import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { Col, Row } from "react-bootstrap";
import * as Yup from "yup";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import TextInput from "../../common/textfield/TextInput";
import { Button } from "../../common/Button";
import { Navigate, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery";
import { createKioskDevice } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";

const CreateDevice = () => {
  const [organizations, setOrganizations] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const navigate = useNavigate();
  const user = useSelector((state) => state?.auth?.user);
  const [locations, setLocations] = useState([]);
  const userRoles = useSelector(
    (state) => state?.auth?.user?.["cognito:groups"]
  );
  const admin = userRoles?.includes("Super-Admin");

  const onSuccessLocations = (data) => {
    setLocations(
      data?.map((location) => (location = { ...location, value: location?.id }))
    );
  };

  const onSuccessOrganizations = (data) => {
    setOrganizations(data?.map((org) => ({ ...org, value: org?.id })));
  };
  useClinicLocationsQuery({
    onSuccess: onSuccessLocations,
    orgIds: admin ? false : [user?.organizations?.[0]?.id],
  });
  useOrgQuery({
    onSuccess: onSuccessOrganizations,
  });

  const formik = useFormik({
    initialValues: {
      deviceName: "",
      location: "",
      email: "",
      organization:
        user?.["custom:group"] === "Super-Admin"
          ? ""
          : user?.organizations?.[0]?.id,
      type: "kiosk",
    },
    validationSchema: Yup.object({
      deviceName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      location: Yup.string()
        .required("Required field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      organization: Yup.string()
        .required("Required field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      type: Yup.string()
        .required("Required field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      email: Yup.string()
        .email("Invalid email address")
        .required("Required email field"),
    }),

    onSubmit: (values) => {
      setBtnLoading(true);
      let orgData = organizations?.filter(
        (org) => values?.organization === org?.id
      );
      let locationData = locations?.filter(
        (loc) => values?.location === loc?.id
      );
      let locationRegion =
        locationData?.[0]?.address?.[0]?.country +
        "-" +
        locationData?.[0]?.address?.[0]?.state;
      let payload = {
        email: values?.email,
        deviceName: values?.deviceName,
        location: {
          display: locationData?.[0]?.name,
          reference: `Location/${values?.location}`,
          type: "Location",
        },
        type: values?.type,
        managingOrganization: {
          display: orgData?.[0]?.name,
          reference: `Organization/${values?.organization}`,
          type: "Organization",
        },
        region: locationData?.[0]?.locationCode || locationRegion || "-",
        orgId: values?.organization,
      };

      createKioskDevice(user?.organizations?.[0]?.id, payload)
        .then((res) => {
          if (res?.status === true) {
            navigate("/app/identities/devices");
            success(res.message);
          } else {
            failed(res.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });

  const handleOrgChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("organization", value);
  };

  const handleLocationChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("location", value);
  };

  const deviceOptions = [{ name: "Kiosk", value: "kiosk" }];

  return (
    <section className="common-listing">
      <div className="custom-card p-4">
        <form className="common-form border-fields userAccount">
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"deviceName"}
                label={"Device Name"}
                formik={formik}
                placeholder={"Device Name"}
                required={true}
              />
            </Col>
            <Col md={3}>
              <SelectFiled
                keyField={"type"}
                label={"Device Type"}
                formik={formik}
                options={deviceOptions}
              />
            </Col>
            <Col md={3}>
              <SelectFiled
                keyField={"organization"}
                label={"Select Organization"}
                formik={formik}
                options={organizations}
                onChange={handleOrgChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                type={"email"}
                keyField={"email"}
                label={"Email Address"}
                formik={formik}
                placeholder={"Email Address"}
                required={true}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"location"}
                label={"Select Location"}
                formik={formik}
                options={locations}
                onChange={handleLocationChange}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                style={{ marginRight: "20px" }}
                onClick={() => {
                  navigate("/app/identities/devices");
                }}
                variant="secondary"
                title="Cancel"
              >
                Cancel
              </Button>
              <Button isLoading={btnLoading} onClick={formik.handleSubmit}>
                Create
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    </section>
  );
};

export default CreateDevice;
