import { axios } from "../../../lib/axios";

const getCode = (requestType)=>{
    switch (requestType) {
        case 'Lab':
          return "MBL"
        case 'Diagnostic':
          return "RADDX"
        case 'Referral':
          return "DX"
        case 'Encounter Report':
          return "HOSP"
        default:
          return "PHARM" // medication
    }
}

const apiPayload = ({ name, description, phone_number, email, address, state, city, postalCode, country, orgName, orgId, fax, categoryType, code, sftEnabled }) => {
    const categoryTypeCode = categoryType.map(item => item.value);
    const payload = {
        "name": name,
        "description": description,
        "sftEnabled": sftEnabled,
        "telecom": [
            {
                "system": "phone",
                "value": phone_number
            },
            {
                "system": "email",
                "value": email?.toLowerCase()
            }
        ],
        "address": [
            {
                "line": [address],
                "state": state,
                "city": city,
                "postalCode": postalCode,
                "country": country,
            }
        ],
        "managingOrganization": {
            "display": orgName,
            "reference": `Organization/${orgId}`,
            "type": "Organization"
        },
        "categoryTypeCode": categoryTypeCode
    }

    if (fax) {
        payload.telecom.push({
            "system": "fax",
            "value": fax
        });
    }

    return payload;

}

export const createClinicLocation = (data) => {
    return axios.post("location", apiPayload(data))
}

export const editClinicLocation = (data) => {
    let payload = apiPayload(data);
    if (data?.country) {
        payload.locationCode = data?.country + "-" + data?.state
    }
    
    return axios.put(`location/${data?.id}`, payload)
}

export const deleteClinicLocation = (id) => {
    return axios.delete(`location/${id}`)
}

export const getOrgnizationForLocation = (orgId) => {
    return axios.get(orgId ? `organization/${orgId}` : "organization");
}

export const getLocationQuery = ({ orgnizationIds= [], userRoles, isSftEnable, requestType }) => {
    return  userRoles.includes("Super-Admin") ? axios.get("location") : isSftEnable
    ? axios.get(`location?sft=true&code=${getCode(requestType)}&orgIds=${JSON.stringify(orgnizationIds)}`)
    : axios.get(`location?orgIds=${JSON.stringify(orgnizationIds)}`);
}