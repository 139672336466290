import React, { useEffect, useState } from "react";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery";
import { Autocomplete, TextField } from "@mui/material";
import { getLocationQuery, getOrgnizationForLocation } from "../../api/ClinicLocation";
import { useSelector } from "react-redux";

const LocationsSearch = ({ keyField, label, formik, required, requestType }) => {
    const [locations, setLocations] = useState([])
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const userOrganizationId = useSelector((state) => state?.auth?.user?.["organizations"][0].id);
    // useClinicLocationsQuery({ onSuccess: setLocations, isSftEnable: true, requestType })

    const onChange = (e, value) => {
        formik.setFieldValue("locationId", value?.id || "");
        formik.setFieldValue("locationFax_number", value?.telecom?.find((ele) => ele?.system === "fax")?.value || "");
        formik.setFieldValue("name", value?.name || "");
    };

    const fetchLocation = () =>{
        // getOrgnizationForLocation().then((res)=>{
            getLocationQuery({orgnizationIds: [userOrganizationId], userRoles, isSftEnable: true, requestType}).then((data)=>{
                data?.data?.sort((a, b) => a.name.localeCompare(b.name));
                setLocations(data?.data);
            })
        // })
    }

    useEffect(() => {
        fetchLocation()
    }, [])

    return (
        <>
            <label htmlFor={keyField}>
                {label}
                {required ? (
                    <span style={{ color: "red" }}> *</span>
                ) : null}
            </label>
            <Autocomplete
                disablePortal
                clearOnBlur={false}
                id="combo-box-demo"
                options={locations}
                onChange={onChange}
                getOptionLabel={option => option?.name || ""}
                sx={{
                    fontSize: "14px",
                    "& .MuiAutocomplete-input": {
                        padding: "0.5px 7px !important"
                    },
                }}
                renderInput={(params) =>
                    <TextField {...params}
                        sx={{
                            fieldset: {
                                borderRadius: "10px",
                            }
                        }}
                        label={null}
                        variant="outlined"
                        fullWidth
                    />
                }
            />
            <div>{formik?.touched?.locationId && formik?.errors?.locationId ? <div className="error-text">{formik?.errors?.locationId}</div> : null}</div>
        </>
    );
};

export default LocationsSearch;
