import React, { useCallback, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import * as Yup from "yup";
import { useFormik } from "formik";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useNavigate } from "react-router-dom";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import { decryptData, encryptData } from "../../EncryptDecrypt";
import {
  typeOptions,
  createAccountOptions,
  caregions,
  usregions,
  countryList,
  FormikObjCreate,
  relationsList,
  createBasicFields,
  createBasicFieldsPractionerTwo,
} from "./Constants";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import MultiSelect from "../../common/textfield/MultiSelect";
import { Skeleton, Tooltip } from "@mui/material";
import {
  createKioskDevice,
  createUser,
  getCustomRoles,
  getPractitionerList,
} from "../../api/Individual";
import FilterSelect from "../../common/textfield/FilterSelect";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery";
import { Clear } from "@mui/icons-material";
import moment from "moment";
import {
  createContactNumber,
  formattedPhoneNumberLength,
} from "../../../utils/phoneNumberSupport";
import { useSelector } from "react-redux";
import Fields from "./Fields";

function CreatePatient() {
  const navigate = useNavigate();
  const onSuccessLocations = (data) => {
    setLocations(
      data?.map((location) => (location = { ...location, value: location?.id }))
    );
  };
  useClinicLocationsQuery({ onSuccess: onSuccessLocations });
  const [locations, setLocations] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [organizationsData, setOrganizationsData] = useState([]);
  //For Setting scope of jurisdictions
  const [regionOptions, setRegionOptions] = useState([]);
  //For selecting role and showing jurisdictions
  const [roleSelected, setRoleSelected] = useState(false);
  //For selecting org and showing roles  of that org
  const [orgSelected, setOrgSelected] = useState(false);

  const [typeStatus, setTypeStatus] = useState(false);
  const [createAccount, setCreateAccount] = useState(true);
  const [createAccountValue, setCreateAccountValue] = useState(true);
  const user = useSelector((state) => state?.auth?.user);

  //For getting general Practiotioner data and setting the list and options
  // const [generalPractitionerOptions, setGeneralPractitionerOptions] = useState(
  //   []
  // );
  // const [generalPractitionerData, setGeneralPractitionerData] = useState([]);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)

  //Fetching Organization Data
  const onSuccessOrg = (data) => {
    setOrganizations(data?.map((org) => (org = { ...org, value: org?.id })));
    setOrganizationsData(data);
  };
  const { isLoading } = useOrgQuery({ onSuccess: onSuccessOrg });

  //Handle change function for org selection and showing roles w.r.to selected org
  const handleOrgChange = (event) => {
    const value = event?.target?.value;
    setOrgSelected(true);
    formik.setFieldValue("organization", value);
  };
  //Handle change function for Couuntry selection and showing region
  const handleCountryChange = (event) => {
    const value = event?.target?.value;
    if (value === "CA") {
      setRegionOptions(caregions);
    } else {
      setRegionOptions(usregions);
    }
    formik.setFieldValue("countrylist", value);
    if (formik?.values?.type === "Kiosk Device") {
      formik.setFieldValue("country", value);
    }
    formik.setFieldValue("jurisdiction", []);
  };

  const handleRelationshipChange = (event) => {
    formik.setFieldValue("relationship", event?.target?.value);
  };

  const formik = useFormik({
    ...FormikObjCreate,
    initialValues: {
      ...FormikObjCreate.initialValues,
      type:  "Patient",
    },
    validationSchema: Yup.object({
      first_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      last_name: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required last name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      qualification: Yup.string()
        .max(50, "Must be 50 characters or less")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      license: Yup.string()
        .max(20, "Must be 20 characters or less")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      date_of_birth: Yup.string().when("type", (type, schema) =>
        type === "Practitioner"
          ? schema
              .required("Required date of birth field")
              .test(
                "min-age",
                "Age cannot be below 16 years. Please provide a valid age.",
                function (value) {
                  const currentDate = moment();
                  const birthDate = moment(value, "MM/DD/YYYY");
                  const age = moment.duration(currentDate.diff(birthDate)).years();
                  return age >= 16;
                }
              )
          : schema.required("Required date of birth field")
      ),
    
      phone_number: Yup.string().matches(
        /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
        "Enter Valid Phone Number."
      ),
    
      home_phone_number: Yup.string().matches(
        /^(?:\+?1[-. ]?)?\(?[0-9]\d{2}\)?[-. ]?\d{3}[-. ]?\d{4}$/,
        "Enter Valid Home Phone Number."
      ),
    
      ext: Yup.string().matches(/^\d{0,4}$/, "Must be 4 digits or less."),
    
      address: Yup.string()
        .required("Required address field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      city: Yup.string()
        .required("Required city field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      email: Yup.string().when("create_account", (type, schema) =>
          createAccountValue
          ? schema
            .email("Invalid email address")
            .required("Required email field")
            .matches(/^$|^\S+.*/, "Only blankspaces is not valid.")
          : schema
          .email("Invalid email address")
      ),
    
      type: Yup.string()
        .required("Required type field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      organization: Yup.string()
        .required("Required organization")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      gender: Yup.string()
        .required("Required gender field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      country: Yup.string()
        .required("Required country field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      region: Yup.string()
        .required(`Required field`)
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    
      role: Yup.string().when("type", (type, schema) =>
        typeStatus ? schema.required("Required Role field") : schema
      ),
    
      create_account: Yup.string().when("type", (type, schema) =>
        createAccount ? schema.required("Required Create Account field") : schema
      ),
    
      countrylist: Yup.string().when("role", (role, schema) =>
        role && roleSelected ? schema.required("Required Country field") : schema
      ),
    
      jurisdiction: Yup.array().when("role", (role, schema) =>
        role && roleSelected
          ? schema
              .min(1, "Required Jurisdictions field")
              .required("Required Jurisdictions field")
          : schema
      ),
    
      relationship: Yup.string().when(
        ["related_to", "type"],
        (related_to, type, schema) =>
          related_to && type === "Related_Person"
            ? schema.required("Required Relationship field")
            : schema
      ),
    
      related_to: Yup.string().when("type", (type, schema) =>
        type === "Related_Person"
          ? schema.required("Required Related To field")
          : schema
      ),
    
      postalCode: Yup.string().when(["type", "country"], (type, country, schema) =>
        country === "US"
          ? Yup.number()
              .typeError("Zip code must be a number")
              .required("Required field")
          : Yup.string()
              .required("Required field")
              .matches(
                /^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/,
                "Postal code must be in A1A 1A1 format."
              )
      ),
    
      hcn: Yup.string().when("type", (type, schema) =>
        type === "Patient"
          ? schema.required("HCN is required when creating a patient.")
          : schema
      ),
    }),
    

    onSubmit: (values) => {
      const type = values?.type;
      const state = values?.country + "-" + values?.region;
      // const generalPractitionersSelected = generalPractitionerData.filter((data) => values?.general_practitioner.includes(data?.id));
      let phoneNumber = createContactNumber(values);
      let encryptedData = {
        first_name: encryptData(values?.first_name.toLowerCase()),
        last_name: encryptData(values?.last_name.toLowerCase()),
        full_name: encryptData(
          [
            values?.first_name.toLowerCase(),
            values?.last_name.toLowerCase(),
          ].join(" ")
        ),
        address: encryptData(values?.address),
        date_of_birth: encryptData(moment(values?.date_of_birth).format("MM-DD-YYYY")),
        phone_number: encryptData(phoneNumber),
        postalCode: encryptData(values?.postalCode.toUpperCase()),
        city: encryptData(values?.city),
        gender: encryptData(values?.gender),
        email: encryptData(values?.email?.toLowerCase()),
        relationship: encryptData(values?.relationship),
        healthCardNumber: encryptData(values?.hcn),
        device_name: encryptData(values?.device_name),
      };
      setBtnLoading(true);
      const selectedLocationData =
        locations?.filter(
          (location) => location?.id === values?.clinic_location
        )?.[0] || null;
      if (values?.type === "Kiosk Device") {
        let orgName = organizations?.filter((org) => values?.organization === org?.id);
        
        let payload = {
          email: values?.email,
          deviceName: values?.device_name,
          location: values?.clinic_location,
          orgId: values?.organization,
          managingOrganization: {
            type: "Organization",
            reference: `Organization/${values?.organization}`,
            display: orgName?.[0]?.name
          },
          type: "kiosk"
        }
        createKioskDevice(adminOrgs?.[0]?.id, payload)
        .then((res) => {
          if (res?.status === true) {
            navigate("/app/identities/patients");
            success(res.message);
          } else {
            failed(res.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
          );
        })
        .finally(() => setBtnLoading(false));
      } else {
        createUser(
          values,
          encryptedData,
          organizationsData,
          state,
          type,
          [],
          selectedLocationData,
          adminOrgs?.[0]?.id
        )
          .then((res) => {
            if (res.status === true) {
              navigate("/app/identities/patients");
              success(res.message);
            } else {
              failed(res.error);
            }
          })
          .catch((res) => {
            failed(
              res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
            );
          })
          .finally(() => setBtnLoading(false));
      }
        
    },
  });

 
  const handleCreateAccountChange = useCallback((event) => {
    const value = event?.target?.value; 
    if (value == "true") { 
      setCreateAccountValue(true);
    }
    else{ 
      setCreateAccountValue(false);
    }   
    formik?.setFieldValue("create_account", value);
  }, [formik, setCreateAccountValue]); 

  //Form handleChange for getting general Practitioners list while creating patient
  const handleFormChange = (e) => {
    if (e?.target?.id === "region" || e?.target?.id === "organization") {
      const selected_region =
        e?.target?.id === "region" ? e?.target?.value : formik?.values?.region;
      const selected_org =
        e?.target?.id === "organization"
          ? e?.target?.value
          : formik?.values?.organization;
      // if (selected_org && selected_region) {
      //   const scopeOfPrac = formik?.values?.country + "-" + selected_region;
      //   getPractitionerList(selected_org, scopeOfPrac)
      //     .then((res) => {
      //       setGeneralPractitionerData(res?.result);
      //       setGeneralPractitionerOptions(
      //         res?.result?.map((list) => ({
      //           name: decryptData(list?.name?.[0]?.text),
      //           value: list?.id,
      //           id: list?.id,
      //         }))
      //       );
      //     }).catch((res) => {
      //       failed(
      //         res?.response?.data?.message ||
      //         res?.response?.data?.error ||
      //         res.message
      //       );
      //     });
      // }
    } else if (e?.target?.id === "country") {
      formik.setFieldValue("region", "");
      formik.setFieldValue("postalCode", "");
    }
  };

  const handlePostalCodeChange = (e) => {
    const { value } = e.target;
    let spacedValue = value;
    if (formik?.values?.country !== "US") {
      // Remove spaces and non-alphanumeric characters
      const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
      // Add a space after every 3 letters
      spacedValue = formattedValue.replace(
        /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
        "$1 $2"
      );
    }
    // Set the formatted value in the formik
    formik?.setFieldValue("postalCode", spacedValue);
  };

  const handleDesignationChange = (event) => {
    const value = event?.target?.value;
    const name = event?.target?.name;
    formik.setFieldValue("designation", value);
  };
  const handleExt = (event) => {
    let key = event.key;
    let regex = new RegExp("^[0-9]+$");
    if (!regex.test(key)) {
      event.preventDefault();
      return false;
    }
  };

  return (
    <section className="common-listing">
      <div className="heading-wrap mb-3">
        {/* <Tooltip title="Go back">
          <div
            style={{ marginRight: "1rem", cursor: "pointer" }}
            onClick={() => navigate(-1)}
          >
            <ArrowBack />
          </div>
        </Tooltip> */}
      </div>
      <div className="custom-card p-4">
        <form
          className="common-form border-fields userAccount"
          onSubmit={formik.handleSubmit}
          onChange={handleFormChange}
        >
          <Row>
            {isLoading ? (
              <Col>
                <Skeleton animation="wave" />
              </Col>
            ) : (
              <Col md={3}>
                {formik?.values?.type !== "Super-Admin" ? (
                  <SelectFiled
                    keyField={"organization"}
                    label={"Select Organization"}
                    formik={formik}
                    options={organizations}
                    onChange={handleOrgChange}
                  />
                ) : null}
              </Col>
            )}
            {  createAccount ? (
              <>
                <Col>
                  <SelectFiled
                    keyField={"create_account"}
                    label={"Create Account"}
                    formik={formik}
                    placeholder={"Create Account"}
                    options={createAccountOptions}
                    onChange={handleCreateAccountChange}
                  />
                </Col>
                
              </>
            ) : (
              <>
                <Col></Col>
                <Col></Col>
              </>
            )}
            <Col style={{display:"flex", placeContent: "flex-end"}}>
                  <Tooltip style={{}}>
                    <div
                      style={{ marginRight: "1rem", cursor: "pointer",maxHeight: "24px" }}
                      onClick={() => navigate(-1)}
                    >
                      <Clear />
                    </div>
                  </Tooltip>
            </Col>
          </Row>
          {formik?.values?.type === "Related_Person" && orgSelected ? (
            <Row>
              <Col>
                <FilterSelect
                  keyField={"related_to"}
                  label={"Related To"}
                  formik={formik}
                  required={true}
                  id={formik?.values?.organization}
                />
              </Col>
              <Col>
                <SelectFiled
                  keyField={"relationship"}
                  label={"Relationship"}
                  formik={formik}
                  placeholder={"Relationship"}
                  options={relationsList}
                  onChange={handleRelationshipChange}
                />
              </Col>
              <Col></Col>
            </Row>
          ) : null}

          {(formik.values.type !== "Device" && formik?.values?.type !== "Kiosk Device") ? 
              <Fields fields={createBasicFields} formik={formik} isReadOnly={false} handlePostalCodeChange={handlePostalCodeChange} />
           : (
            <Row>
              <Col>
                <TextInput
                  keyField={"device_name"}
                  label={"Device Name"}
                  formik={formik}
                  placeholder={"Device 1"}
                />
              </Col>
              {formik?.values?.type === "Kiosk Device" && <Col>
                <SelectFiled
                  keyField={"country"}
                  label={"Country"}
                  formik={formik}
                  options={countryList}
                  onChange={handleCountryChange}
                />
              </Col>}
              <Col>
                <SelectFiled
                  keyField={"clinic_location"}
                  label={formik?.values?.type === "Kiosk Device" ? "Select Location" : "Select Clinic Location"}
                  formik={formik}
                  options={formik?.values?.type === "Kiosk Device" ? regionOptions : locations}
                />
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          )}
          <Row>
            <Col  sm lg = "4">
              <TextInput
                keyField={"email"}
                label={"Email Address"}
                formik={formik}
                placeholder={"Email Address"}  
                hideRequired= {!createAccountValue}
                required={true}
              />
            </Col>
            {(formik.values.type !== "Device" && formik?.values?.type !== "Kiosk Device") ? (
              <>
                {formik?.values?.type === "Practitioner" ?
                  <>
                    <Col>
                      <TextInput
                        keyField={"phone_number"}
                        type="phone"
                        label={"Work Phone Number"}
                        formik={formik}
                        placeholder={"Work Phone Number"}
                        hideRequired= {true}
                      />
                    </Col>

                    {formik.values.phone_number && (
                      <Col  sm lg = "2">
                        <TextInput
                          keyField={"ext"}
                          label={"Ext."}
                          type="text"
                          maxlength="4"
                          formik={formik}
                          placeholder={"Ext."}
                          onKeyPress={handleExt}
                          autocomplete="off"
                          hideRequired= {true}
                        />
                      </Col>
                    )}

                    <Col>
                      <TextInput
                        keyField={"home_phone_number"}
                        type="phone"
                        label={"Home Phone Number"}
                        formik={formik}
                        placeholder={"Home Phone Number"}
                        hideRequired= {true}
                      />
                    </Col>

                  </>

                  :
                  <>
                    <Col sm lg = "4">
                      <TextInput
                        keyField={"phone_number"}
                        type="phone"
                        label={"Phone Number"}
                        formik={formik}
                        placeholder={"Phone Number"}
                        hideRequired= {true}
                      />
                    </Col>
                    {formik.values.phone_number && (
                      <Col sm lg = "2">
                        <TextInput
                          keyField={"ext"}
                          label={"Ext."}
                          type="text"
                          maxlength="4"
                          formik={formik}
                          placeholder={"Ext."}
                          hideRequired= {true}
                          onKeyPress={handleExt}
                          autocomplete="off"
                        />
                      </Col>
                    )}
                  </>
                }


              </>
            ) : (
              <>
                <Col></Col>
                <Col></Col>
              </>
            )}
          </Row>

          {formik?.values?.type === "Patient" && (
            <Row>
              <Col>
                <TextInput
                  keyField={"hcn"}
                  label={"HCN"}
                  formik={formik}
                  placeholder={"HCN"}
                  required={true}
                />
              </Col>
              <Col></Col>
              <Col></Col>
            </Row>
          )}

          <div className="btn-wrap" style={{ display: "flex", gap: "16px" }}>
            <Button type="submit" isLoading={btnLoading}>
              Create
            </Button>
            <Button
              onClick={() => navigate("/app/identities/patients")}
              variant="secondary"
            >
              Cancel
            </Button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default CreatePatient;
