import React, { useEffect, useState } from "react";
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, Select } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: "max-width",
        },
    },
    getcontentanchorel: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center",
    },
    variant: "menu",
};

    /**
     * 
     * @param {object} formik 
     * @param {string} keyField 
     * @returns a div containing an error message or null
     * 
     * In the case of a multi-line form, so unavailability, or for example a dynamic length form, where you 
     * can add duplicate rows, the keyfields will be set in a groupname.rowindex.fieldname, however the formik
     * errors don't translate gracefully to this mult-row setup, and come as a JSON object like follows:
     * 
     * formik.errors = {
     *      groupname: [
     *          fieldname
     *      ]
     * }
     * where the rowindex is the index of the field in the array.
     */
const renderError = (formik, keyField) => {
    let keyFieldParts = keyField?.split('.');
    
    if(keyFieldParts?.length === 3 && formik?.errors[keyFieldParts[0]]) {
        let errorString = formik?.errors[keyFieldParts[0]]?.[keyFieldParts[1]]?.[keyFieldParts[2]];
        return   <div>
                    { errorString ? <div className="error-text">{errorString}</div>: null }
                 </div>
    }
    else {
        return <div>{formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
    }
}

function MultiSelect({ options, keyField, formik, isSearchable="false", isSelectAll = false, isSelectAllEnable = false ,label, nodata, required = true, hideRequired =false, defaultValue = [], readOnly = false,bindMethod = false, ...props }) { 
    const [selected, setSelected] = useState(["Select"]);
    const [selectAll, setSelectAll] = useState(isSelectAll);
    const isSmallScreen = useMediaQuery('(max-width: 991px)');
    const isBigScreen = useMediaQuery('(max-width: 1395px)');
    const handleChange = (event) => {
        const value = event.target.value;
        if(value.includes("selectAll")){
            handleSelectAll()
        }else{
            setSelectAll(false)
            setSelected(value);
            formik?.setFieldValue(keyField, value.filter((val) => val !== "Select"));
            bindMethod && bindMethod(value.filter((val) => val !== "Select"))
        }
    };    
    useEffect(()=>{
        defaultValue.length > 0 ? setSelected([...selected, ...defaultValue.map((n) => selected.includes(n) ? null : n).filter((n) => n)]): setSelected(["Select"]);
        defaultValue.length > 0 && (defaultValue.length  === options.length  ? setSelectAll(true) : setSelectAll(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue?.length])

    useEffect(()=>{
        if(isSelectAllEnable){
            if(formik?.values?.[keyField]?.length === 0) handleChange({target: {value: ["Select"]}})
            options?.length > 0 && formik?.values[keyField]?.length === options?.length && setSelectAll(true)
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formik?.values?.[keyField]?.length])

    useEffect(()=>{
        setSelectAll(isSelectAll)
    }, [isSelectAll])

    const handleSelected = (selected) => {
        if (selected.length >= 2)
            return options
                .map((option) => (selected.includes(option.id) ? option.name : null))
                .filter((n) => n)
                .join(", ");
        return selected;
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        const valuesById = selectAll ? [] : options.map((option) =>  option.id)
        setSelected(selectAll ? ["Select"] : valuesById);
        formik?.setFieldValue(keyField, valuesById);
        bindMethod && bindMethod(valuesById);
    };

    return (
        <div className="field-wrap multiselect">
            {label && <InputLabel id="mutiple-select-label">{label}{hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : ( props.propsOptionData === 0 ? null : " (Optional)")}</InputLabel>}
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => handleSelected(selected)}
                MenuProps={MenuProps}
                fullWidth
                issearchable={isSearchable}
                // style={{ borderRadius: "10px", height: "42px", maxWidth: "clamp(23rem, 3.5vw, 30rem)", textTransform: "capitalize" }}
                style={{ borderRadius: "10px", height: "42px",minHeight:isBigScreen ? "54px": "50px",maxWidth: isSmallScreen ? "100%" : "clamp(23rem, 3.5vw, 30rem)", textTransform: "capitalize" }}
                readOnly={readOnly}
                {...props}
            >
                {isSelectAllEnable ?
                    <MenuItem value={"selectAll"}>
                        <ListItemIcon>
                            <Checkbox checked={selectAll} />
                        </ListItemIcon>
                        <ListItemText primary={"Select All"} />
                    </MenuItem>
                :null}
                {options.length > 0 ? (
                    options.map(({ value, name }) => (
                        <MenuItem key={value} value={value} style={{textTransform: "capitalize"}}>
                            <ListItemIcon>
                                <Checkbox checked={selected.indexOf(value) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={name} />
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem>
                        <ListItemText primary={nodata} />
                    </MenuItem>
                )}
            </Select>
            {
                renderError(formik, keyField)
            }
        </div>
    );
}

export default MultiSelect;
