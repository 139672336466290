import * as Yup from "yup";
import { splitContactNumber, formattedPhoneNumberLength } from "../../../utils/phoneNumberSupport";

export const HeadCells = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'category',
        label: 'Type',
        type: 'text',
        nested: { key: "type", value: { index: 0, value: { key: "coding", value: { index: 0, key: "display" } } }},
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
         nested: { key: "address", value: { index: 0, value : { key:"line"} } },
      //  nested: [{ key: "address", value: { index: 0, value : { key:["line"]} } }, { key: "address", value: { index: 0, value : { key:["postalCode"]} } }],
    //   nested: [
    //     { key: "address",   value: { index: 0, value : { key:"line"} } } ,
    //     // { key: "address",   value: { index: 0, value : { key:["postalCode"]} } } ,
    //     // { key: "address",  value: { index: 0, value : { key:["state"]} } } ,
    //     // { key: "address",   value: { index: 0, value : { key:["country"]} } } ,
    // ],
    },
    {
        id: 'telecom',
        label: 'Fax',
        type: 'text',
        nested: { key: "telecom", value: { index: 2, value:{key:"value"} }},
    },
    {
        id: 'sft',
        label: 'SFT',
        type: 'active',
        nested: { key: "sftEnabled"},
    },
    {
        id: 'description',
        label: 'Description',
        type: 'text',
        nested: { key: "description" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];

export const HeadCellsOld = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'category',
        label: 'Category Type',
        type: 'text',
        nested: { key: "type", value: { index: 0, value: { key: "coding", value: { index: 0, key: "display" } } }},

    },
    {
        id: 'sft',
        label: 'SFT',
        type: 'active',
        nested: { key: "sftEnabled"},
    },
    {
        id: 'organisation',
        label: 'Organization',
        type: 'text',
        nested: { key: "managingOrganization", value: { key: "display" } },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];
export const HeadCellsOrgAdmin = [
    {
        id: 'name',
        label: 'Name',
        type: 'text',
        nested: { key: "name" },
    },
    {
        id: 'category',
        label: 'Type',
        type: 'text',
        nested: { key: "type", value: { index: 0, value: { key: "coding", value: { index: 0, key: "display" } } }},
    },
    {
        id: 'address',
        label: 'Address',
        type: 'text',
         nested: { key: "address", value: { index: 0, value : { key:"line"} } },
      //  nested: [{ key: "address", value: { index: 0, value : { key:["line"]} } }, { key: "address", value: { index: 0, value : { key:["postalCode"]} } }],
    //   nested: [
    //     { key: "address",   value: { index: 0, value : { key:"line"} } } ,
    //     // { key: "address",   value: { index: 0, value : { key:["postalCode"]} } } ,
    //     // { key: "address",  value: { index: 0, value : { key:["state"]} } } ,
    //     // { key: "address",   value: { index: 0, value : { key:["country"]} } } ,
    // ],
    },
    {
        id: 'telecom',
        label: 'Fax',
        type: 'text',
        nested: { key: "telecom", value: { index: 2, value:{key:"value"} }},
    },
    {
        id: 'sft',
        label: 'SFT',
        type: 'active',
        nested: { key: "sftEnabled"},
    },
    {
        id: 'description',
        label: 'Description',
        type: 'text',
        nested: { key: "description" },
    },
    {
        id: 'actions',
        label: 'Actions',
        type: 'text',
        style: { textAlign: "center" },
    },
];

export const DataFields = [
    'name',
    'category',
    'address',
    'telecom',
    'sft',
    "description",
    "actions"
];

export const DataFieldsOrgAdmin = [
    'name',
    'category',
    'address',
    'telecom',
    'sft',
    "description",
    "actions"
];

// export const DataFieldsOrgAdmin = [
//     'name',
//     'category',
//     'sft',
//     "actions"
// ];

export const categoryType = [ 
    { value: "Lab", name: " Lab", code: "MBL" },
    { value: "Diagnostic", name: "Diagnostic", code: "RADDX" },
    { value: "Service", name: "Service", code: "DX" },
    { value: "Pharmacy", name: "Pharmacy", code: "PHARM" },
    { value: "Health Facility", name: "Health Facility", code: "HOSP" },
]

export const COUNTRY = "Country";
export const STATE = "State";
export const ZIP_CODE = "Zip Code";
export const POSTAL_CODE = "Postal Code";
export const FormikObj = {
    initialValues: {
        first_parent_organization: "",
        second_parent_organization: "",
        third_parent_organization: "",
        orgId: "",
        orgName:"",
        name: "",
        categoryType: [],
        code: "",
        description: "",
        address: "",
        city:"",
        state: "",
        country: "",
        postalCode: "",
        phone_number: "",
        ext: "",
        email: "",
        fax: "",
        sftEnabled: false
    },
    validationSchema: Yup.object({
        first_parent_organization: Yup.string(),
        second_parent_organization: Yup.string(),
        third_parent_organization: Yup.string(),
        orgId: Yup.string().required("Required organization").matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
        categoryType: Yup.array().of(Yup.object({ value: Yup.string().required(), label: Yup.string().required() })).min(1, "At least one type is required").required(),
        name: Yup.string().max(60, "Must be 60 characters or less").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().max(250, "Must be 250 characters or less").required("Required description field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        address: Yup.string().max(250, "Must be 250 characters or less").required("Required address field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        city: Yup.string().max(100, "Must be 100 characters or less").required("Required city field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        state: Yup.string().required("Required field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        country: Yup.string().required("Required country field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        postalCode: Yup.string().when("country", (country, schema) =>
            country === "US"
                ? Yup.number().typeError("Zip code must be a number").required("Required field")
                : Yup.string().required("Required field").matches(/^[A-Za-z]\d[A-Za-z] [0-9][A-Za-z]\d$/, "Postal code must be in A1A 1A1 format.")

        ),
        phone_number: Yup.string().typeError('you must specify a number')
            .test("len", "Invalid phone number", (val) => {
                let phoneNumber = splitContactNumber(val).phone_number;
                const val_length_without_dashes = formattedPhoneNumberLength(phoneNumber);
                return val_length_without_dashes === 13;
            }).required("Required phone number field")
            .matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        // TODO Multilingual Support
        ext: Yup.string().matches(/^\d{0,4}$/, 'Must be 0 to 4 digits.'),
        email: Yup.string().email("Invalid email address").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        fax: Yup.string().when("sftEnabled", (sftEnabled, schema)=> 
            sftEnabled === true 
                ? Yup.string().required("Required fax field").test("len", "Invalid fax number", (val) => {
                    const val_length_without_dashes = formattedPhoneNumberLength(val);
                    return val_length_without_dashes === 10;
                }) : Yup.string().notRequired()
        ).test("len", "Invalid fax number", (val) => {
            const val_length_without_dashes = formattedPhoneNumberLength(val);
            return val_length_without_dashes === 10;
        }),
    }),
}