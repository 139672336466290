import moment from "moment";

export const getDaysBetweenDates = (startDate, endDate) => {
    const now = moment(startDate).clone(), dates = [];

    while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'));
        now.add(1, 'days');
    }
    return dates;
};

export const capitalizeWords = (str) => {
    return str
      .toLowerCase()
      .replace(/(^|\s)\S/g, (firstLetter) => firstLetter.toUpperCase());
  };