
import { Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import TextInput from "../common/textfield/TextInput.jsx";
import '../apps/ResourcesHub/ResourceHub.css';
import { Col, Row, Table } from "react-bootstrap";
import { InputLabel, Skeleton, TableCell, Tooltip } from "@mui/material";
import CustomPagination from "../common/components/Pagination/CustomPagination.js";
import { getDocumentReference } from "../api/FillableDocument.js/index.js";
import { useFormik } from 'formik'
import { useClinicLocationsQuery } from "../../hooks/ReactQueryHooks/useClinicLocationsQuery.js";
import { ViewLocation } from "../apps/ResourcesHub/ViewLocation.js";
import { truncateText } from "../apps/ResourcesHub/constant.js";


export function Resources({ show, onHide}) {
    const [documentList, setDocumentList] = useState([]);
    const [filteredDocumentList, setFilteredDocumentList] = useState([]);
    const totalRows = filteredDocumentList.length;
    const [page, setPage] = useState(0);
    const rowsPerPage = 6;
    const [isLoading, setIsLoading] = useState(false);  
    const [locations, setLocations] = useState([]);
    const [viewlocationData, setViewlocationData] = useState(false);

    const onSuccessLocations = (data) => {
      setLocations(data);
    };
  
    useClinicLocationsQuery({ onSuccess: onSuccessLocations });
    const userOrganizationId = useSelector(
        (state) => state?.auth?.user?.["organizations"][0].id
    );

    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const tableTitle = ["Name", "Description", "Location"]

    const formik = useFormik({
            initialValues: {
                name: "",
            },
          onSubmit: (values) => {
              // Other form submission logic
          }
      });

    const sortByNameAscending = (data) => {
        return data.sort((a, b) => {
            let titleA = a?.content?.[0]?.attachment?.title;
            let titleB = b?.content?.[0]?.attachment?.title
            if (titleA < titleB) return -1;
            if (titleA > titleB) return 1;
            return 0;
        });
    };

    const getListOfDocument = async () => {
        setIsLoading(true);
        const documentList = await getDocumentReference(userOrganizationId, 'ref-doc', userRoles);
        const sortedList = sortByNameAscending(documentList?.data);
        setDocumentList(sortedList);
        setFilteredDocumentList(sortedList); // Initialize filtered list
        setIsLoading(false);
    };

    const handleSearchChange = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        formik.setFieldValue("name", searchTerm);
        const filteredList = documentList.filter((doc) =>
            doc?.content?.[0]?.attachment?.title?.toLowerCase().includes(searchTerm)
        );
        setFilteredDocumentList(filteredList);
        setPage(0); 
    };


    const handleLinkOpen = async(url) => {
        const fullUrl = url.startsWith("http") ? url : `https://${url}`;
        window.open(fullUrl, "_blank", "noopener,noreferrer");
    }

    const handleViewLocation = async (id) => {
        if(viewlocationData){
            setViewlocationData(false)
        }else{
            const clickedLocation = locations.filter(loc => loc.id === id)[0]
            setViewlocationData(clickedLocation)
        }
      }

    useEffect(() => {
        getListOfDocument();
    }, []);

    return (
        <>
        <ViewLocation modalShow={viewlocationData} handleShow={handleViewLocation}/> 
        <Modal
            show={show}
            aria-labelledby="contained-modal-title-vcenter"
            centered 
            onHide={onHide}
            backdropClassName
            backdrop={'static'}
            className="custom-dialog"
            dialogClassName="modal-90w"
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title id="contained-modal-title-vcenter">
                    Resources
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="common-form border-fields resourceHub">
              <Row>
                    <Col md={8}>
                    <TextInput
                            keyField={"name"}
                            formik={formik}
                            placeholder={"Search Resources"}
                            hideRequired={true}
                            onChange={handleSearchChange} 
                            value={formik.values.name} 
                        />
                    </Col>
                </Row>
                <div className="table-wrap" >
                <Table responsive>
                    <thead>
                        <tr>
                            {tableTitle?.map((title) => (
                                <th key={title} >
                                    {title}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                    {isLoading ? (
                        [1, 2, 3, 4, 5, 6].map((val) => (
                            <tr key={val}>
                                {tableTitle?.map((index) =>
                                (<td key={index}>
                                    <Skeleton animation="wave" />
                                </td>)
                                )}
                            </tr>
                            ))
                    )
                    :filteredDocumentList?.length > 0 ? (
                    filteredDocumentList
                        ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        ?.map((data) => {
                            const { id, documentName, location, description, content} = data;
                            let url = content?.[0]?.attachment?.url
                            let title = content?.[0]?.attachment?.title
                            return (
                                <tr key={id}>
                                    <td>
                                        <div
                                            className="click-url"
                                            title={url}
                                            onClick={() =>  handleLinkOpen(url)}
                                        >
                                            {title || documentName}
                                        </div>
                                    </td>
                                    <td
                                        style={{
                                        wordBreak: "break-word",
                                        maxWidth: "200px",
                                        whiteSpace: "pre-wrap"
                                    }}
                                    >
                                        <Tooltip title={description.length > 70 ? description : ""} placement="top">
                                            <span>{truncateText(description, 70)}</span>
                                        </Tooltip>
                                    </td>
                                    <td style={{ wordBreak: "break-word", maxWidth: "200px", whiteSpace: "pre-wrap" }}>
                                        {location?.length > 0 ? (
                                            <div style={{ paddingLeft: "5px", margin: 0 }}>
                                                {location.map((item, index) => (
                                                    <>
                                                        <span
                                                            key={index}
                                                            onClick={() => handleViewLocation(item.reference.split("/")[1])}
                                                            title={"View Location"}
                                                            className="click-url"
                                                        >
                                                            {item.display}
                                                        </span>
                                                        {index < location.length - 1 && ", "}  {/* Add a comma separator */}
                                                    </>
                                                ))}
                                            </div>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                </tr>
                            );
                        })
                        ) : (
                            <tr>
                                <TableCell
                                    colSpan={tableTitle.length}
                                    style={{ textAlign: "center" }}
                                >
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
                {filteredDocumentList?.length > 0 && (
                    <div>
                        <CustomPagination
                            tableData={filteredDocumentList}
                            totalRows={totalRows}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            handlePage={(pageNo) => setPage(pageNo)}
                        />
                    </div>
                )}
                </div>
              </div>
                
            </Modal.Body>
        </Modal>
        
        </>
        
    );
}