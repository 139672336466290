import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useFormik } from "formik";
import Table from "react-bootstrap/Table";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useGetOrgLicensedProducts } from "../../../hooks/ReactQueryHooks/useGetOrgLicensedProducts";
import { getDevicesByOrg } from "../../api/Individual";
import { failed } from "../../common/Toastify";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import EditIcon from "../../../assets/images/editIcon.svg";
import ViewIcon from "../../../assets/images/view.png";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import DevicesSearchBar from "../../common/components/DevicesSearchBar";
import { Col, Row } from "react-bootstrap";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import EditDeviceModal from "./EditDeviceModal";

function DeviceList() {
  const [deviceData, setDeviceData] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
  const [filterData, setFilterData] = useState(null);
  const [selectedDeviceData, setSelectedDeviceData] = useState(null);
  const [page, setPage] = useState(0);
  const rowsPerPage = 10;
  const [selectedOrg, setSelectedOrg] = useState(adminOrgs?.[0]?.id);
  const [editDeviceModalShow, setEditDeviceModalShow] = useState(false);
  const [sortBy, setSortBy] = useState("AscDevice");
  const [searchFilterDeviceData, setSearchFilterDeviceData] = useState([]);
  const userRoles = useSelector(
    (state) => state?.auth?.user?.["cognito:groups"]
  );
  const hasAccess =  userRoles?.includes("Super-Admin");
  const [organizations, setOrganizations] = useState([]);

  const admin = userRoles?.includes("Super-Admin");
  useOrgQuery({
    onSuccess: (data) => {
      setOrganizations(
        data?.map((org) => ({ name: org?.name, value: org?.id }))
      );
    },
    refetchOnWindowFocus: false,
  });

  const formik = useFormik({
    initialValues: {
      org: adminOrgs?.[0]?.id,
      active: true,
      deviceType: "kiosk",
    },
  });

  useEffect(() => {
    if (organizations?.length > 0) {
      if (admin) {
        formik.setFieldValue("org", organizations?.[0]?.value);
      }
    }
  }, [organizations]);

  useEffect(() => {
    setIsLoading(true);
    getDevicesByOrg(formik?.values?.deviceType, formik?.values?.org)
      .then((res) => {
        if (res?.status === true) {
          setDeviceData(res?.result);
          setFilterData(res?.result);
          setSearchFilterDeviceData(res?.result);
        }
      })
      .catch((res) => failed(res?.message || res?.data?.message))
      .finally(() => setIsLoading(false));
  }, [editDeviceModalShow, formik?.values?.org]);

  const handleDeviceSort = (sort_order) => {
    const sortedList = [...deviceData].sort((a, b) => {
      const nameA = a.deviceName?.name?.toLowerCase() || "";
      const nameB = b.deviceName?.name?.toLowerCase() || "";

      if (sort_order === "asc") {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setDeviceData(sortedList);
  };

  const sortFilterData = (sort_by) => {
    setSortBy(sort_by);
    switch (sort_by) {
      case "AscDevice":
        handleDeviceSort("asc");
        break;
      case "DscDevice":
        handleDeviceSort("desc");
        break;
      default:
        handleDeviceSort("asc");
        break;
    }
  };

  const handleFilterData = (data) => {
    setSearchFilterDeviceData(data);
  };

  const computeOptions = (org) => {
    let res = org.filter((org) => org.value !== adminOrgs?.[0]?.id);
    let firstOrg;
    if (admin) {
      firstOrg = { name: "All", value: "All" };
      return [...org];
    }
    return [{ name: adminOrgs?.[0]?.name, value: adminOrgs?.[0]?.id }, ...res];
  };

  const handleOrgChange = (event) => {
    let value = event?.target?.value;
    formik.setFieldValue("org", value);
    setPage(0);
    getDevicesByOrg(formik?.values?.deviceType, value).then((res) => {
      if (res?.status === true) {
        setDeviceData(res?.result);
        setFilterData(res?.result);
        setSearchFilterDeviceData(res?.result);
      }
    });
  };

  const deviceOptions = [{ name: "Kiosk", value: "kiosk" }];

  return (
    <section className="common-listing">
      {editDeviceModalShow && (
        <EditDeviceModal
          deviceData={selectedDeviceData}
          onShow={editDeviceModalShow}
          onHide={() => setEditDeviceModalShow(false)}
          hasAccessToEdit={hasAccess}
        />
      )}
      <Row className="user-Management-header">
        <Col md="auto">
          <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
            <SelectFiled
              keyField={"deviceType"}
              formik={formik}
              selectLabel={"Device Type"}
              options={deviceOptions}
              required={false}
              optional={false}
              readOnly={isLoading}
            />
          </form>
        </Col>
        <Col md="auto">
          <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
            <SelectFiled
              keyField={"org"}
              formik={formik}
              selectLabel={"Select Organization"}
              options={computeOptions(organizations)}
              onChange={handleOrgChange}
              required={false}
              optional={false}
              readOnly={isLoading}
            />
          </form>
        </Col>

        <Col style={{ display: "flex", justifyContent: "flex-end" }}>
          <DevicesSearchBar
            page={page}
            setPage={setPage}
            list={deviceData}
            filterData={handleFilterData}
          />{" "}
        </Col>
      {hasAccess && (
        <Col md="auto" style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            onClick={() => navigate("/app/create-device")}
            title="Create Device"
          >
            Create Device
          </Button>
        </Col>
        )}
      </Row>

      <div className="table-wrap">
        <Table responsive>
          <thead>
            <tr>
              <th
                style={{ cursor: "pointer" }}
                onClick={() =>
                  sortFilterData(
                    sortBy === "AscDevice" ? "DscDevice" : "AscDevice"
                  )
                }
              >
                Name
                {sortBy === "AscDevice" ? (
                  <span>
                    <ArrowDropUp />
                  </span>
                ) : (
                  <span>
                    <ArrowDropDown />
                  </span>
                )}
              </th>

              <th>Location</th>
              <th>Type</th>
              <th>Status</th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              [1, 2, 3, 4, 5]?.map((val) => (
                <tr key={val}>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                  <td>
                    <Skeleton animation="wave" />
                  </td>
                </tr>
              ))
            ) : searchFilterDeviceData?.length > 0 ? (
              searchFilterDeviceData
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((data) => {
                  return (
                    <tr key={data?.id}>
                      <td>{data?.deviceName?.name || "-"}</td>
                      <td>{data?.location?.display || "-"}</td>
                      <td>{data?.type || "-"}</td>
                      <td>{data?.status || "-"}</td>
                      <td>
                        <div className="action-wrap"> 
                          {!admin ? (
                               <Tooltip title={"View"}>
                               <Link 
                                className="view-btn" 
                                 to=""
                                 onClick={() => {
                                   setSelectedDeviceData(data);
                                   setEditDeviceModalShow(true);
                                 }}
                               >
                                 <img src={ViewIcon} alt="View" />
                               </Link>
                             </Tooltip>
                          ) : (
                            <Tooltip title={"Update"}>
                              <Link
                                to=""
                                onClick={() => {
                                  setSelectedDeviceData(data);
                                  setEditDeviceModalShow(true);
                                }}
                              >
                                <img src={EditIcon} alt="Update" />
                              </Link>
                            </Tooltip>
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <TableCell colSpan={"5"} style={{ textAlign: "center" }}>
                  No Data Available
                </TableCell>
              </tr>
            )}
          </tbody>
        </Table>
        {searchFilterDeviceData?.length > 0 && (
          <div>
            <CustomPagination
              tableData={searchFilterDeviceData}
              totalRows={searchFilterDeviceData?.length}
              rowsPerPage={10}
              page={page}
              handlePage={(pageNo) => setPage(pageNo)}
            />
          </div>
        )}
      </div>
    </section>
  );
}

export default DeviceList;
