import { Skeleton, TableCell, Tooltip } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppointmentsRequestsQuery } from '../../../hooks/ReactQueryHooks/useAppointmentsRequestsQuery';
import CustomPagination from '../../common/components/Pagination/CustomPagination';
import { decryptData, encryptData } from '../../EncryptDecrypt';
import Search from './Search';
import { CalendarMonth, Close, DescriptionOutlined, Done, ListAlt, VideocamOutlined , FilePresentOutlined, callIco} from '@mui/icons-material';
import InfoIconOutlined from '@mui/icons-material/InfoOutlined';
import CallIcon from '@mui/icons-material/Call';
import moment from 'moment';
import { FullPageSpinner } from '../../common/Spinner/FullPageSpinner';
import { createEncounter, updateAppointmentRequest, searchEncounters } from '../api/AppointmentRequests';
import { failed, success } from '../../common/Toastify';
import { eventType, generatePass, typeOptions, downloadTitle, dashTypes } from './Constants';
import SelectFiled from '../../common/textfield/SelectFiled';
import { useFormik } from 'formik';
import { getSignature } from '../../Zoom/functions';
import { Buffer } from "buffer";
import { NoteModal } from './NoteModal';
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import dayGridPlugin from "@fullcalendar/daygrid";
import { ViewAppointmentModal } from './ViewAppointmentModal';
import  RescheduleAppointment  from '../ManageProviderSchedule&Appointments/Reschedule';
import { AttachementModal } from './AttachementModal';
import { axios } from '../../../lib/axios';
import Video from "../../../assets/images/Video.svg";
import Audio from "../../../assets/images/Audio.svg";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { getPatientData } from "../../api/Individual";
import { ViewDate } from "../../../utils/DateSupport";
import { buildSessionTopic} from "../../../utils/ZoomSessionSupport"

const AppointmentsList = ({dashboard = false, setCount = () => {}}) => {
    const navigate = useNavigate()
    const user = useSelector((state) => state?.auth?.user);
    const {state} = useLocation();
    // const { proceedWith, requestedPeriod } = location?.state
    const [appointments, setAppointments] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [attachmentUrl, setAttachmentUrl] = useState('');
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [showAttachmentModal, setShowAttachmentModal] = useState(false);
    const [showAppointment, setShowAppointment] = useState(false);
    const [loading, setLoading] = useState(false);
    const [requestedCount, setRequestedCount] = useState(0);
    const [todayCount, setTodayCount] = useState(0);
    const [upcomingCount, setUpcomingCount] = useState(0);
    const [calendarView, setCalendarView] = useState(()=> dashboard ? false : true);
    const [scheduleModal, setScheduleModal] = useState(false);
    const [btnActive, setBtnActive] = useState("today");
    const [todayAppts, setTodayAppts] = useState([]);
    const [upcomingAppts, setUpcomingAppts] = useState([]);
    const [documentData, setDocumentData] = useState({})
    const [patients, setPatients] = useState("");
    const [inProgressEncounters, setInProgressEncounters] = useState([]);

    const onSuccess = async (data) => {
        setAppointments(await computeAppt(data));
    };

    // Function to fetch patient information by ID
    const fetchPatientInfo = async (patientId) => {
        try {
            const res = await getPatientData(patientId);
            return res?.result;
        } catch (error) {
            console.error('Failed to fetch patient data:', error);
            return null;
        }
    };

    useEffect(() => {
        if (!dashboard) return;
      
        let isMounted = true;
      
        const fetchEncounters = async () => {
          try {
            const res = await searchEncounters({
              orgId: user?.organizations?.[0]?.id,
              status: "in-progress-appointment",
              practitionerId: user?.["custom:practitioner_id"]
            });
      
            if (isMounted) {
              setInProgressEncounters(res?.data);
            }
          } catch (error) {
            console.error("Error fetching encounters:", error);
          }
      
          if (isMounted) {
            setTimeout(fetchEncounters, 10000);
          }
        };
      
        fetchEncounters();
      
        return () => {
          isMounted = false
        };
      }, [dashboard]);
      
      

    // Function to process appointment lists and fetch patient data
    const processAppointments = async (appointments) => {
        const patientIds = appointments?.map(appointment => appointment.patientId);
        const uniquePatientIds = [...new Set(patientIds)];  // Remove duplicates
        
        // filter out patient details already retrieved to increase performance
        const filtered = uniquePatientIds.filter((id) => patients[id] == null); 
        const patientPromises = filtered.map(id =>  fetchPatientInfo(id));
        const patientData = await Promise.all(patientPromises);

        // Create a map of patientId to patient data
        const patientInfo = patientData.reduce((acc, patient) => {
            if (patient && patient.id) {
                // get HCN nested in patient record.
                let healthCardNumber = (patient.identifier) ? patient.identifier.filter(item => (item.type && item.type == "HCN"))?.map(item => item.value)[0] : null
                patient.healthCardNumber = healthCardNumber;
                
                acc[patient.id] = patient;
            }
            return acc;
        }, {});
        // use spreaders to merge 2 objects.
        setPatients({...patients, ...patientInfo});
    };

    

    const computeAppt = async (data) => {
        let reqData = data
        if(dashboard){
            await processAppointments(data?.proposed);
            if (data.requested && data.requested.length > 0) {
                await processAppointments(data?.requested);
            }
            if(data.otherApps && data.otherApps.length > 0 ) {
                await processAppointments(data?.otherApps)
            }

            let bookedApps = data?.proposed
            let requestApps = data?.requested
            setRequestedCount(requestApps?.length)

            let today = moment().startOf('day');
            bookedApps = bookedApps?.filter(data => !inProgressEncounters?.some(encounter => encounter.id === data.encounterId))
            let todaysAppt = bookedApps
                ?.filter(data => {
                    const appointmentDate = moment(data?.requestedPeriod?.[0]?.start);
                    return appointmentDate.isSame(today, 'day');
                })
                ?.sort((a, b) => moment(a?.requestedPeriod?.[0]?.start) - moment(b?.requestedPeriod?.[0]?.start));

            // Filter upcoming appointments (excluding today)
            let upcomingAppt = bookedApps
                ?.filter(data => {
                    const appointmentDate = moment(data?.requestedPeriod?.[0]?.start);
                    return appointmentDate.isSameOrAfter(today, 'day') && !appointmentDate.isSame(today, 'day');
                })
                ?.sort((a, b) => moment(a?.requestedPeriod?.[0]?.start) - moment(b?.requestedPeriod?.[0]?.start));

            setTodayAppts(todaysAppt);
            setUpcomingAppts(upcomingAppt);
            setTodayCount(todaysAppt.length);
            setCount(todaysAppt.length);
            setUpcomingCount(upcomingAppt.length);
            if(formik?.values?.type === "booked"){
                if(btnActive === "today"){
                    reqData = todaysAppt
                }else{
                    reqData = upcomingAppt
                }     
            }else if(formik?.values?.type === "proposed"){
                reqData = requestApps
            }else{
                reqData = data.otherApps
            }
        }
        else if(!calendarView) {
            let status = (formik?.values?.type) ? formik?.values?.type : "all";
            let filtered = (status && status !== "all") ? reqData?.filter(item => item.status == status): reqData;
            await processAppointments(filtered);
        }
        return reqData;
    }


    const formik = useFormik({
        initialValues: {
            type: "booked",
        },
    });

    const { isLoading, refetch } = useAppointmentsRequestsQuery({
        onSuccess,
        practitionerId: user?.["custom:unique_id"],
        dashboard: dashboard,
        status: formik?.values?.type,
    });
    const handleFilterData = (data) => {
        setFilterData(data);
        // only change to first page, if there are more appts than filtered data
        if(appointments.length > data.length)// && (data.length / rowsPerPage) < page )
            page !== 0 && setPage(0);
    };
    
    const handleBtnChange = (val) => {
        setPage(0)
        if(val==="today"){
            setFilterData(todayAppts)
        }else if(val === "upcoming"){
            setFilterData(upcomingAppts)
        }else{
            setFilterData('');
        }
        setBtnActive(val);
        formik.setFieldValue("type", dashTypes[val]);
    }

    const [page, setPage] = useState(0);
    const rowsPerPage = dashboard? 10 : 10;
    const totalRows = filterData.length;

    const handleUpdateRequest = async (data, status) => {
        setLoading(true)
        const patient = await data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor
        const practitioner = await data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
            
        const patientId = patient.reference?.split("/")?.[1];
        const practitionerId = practitioner.reference?.split("/")?.[1];
        let actor = {
            display: practitioner.display,
            reference: practitioner.reference
        }
        let sessionToken;
        if (status === "booked") {                  
            let topic = buildSessionTopic(user?.name[0]?.text);
            let password= generatePass();
            await getSignature({ topic: topic, role: 0,  password:password})
                .then(async (res) => {
                    sessionToken = res.data.sessionToken;
                }).catch((error) => console.log("getsignature error", { error }))
        }
        await updateAppointmentRequest({ id: data?.id, status, patientId, practitionerId, sessionToken, actor })
            .then((res) => { success(status === "booked" ? "Appointment booked!" : "Appointment cancelled!"); refetch() })
            .catch((err) =>{ failed(err?.response?.data?.message || err?.response?.data?.error || err?.message)})
            .finally(() => setLoading(false));
        setShowAppointment(false);
    };

    const handleTypeChange = (e) => {
        setPage(0)
        formik.setFieldValue("type", e.target.value);
    }

    const handleConnect = async (data) => {
        const utcString = new Date().toISOString().split(".")[0] + ".00Z";
        let intakeQuestionsOld = data?.intakeQuestions;
        let intakeAssignedPractitioner = {
            individual: {
                display: `${user?.name[0]?.text}`,
                reference: `Practitioner/${user?.["custom:practitioner_id"]}`,
                type: "Practitioner"
            },
            period: {
                start: utcString,
                end: null
            }
        }
        let updatedIntakeQuestions = {
            ...intakeQuestionsOld,
            intakeAssignedPractitioner: intakeAssignedPractitioner
        }
        if(data?.sessionToken){
            setLoading(true)
            const base64Url = await data?.sessionToken?.split(".")[1],
            base64 = await base64Url?.replace(/-/g, "+")?.replace(/_/g, "/"),
            buff = await Buffer.from(base64, "base64"),
            payloadinit = await buff.toString("ascii"),
            parseddata = JSON.parse(payloadinit || "{}"),
            patient = data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor,
            practitioner = data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
            let encounterId = data?.encounterId ? data?.encounterId : null;
            let actor = {
                display: practitioner.display,
                reference: practitioner.reference
            }
            
            if(!data?.encounterId){
                await createEncounter({ patient, practitioner, appointmentId: data?.id, appointmentTime: data?.requestedPeriod?.[0]?.start, orgId: user?.organizations?.[0]?.id  })
                    .then((res) => {
                        encounterId = res?.data?.id
                        updateAppointmentRequest({ sessionToken: data?.sessionToken, encounterId: res?.data?.id, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                            .then((res) => res)
                            .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                            .finally(() => setLoading(false));
                    })
                    .catch((error) => console.log("encounter error", { error }))
                    .finally(() => setLoading(false))  
            }else{
                updateAppointmentRequest({ sessionToken: data?.sessionToken, encounterId: data?.encounterId, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                    .then(async(res) => {
                        const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
                        await axios.put(`encounter/${res?.result?.encounterId}`, { status: "in-progress-appointment", requestedPeriod: requestedPeriod, actor: actor, active: true, class: "NULL", intakeQuestions: updatedIntakeQuestions }) 
                    })
                    .catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
                    .finally(() => setLoading(false));
                }  
                let topic=parseddata?.tpc.trim();
                await getSignature({ topic: topic, role: 1, password: parseddata?.password })
                
                    .then(async (res) => {
                        console.log("Session token is this: ", res.data.sessionToken)
                        updateAppointmentRequest({ sessionToken: res?.data?.sessionToken, encounterId: data?.encounterId, id: data?.id, status: "booked", patientId: patient?.reference?.split("/")?.[1], practitionerId: practitioner?.reference?.split("/")?.[1] })
                            .then(async(response) => {
                                await navigate("/app/zoom-meeting-create", { state: { encounterId, appointmentData: data, topic: encryptData(parseddata?.tpc), sessionToken: res?.data?.sessionToken, password: encryptData(parseddata?.password), patientName: patient?.display, questionnairInfo: data?.intakeQuestions } });
                                await success("connected successfully");
                            })
                    }).catch((error) => console.log("getsignature error", { error }))
                    .finally(() => setLoading(false))
        }else{ failed("Session token does not exist!") }
    }

    const handleCall = async(data) =>{
            const patient = await data?.participant.filter((data) => data?.actor?.type === "Patient")?.[0]?.actor
            const practitioner = await data?.participant.filter((data) => data?.actor?.type === "Practitioner")?.[0]?.actor;           
            const encounterId = data?.encounterId ? data?.encounterId : null;
            const requestedPeriod = [{start: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]"), end: moment().utc().format("YYYY-MM-DDTHH:mm:ss[Z]")}] 
            
            let actor = {
                display: practitioner.display,
                reference: practitioner.reference
            }
            const utcString = new Date().toISOString().split(".")[0] + ".00Z";
            let intakeQuestionsOld = data?.intakeQuestions;
            let intakeAssignedPractitioner = {
                individual: {
                    display: `${user?.name[0]?.text}`,
                    reference: `Practitioner/${user?.["custom:practitioner_id"]}`,
                    type: "Practitioner"
                },
                period: {
                    start: utcString,
                    end: null
                }
            }
            let updatedIntakeQuestions = {
                ...intakeQuestionsOld,
                intakeAssignedPractitioner: intakeAssignedPractitioner
            }
            setLoading(true)
            axios.put(`encounter/${encounterId}`, { status: "in-progress-appointment", requestedPeriod: requestedPeriod, actor: actor, class: "NULL", intakeQuestions: updatedIntakeQuestions }).then((res)=>{
                if(res.status === true){
                    // navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.questionnaireResponse } });
                    navigate("/app/phone-call-create", { state: {practitionerName: practitioner?.display, practitionerId: practitioner?.reference?.split("/")?.[1], patientId: patient?.reference?.split("/")?.[1], patientName: patient?.display, encounterId, appointmentData: data, currentUser: {patient}, questionnairInfo: data?.intakeQuestions } });
                    setLoading(false)
                }
            }).catch((err) => failed(err?.response?.data?.message || err?.response?.data?.error || err?.message))
            .finally(() => setLoading(false)); 
    }

    const handleShowFile = async(data, id) => {
        setLoading(true)
        let response
        try {
            response = await axios.get(`/appointment/${id}`);
            if(response?.status){
                setLoading(false)
                const document = response?.data?.document?.find((obj)=> obj?.key == data?.key)
                setAttachmentUrl(data?.url)
                setDocumentData(document)
                setShowAttachmentModal(true)
            }
        } catch (error) {
           setLoading(false)
           failed(response?.message)
        }
    }

    return (
        <section className={dashboard? "dashboard" : "common-listing"}>
            {loading ? <FullPageSpinner /> : null}
            {showNoteModal ? <NoteModal modalShow={showNoteModal} handleShow={() => setShowNoteModal(false)} /> : null}
            {showAppointment ? <ViewAppointmentModal modalShow={showAppointment} setScheduleModal = {setScheduleModal} handleShow={() => setShowAppointment(false)} handleConnect={handleConnect} handleCall={handleCall} handleUpdateRequest={handleUpdateRequest} appointmentsList={appointments} /> : null}
            {scheduleModal ? <RescheduleAppointment modalShow={scheduleModal} handleShow={() => setScheduleModal(false)}  /> : null}
            {showAttachmentModal ? <AttachementModal modalShow={showAttachmentModal} documentData={documentData} url={attachmentUrl} handleShow={() => setShowAttachmentModal(false)} /> : null}
            {!dashboard ? <div className="heading-wrap h-change" style ={{paddingBottom: 40}}>                
                <Tooltip title="Calendar View">
                    <CalendarMonth
                        onClick={() => setCalendarView(true)}
                        style={{ color: calendarView ? '#0055aa' : "#000000", marginRight: "1rem", cursor: "pointer" }}
                    />
                </Tooltip>
                <Tooltip title="List View">
                    <ListAlt onClick={() => setCalendarView(false)}
                        style={{ color: calendarView ? '#000000' : "#0055aa", cursor: "pointer" }}
                    />
                </Tooltip>
            </div> :
            <div className="dash-buttons">
                <ButtonGroup>
                    <Button
                        variant="secondary"
                        active={btnActive === "today"}
                        onClick = {() =>  handleBtnChange("today")}
                        >Today ({todayCount})
                    </Button>
                    <Button
                        variant="secondary"
                        active={btnActive === "upcoming"}
                        onClick = {() =>  handleBtnChange("upcoming")}
                        >Upcoming ({upcomingCount})
                    </Button>
                    <Button 
                        variant="secondary"
                        active={btnActive === "requested"}
                        onClick = {() => handleBtnChange("requested")}
                        >Requested ({requestedCount})
                    </Button>
                    <Button 
                        variant="secondary"
                        active={btnActive === "rejected"}
                        onClick = {() => handleBtnChange("rejected")}
                        >Rejected
                    </Button>
                    <Button 
                        variant="secondary"
                        active={btnActive === "completed"}
                        onClick = {() => handleBtnChange("completed")}
                        >Completed
                    </Button>
                </ButtonGroup>
                <div style={{alignSelf:"start"}}>
                    <Tooltip title="Calendar View">
                        <CalendarMonth
                            onClick={() => setCalendarView(true)}
                            style={{ color: calendarView ? '#0055aa' : "#000000", marginRight: "1rem", cursor: "pointer" }}
                        />
                    </Tooltip>
                    <Tooltip title="List View">
                        <ListAlt onClick={() => setCalendarView(false)}
                            style={{ color: calendarView ? '#000000' : "#0055aa", cursor: "pointer" }}
                        />
                    </Tooltip>
                </div>
                <div className="search-block"><Search list={appointments} filterData={handleFilterData}/></div>
        </div>}

            {!dashboard && <div className="heading-wrap h-change">
                {calendarView ?
                    <>
                        {Object.keys(eventType).map((keyName) => (
                            <div className="v-full-calendar__indicators" key={keyName}>
                                <div className="v-full-calendar__indicator">
                                    <span style={{ background: eventType?.[keyName]?.backgroundColor }}></span>
                                    {eventType?.[keyName]?.name}
                                </div>
                            </div>
                        ))}
                    </>
                    : <Search list={appointments} filterData={handleFilterData} />}
                <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                    <SelectFiled
                        keyField={"type"}
                        label={"Type"}
                        formik={formik}
                        options={typeOptions}
                        onChange={handleTypeChange}
                    />
                </form>
            </div>}

            {calendarView ?
                <FullCalendar 
                  plugins={[timeGridPlugin, dayGridPlugin]}
                  initialView="dayGridMonth"
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  events={
                    appointments?.length > 0 &&
                    appointments.map((appointmentData) => ({
                      id: appointmentData.id,
                      title: appointmentData.description,
                      start: moment(appointmentData?.requestedPeriod?.[0]?.start).toDate(),
                      end: moment(appointmentData?.requestedPeriod?.[0]?.end).toDate(),
                      backgroundColor: eventType?.[appointmentData.status]?.backgroundColor,
                      borderColor: eventType?.[appointmentData.status]?.backgroundColor,
                      textColor: eventType?.[appointmentData.status]?.textColor,
                      extendedProps: {
                        serviceCategory: appointmentData?.serviceCategory?.[0]?.coding?.[0]?.display
                      }
                    }))
                  }
                  eventDisplay="block"
                  eventClick={(e) => setShowAppointment(e.event.id)}
                  eventContent={(eventInfo) => {
                    const start = eventInfo.event.start;
                    const end = eventInfo.event.end;

                    if (!start || !end) {
                      return <div>Time not available</div>;
                    }

                    const serviceCategory = eventInfo.event.extendedProps.serviceCategory;

                    return (
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '5px' }}>
                        <span><b>{moment(start).format("HH:mm") + "-" + moment(end).format("HH:mm")}</b></span>
                        <Tooltip 
                            title={serviceCategory} 
                            placement="top"
                        >
                            <InfoIconOutlined style={{ fontSize: 'medium', marginRight: '5px', cursor: 'pointer' }} />
                        </Tooltip>
                     </div>
                    );
                  }}
                />
                : <>
                    <div className="table-wrap">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>Patients Name</th>
                                    <th>Reason</th>
                                    <th>Date Of Birth</th>
                                    <th>HCN</th>
                                    <th>Appointment Time</th>
                                    {!dashboard && <th>Attachments</th>}
                                    <th style={{ textAlign: "center" }}>Communication Channel</th>
                                    {formik?.values?.type !== "cancelled" ?
                                        <th style={{ textAlign: "center" }}>Action</th> : null}
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ? (
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9]?.map((val) => (
                                        <tr key={val}>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                            <td>
                                                <Skeleton animation="wave" />
                                            </td>
                                        </tr>
                                    ))
                                ) : filterData?.length > 0 ? (
                                    filterData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((data) => {
                                        const name = patients[data.patientId]?.name[0]?.text;
                                        const dob = patients[data.patientId]?.birthDate;
                                        const hcn = patients[data.patientId]?.healthCardNumber;
                                        var a = moment().format("X");
                                        var b = moment(data?.requestedPeriod?.[0]?.start?.replace("Z", "")).format("X");
                                        //const isTimeAvailable = parseInt(a) > parseInt(b);
                                        return (
                                            <tr onClick={() => setShowAppointment(data?.id)} key={data?.id}>
                                                <td><span style={{ cursor: "pointer", textDecoration: "underline", textTransform:"capitalize"}} onClick={() => navigate("/app/patients-details", { state: { id: data?.patientId, name: encryptData(name), path : "appointment" } })}>{name}</span></td>
                                                <td>{data?.description}</td>
                                                <td>{(dob) ? ViewDate(dob) : '-'}</td>
                                                <td>{hcn}</td>
                                                <td>{`${moment(data?.requestedPeriod?.[0]?.start).format("MMM-DD-YYYY HH:mm").toUpperCase()} - ${moment(data?.requestedPeriod?.[0]?.end).format("HH:mm")}`}</td>
                                                {!dashboard && (
                                                <td onClick={(e) => { e.stopPropagation(); }}> 
                                                  {data?.document?.length > 0 &&
                                                    data?.document.map((pdfUrl, index) => (
                                                      <Tooltip key={index} title={"View"}>
                                                        <Link
                                                          onClick={()=> handleShowFile(pdfUrl, data?.id)}
                                                          variant="primary"
                                                          className="view-btn"
                                                        >
                                                          <FilePresentOutlined />
                                                        </Link>
                                                      </Tooltip>
                                                    ))}
                                                  {data?.document?.url && (
                                                    <Tooltip title={"View"}>
                                                      <Link
                                                        onClick={()=> handleShowFile(data?.document, data?.id)}
                                                        variant="primary"
                                                        className="view-btn"
                                                      >
                                                        <FilePresentOutlined />
                                                      </Link>
                                                    </Tooltip>
                                                  )}
                                                  {!data?.document?.length > 0 &&
                                                    !data?.document?.url && <div>-</div>}
                                                </td>
                                                )}
                                                <td>
                                                    <div className='action-wrap' style={{alignItems: "center"}}>
                                                    {/* Appointment type - Accepted */}
                                                    {(formik?.values?.type === "booked" || data.status === "booked") && (
                                                        // <>
                                                        //     {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                                        //         <Tooltip title={data?.questionnaireResponse?.callerNumber}>
                                                        //             <img src={Audio} alt="Audio Channel" />
                                                        //         </Tooltip>
                                                        //       ) : (
                                                        //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                                        //             <img src={Video} alt="Video Channel" /> 
                                                        //         </Tooltip>
                                                        //     )}
                                                        // </>
                                                        <>
                                                            {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                                                <Tooltip title={data?.intakeQuestions?.callerNumber}>
                                                                    <img src={Audio} alt="Audio Channel" />
                                                                </Tooltip>
                                                              ) : (
                                                                <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                                                    <img src={Video} alt="Video Channel" /> 
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                     )}
                                                     {/* Appointment type - Pending */}
                                                     {(formik?.values?.type === "proposed" || data.status === "proposed") && (
                                                        // <>
                                                        //     {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                                        //         <Tooltip title={data?.questionnaireResponse?.callerNumber}>
                                                        //             <img src={Audio} alt="Audio Channel" />
                                                        //         </Tooltip>
                                                                 
                                                        //       ) : (
                                                        //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                                        //             <img src={Video} alt="Video Channel" /> 
                                                        //         </Tooltip>
                                                        //     )}
                                                        // </>
                                                        <>
                                                            {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                                                <Tooltip title={data?.intakeQuestions?.callerNumber}>
                                                                    <img src={Audio} alt="Audio Channel" />
                                                                </Tooltip>
                                                                 
                                                              ) : (
                                                                <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                                                    <img src={Video} alt="Video Channel" /> 
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                     )}
                                                     {/* Appointment type - Rejected */}
                                                     {(formik?.values?.type === "cancelled" || data.status === "cancelled") && (
                                                        // <>
                                                        //     {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                                        //         <Tooltip title={data?.questionnaireResponse?.callerNumber}>
                                                        //             <img src={Audio} alt="Audio Channel" />
                                                        //         </Tooltip>
                                                        //       ) : (
                                                        //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                                        //             <img src={Video} alt="Video Channel" />
                                                        //         </Tooltip>
                                                        //     )}
                                                        // </>
                                                        <>
                                                            {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                                                <Tooltip title={data?.intakeQuestions?.callerNumber}>
                                                                    <img src={Audio} alt="Audio Channel" />
                                                                </Tooltip>
                                                              ) : (
                                                                <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                                                    <img src={Video} alt="Video Channel" />
                                                                </Tooltip>
                                                            )}
                                                        </>
                                                     )}
                                                     {/* Appointment type - Completed */}
                                                     {(formik?.values?.type === "completed" || data.status === "completed") && (
                                                        // <>
                                                        //     {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( 
                                                        //         <Tooltip title={data?.questionnaireResponse?.callerNumber}>
                                                        //             <img src={Audio} alt="Audio Channel" />
                                                        //         </Tooltip> 
                                                        //       ) : (
                                                        //         <Tooltip title={data?.questionnaireResponse?.preferredChannel}>
                                                        //             <img src={Video} alt="Video Channel" />
                                                        //         </Tooltip> 
                                                        //     )}
                                                        // </>
                                                        <>
                                                            {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? ( 
                                                                <Tooltip title={data?.intakeQuestions?.callerNumber}>
                                                                    <img src={Audio} alt="Audio Channel" />
                                                                </Tooltip> 
                                                              ) : (
                                                                <Tooltip title={data?.intakeQuestions?.preferredChannel}>
                                                                    <img src={Video} alt="Video Channel" />
                                                                </Tooltip> 
                                                            )}
                                                        </>
                                                     )}
                                                    </div>
                                                </td>
                                                {formik?.values?.type !== "cancelled" ?
                                                    <td onClick={(e) => { e.stopPropagation(); }}>
                                                        <div className="action-wrap">
                                                            {formik?.values?.type === "proposed" || data.status === "proposed" ?
                                                                data?.isFromWeb ?
                                                                    <span>Patient haven't accepted</span>
                                                                    : <>
                                                                        <Tooltip title={"Accept"}>
                                                                            <Link to="" variant="primary" onClick={() => handleUpdateRequest(data, "booked")} className="success-btn">
                                                                                <Done style={{ color: "white" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                        <Tooltip title={"Reject"}>
                                                                            <Link to="" variant="primary" onClick={() => handleUpdateRequest(data, "cancelled")} className="delete-btn">
                                                                                <Close style={{ color: "white" }} />
                                                                            </Link>
                                                                        </Tooltip>
                                                                    </>

                                                            : null}
                                                            {(formik?.values?.type === "booked" || data.status === "booked") && (                                                                
                                                                <Tooltip title="Start Session">
                                                                    {/* {data?.questionnaireResponse?.preferredChannel === 'phone' || data?.questionnaireResponse?.preferredChannel === 'Phone' ? ( */}
                                                                    {data?.intakeQuestions?.preferredChannel === 'phone' || data?.intakeQuestions?.preferredChannel === 'Phone' ? (
                                                                        <Link to="" variant="primary" onClick={()=> handleCall(data)} className="success-btn" >
                                                                            <CallIcon style={{ color: "white" }} />
                                                                        </Link>
                                                                     ) : (
                                                                        <Link to="" variant="primary" onClick={() => handleConnect(data)} className="success-btn" >
                                                                            <VideocamOutlined style={{ color: "white"}} />
                                                                        </Link>
                                                                    )} 
                                                                </Tooltip>
                                                            )}
                                                                
                                                            {formik?.values?.type === "completed" || data.status === "completed" ?
                                                                <Tooltip title={"Session Note"}>
                                                                    <Link to="" variant="primary" onClick={(e) => setShowNoteModal(data.id)} className="view-btn" style={{backgroundColor: "#fff", border: "1px solid #cee0ef"}}>
                                                                        <DescriptionOutlined style={{ color: "#005da8", width: "20px", height: "20px"}} />
                                                                    </Link>
                                                                </Tooltip>
                                                                : null}
                                                        </div>
                                                    </td> : null}
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                            No Data Available
                                        </TableCell>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                    {filterData?.length > 0
                        ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page}
                            handlePage={(pageNo) => setPage(pageNo)} />
                        : null}
                </>}
        </section >
    )
}

export default AppointmentsList