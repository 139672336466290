import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import SelectFiled from "../../common/textfield/SelectFiled";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import { FormikObj } from "./Constants";
import { editClinicLocation } from "../../api/ClinicLocation";
import TextArea from "../../common/textfield/TextArea";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { splitContactNumber, createContactNumber } from "../../../utils/phoneNumberSupport";
import {categoryType} from "./Constants"
import { Switch } from "@mui/material";
import "./Style.css";
import { commonDisableAPI } from "../../api/CommonApis";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { useSelector } from "react-redux";
import Select from "react-select";

export function EditClinicLocation({ modalShow, handleShow, refreshTable }) { 
  const [inputValue, setInputValue] = useState("");
  const [btnLoading, setBtnLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const { getConfirmation } = useConfirmDialog();
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const admin = userRoles?.includes('Super-Admin');
  const categoryTypeList = categoryType?.map((categoryType) => ({
      value: categoryType?.code,
      label: categoryType?.name || "Unnamed Type",
    })) 

  const onSuccessOrg = (data) => {
    setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
  };
  useOrgQuery({ onSuccess: onSuccessOrg });

  const formik = useFormik({
    ...FormikObj,
    onSubmit: (values) => {
      values.postalCode=values?.postalCode?.toUpperCase()
      values.phone_number = createContactNumber(values);
      setBtnLoading(true);      
      editClinicLocation({
        ...values,
        id: modalShow?.id,
        orgName: organizations.filter((org) => org?.id === values?.orgId)?.[0]
          ?.name,
      })
        .then((res) => {
          if (res.status === true) {
            success(res.message);
            handleShow();
            refreshTable();
          } else {
            failed(res.error);
          }
        })
        .catch((res) => {
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          );
        })
        .finally(() => setBtnLoading(false));
    },
  });

  useEffect(() => {
    const selectedCategories = categoryTypeList.filter(option =>
      modalShow?.categoryTypeCode?.includes(option.value)
    ); 
    if (modalShow?.id) {
      formik.setFieldValue("orgId",modalShow?.managingOrganization?.reference.split("/").pop() || "");
      formik.setFieldValue("orgName", modalShow?.managingOrganization?.display || "");
      formik.setFieldValue("name", modalShow?.name || "");
      formik.setFieldValue("description", modalShow?.description || "");
      formik.setFieldValue("address", modalShow?.address?.[0]?.line?.[0] || "");
      formik.setFieldValue("postalCode",modalShow?.address?.[0]?.postalCode || "");
      setInputValue(modalShow?.address?.[0]?.postalCode || "")
      formik.setFieldValue("country", modalShow?.address?.[0]?.country || "");
      formik.setFieldValue("state", modalShow?.address?.[0]?.state || "");
      formik.setFieldValue("city", modalShow?.address?.[0]?.city || "");
      formik.setFieldValue("phone_number", splitContactNumber(modalShow?.telecom[0]?.value || "").phone_number);
      formik.setFieldValue("ext", splitContactNumber(modalShow?.telecom[0]?.value || "").ext);
      formik.setFieldValue("email", modalShow?.telecom?.[1]?.value || "");
      formik.setFieldValue("fax", modalShow?.telecom?.[2]?.value || "");
      formik.setFieldValue("categoryType", selectedCategories);
      formik.setFieldValue("sftEnabled", modalShow?.sftEnabled);
      formik.setFieldValue("code", modalShow?.type?.[0]?.coding?.[0]?.code);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalShow?.id]);

    //validation if Country is selected but region not selected
    const handleFormChange = (e) => {
        if (e?.target?.id === "country") {
            formik.setFieldValue("state", "");
            formik.setFieldValue("postalCode", "");
        }
    };
    const handlePostalCodeChange = (e) => {
      const { value } = e.target;
      // Remove spaces and non-alphanumeric characters
      const formattedValue = value.replace(/[^A-Za-z0-9]/g, "");
      // Add a space after every 3 letters
      const spacedValue = formattedValue.replace(
        /^([A-Za-z0-9]{3})([A-Za-z0-9]+)/,
        "$1 $2"
      );
      // Set the formatted value in the state or formik
      setInputValue(spacedValue);
      formik?.setFieldValue("postalCode", spacedValue);
    };

    const handleSFTToggle = (event)=>{
      formik?.setFieldValue("sftEnabled", event.target.checked);
    }

    const handleCountryChange = (newCountry) => {
      if (newCountry !== formik.values.country) {
        formik.setFieldValue("state", ""); // Reset Formik state only if the country is changing
        formik.setFieldValue("postalCode", "");
        formik.setFieldValue("city", "");
        setInputValue("")
      }
    };

    const handleDelete = async (id, entryName) => {
      const confirmed = await getConfirmation({
          title: "Attention!",
          entryName,
          actionBtnTitle: "Delete"
      });
      if (confirmed) {
          setDeleting(id);
          commonDisableAPI({ key: "location", id }).then(() => {
           //   setFilterData(filterData.filter(data => data.id !== id));
              success("Location Deleted!");
              handleShow();
              refreshTable();
          }).catch(res => failed(res?.response?.data?.message || res?.response?.data?.error || res.message)).finally(() => setDeleting(false));
      }
  }

  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      backdropClassName
      backdrop={"static"}
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Location Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          className="common-form border-fields"
          onSubmit={formik.handleSubmit}
          onChange={handleFormChange}
        >
          <Row>
            {admin && (
              <Col>
              <TextInput
                 keyField={"orgName"}
                 label={"Organization"}
                 formik={formik}
                 disabled={true}
              />
            </Col>
            )}
            <Col>
              <TextInput
                keyField={"name"}
                label={"Name"}
                formik={formik}
                placeholder={"Name"}
              />
            </Col>
            <Col className="field-wrap multiselect">
              <label id="mutiple-select-label"> Type <span style={{ color: "red" }}> *</span> </label>
              <Select
                options={categoryTypeList}
                isMulti
                value={formik.values.categoryType} 
                onChange={(selected) => formik.setFieldValue("categoryType", selected || [])}
                placeholder="Select Type"
              />
               <div>
                {formik.touched.categoryType && formik.errors.categoryType ? (
                  <div className="error-text">{formik.errors.categoryType}</div>
                ) : null}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                keyField={"description"}
                label={"Description"}
                formik={formik}
                placeholder={"Description..."}
              />
            </Col>
          </Row>
          <div className="address-block-popup mb-3">
            <Row className="ms-0 me-0">
              <Col>Address Details</Col>
            </Row>
            <Row className="ms-0 me-0 mt-3 border-row">
              <Row>
                <Col>
                  <TextInput
                    keyField={"address"}
                    label={"Address"}
                    formik={formik}
                    placeholder={"Address"}
                  />
                </Col>
                <Col>
                  <CountrySelect
                    country={formik?.values?.country}
                    keyField={"country"}
                    setCountry={handleCountryChange}
                    label={"Country"}
                    required
                    formik={formik}
                  />
                </Col>
                
              </Row>
              <Row>
                
                <Col>
                  <TextInput
                    keyField={"city"}
                    label={"City"}
                    formik={formik}
                    placeholder={"City"}
                  />
                </Col>
                <Col>
                <RegionSelect
                    country={formik?.values?.country}
                    region={formik?.values?.state}
                    keyField={"state"}
                    label={
                      formik?.values?.country === "US" ? "State" : "Province"
                    }
                    defaultOptionLabel={
                      formik?.values?.country === "US"
                        ? "Select State"
                        : "Select Province"
                    }
                    required
                    formik={formik}
                  />
                </Col>
                <Col>
                  {formik?.values?.country === "US" ? (
                    <TextInput
                      keyField={"postalCode"}
                      label={"Zip Code"}
                      formik={formik}
                      placeholder={"Zip Code"}
                      // max length for both zip code and postal code(plus space)
                      maxLength={formik?.values?.country === "US" ? "5" : "7"}
                    />
                  ) : (
                    <TextInput
                      onChange={handlePostalCodeChange}
                      value={inputValue}
                      keyField={"postalCode"}
                      label={"Postal Code"}
                      formik={formik}
                      placeholder={"Postal Code"}
                      // max length for both zip code and postal code(plus space)
                      maxLength={formik?.values?.country === "US" ? "5" : "7"}
                    />
                  )}
                </Col>
              </Row>
            </Row>
          </div>
          <div className="contact-block-popup mb-3">
            <Row className="ms-0 me-0">
              <Col>Contact Details</Col>
            </Row>
            <Row className="ms-0 me-0 mt-3 border-row">
              <Col>
                <Col>
                  <TextInput
                    keyField={"phone_number"}
                    type="phone"
                    label={"Phone Number"}
                    formik={formik}
                    placeholder={"Phone Number"}
                  />
                </Col>
                <Col>
                  <TextInput
                   // disabled
                    keyField={"email"}
                    label={"Email Address"}
                    formik={formik}
                    placeholder={"Email Address"}
                    required={false}
                  />
                </Col>
              </Col>
              <Col>
                <Col>
                <TextInput
                    keyField={"ext"}
                    type="ext"
                    label={"Ext."}
                    formik={formik}
                    placeholder={"Ext"}
                    required={false}
                  />
                </Col>
                <Col>
                  <TextInput
                    keyField={"fax"}
                    type="fax"
                    label={"Fax Number"}
                    formik={formik}
                    placeholder={"Fax Number"}
                    required={formik.values.sftEnabled ? true : false}
                  />
                </Col>
              </Col>
            </Row>
          </div>
          <Row style={{marginTop: "10px"}}>
            <Col>
              <label>Enable Secure File Transfer ( SFT )</label>
              <Switch name="sftEnabled" onChange={handleSFTToggle} defaultChecked={modalShow?.sftEnabled} />
            </Col>
          </Row>
          <Row>
          <div className="btn-wrap">
            <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
            <Button onClick={handleShow} variant="secondary" title="Cancel" style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }} >
              Cancel
            </Button>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="text-center" >
            <Button type="submit" isLoading={btnLoading} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }} >
              Update
            </Button>
            </Col>
            <Col sm={12} md={12} lg={4} xl={4} className="text-center"  >
            <Button variant="secondary" title="Delete" onClick={() =>  (deleting ? null : handleDelete(modalShow?.id, modalShow?.name))} style={{ width: '100%', marginBottom: '10px', padding: '5px 10px' }}>
                          Delete
                        </Button>
                        </Col>
          </div>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
}
