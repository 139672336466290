import React from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import './ResourceHub.css';
import {categoryType} from "../../Admin/Clinic Location/Constants"

export function ViewLocation({ modalShow, handleShow }) { 
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const admin = userRoles?.includes("Super-Admin");
  const getCategoryValuesString = (codes) => {
    return categoryType
        .filter(item => codes.includes(item.code)) 
        .map(item => item.name)  
        .join(", "); 
  };
  return (
    <Modal
      size="lg"
      show={modalShow}
      onHide={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
          Location Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="location-details">
          {admin && (
            <p className="info"><strong>Organization:</strong> {modalShow?.managingOrganization?.display || "-"}</p>
          )}
          <p className="info"><strong>Name:</strong> {modalShow?.name || "-"}</p>
          <p className="info"><strong>Type:</strong> { modalShow?.categoryTypeCode ? getCategoryValuesString(modalShow.categoryTypeCode) : "-"}</p>
          <p className="info"><strong>Description:</strong> {modalShow?.description || "-"}</p>
          <div className="address-block">
            <h3>Address Details</h3>
            <p className="info"><strong>Address:</strong> {modalShow?.address?.[0]?.line?.[0] || "-"}</p>
            <p className="info"><strong>City:</strong> {modalShow?.address?.[0]?.city || "-"}</p>
            <p className="info"><strong>State/Province:</strong> {modalShow?.address?.[0]?.state || "-"}</p>
            <p className="info"><strong>Country:</strong> {modalShow?.address?.[0]?.country || "-"}</p>
            <p className="info"><strong>Postal Code:</strong> {modalShow?.address?.[0]?.postalCode || "-"}</p>
          </div>
          <div className="contact-block">
            <h3>Contact Details</h3>
            <p className="info"><strong>Phone Number:</strong> {modalShow?.telecom?.[0]?.value || "-"}</p>
            <p className="info"><strong>Email:</strong> {modalShow?.telecom?.[1]?.value || "-"}</p>
            <p className="info"><strong>Fax:</strong> {modalShow?.telecom?.[2]?.value || "-"}</p>
          </div>
          <p className="info"><strong>Secure File Transfer (SFT):</strong> {modalShow?.sftEnabled ? "Enabled" : "Disabled"}</p>
        </div>
        <div className="btn-wrap text-center">
          <button onClick={handleShow} className="btn btn-secondary custom-btn">Close</button>
        </div>
      </Modal.Body>
    </Modal>
  );
}


