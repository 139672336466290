import { axios } from "../../../lib/axios";

export const addDocument = (data) => {
    let newData = {
        "contentType": "pdf",
        "category": data?.category,
        "description": data?.description,
        "practitionerId": data?.practitionerId,
        "patientId":"",
        "documentName": data?.name,
        "orgId": data?.orgId,
        "orgName": data?.orgName,
        "status": data?.status,
        "document":{
            "data": data?.base64Document, "extension":"pdf"
        }
        
    }
    return axios.post("document", newData)
}

export const addDocumentReference = (data) => {
    let newData = {
        "description": data?.description,
        "author": data?.author,
        "orgId": data?.orgId,
        "orgName": data?.orgName,
        "status": data?.status,
        "location": data?.locations,
        "typeCode": "ref-doc",
        "url": data?.url,
        "key": data?.name
    }
    return axios.post("documentreference", newData)
}

export const getDocumentReference = (orgId, typeCode, userRoles ) => {
    return userRoles?.includes("Super-Admin") ?  axios.get(`/documentreference?typeCode=${typeCode}`) :  axios.get(`/documentreference?typeCode=${typeCode}&orgId=${orgId}`)
}

export const addDocumentDashboard = (payload) => {
    return axios.post("document", payload)
}

export const editDocumentDashboard = (payload) => {
    return axios.put(`documentreference/${payload?.id}`, payload)
}

export const getDocumentDashboard = ({status, practitionerId, orgId}) => {
    return practitionerId ? axios.get(`documentreference?status=${status}&docStatus=final&practitionerId=${practitionerId}&orgId=${orgId}&isDocumentDashboard=true`) : axios.get(`documentreference?status=${status}&docStatus=final&orgId=${orgId}&isDocumentDashboard=true`)
}

export const getAllDocumentsByPatientId = (patientId) => {
    return axios.get(`documentreference?docStatus=final&patientId=${patientId}&isDocumentDashboard=true`)
}

export const editDocument = (data) => {
    let newData = {
        category: data?.category,
        description: data?.description,
        documentName: data?.name,
        status: data?.status,
        orgName: data?.orgName
    }
    return axios.put(`documentreference/${data?.id}`, newData)
}

export const editDocumentReference = (data) => {
    let newData = {
        "description": data?.description,
        "status": data?.status,
        "location": data?.locations,
        "typeCode": "ref-doc",
        "url": data?.url,
        "key": data?.name
    }
    return axios.put(`documentreference/${data?.id}`, newData)
}

export const deleteDocument = (id) => {
    return axios.put(`documentreference/${id}`,{status : "entered-in-error"})
}

export const getDocument = (orgId, status, userRoles ) => {
    return userRoles?.includes("Super-Admin") ?  axios.get(`/documentreference?status=${status}`) :  axios.get(`/documentreference?status=${status}&orgId=${orgId}`)
}

export const getDocumentDashboardCount = ({status, practitionerId, orgId}) => {
    return  axios.get(`documentreference?docStatus=final&orgId=${orgId}&isDocumentDashboard=true`)
}