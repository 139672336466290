import { useFormik } from "formik";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import TextInput from "../../common/textfield/TextInput";
import CountrySelect from "../../common/textfield/CountrySelect";
import RegionSelect from "../../common/textfield/RegionSelect";
import SelectFiled from "../../common/textfield/SelectFiled";
import { Button } from "../../common/Button";
import { ViewDate } from "../../../utils/DateSupport";

import UpdateDetailsIcon from "../../../assets/images/update.svg";
import { editDevice } from "../../api/Individual";
import { failed, success } from "../../common/Toastify";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { Switch } from "@mui/material";

const EditDeviceModal = ({ deviceData, onShow, onHide, hasAccessToEdit }) => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const { getConfirmation } = useConfirmDialog();
  const userRoles = useSelector(
    (state) => state?.auth?.user?.["cognito:groups"]
  );
  const admin = userRoles?.includes("Super-Admin");
  const user = useSelector((state) => state?.auth?.user);

  const formik = useFormik({
    initialValues: {
      deviceName: deviceData?.deviceName?.name || "",
      location: deviceData?.location?.reference?.split("/")[1] || "",
      email: deviceData?.email || "",
      region: deviceData?.region || "",
      status: deviceData?.status || "",
      organization: deviceData?.managingOrganization?.display || "",
    },
    validationSchema: Yup.object({
      deviceName: Yup.string()
        .max(50, "Must be 50 characters or less")
        .required("Required first name field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
      email: Yup.string()
        .email("Invalid email address")
        .required("Required email field"),
      location: Yup.string()
        .required("Required field")
        .matches(/^$|^\S+.*/, "Only blankspaces is not valid."),
    }),
    onSubmit: (values) => {
      setBtnLoading(true);
      let locationData = locations?.filter(
        (loc) => values?.location === loc?.id
      );
      let locationRegion =
        locationData?.[0]?.address?.[0]?.country +
        "-" +
        locationData?.[0]?.address?.[0]?.state;
      let payload = {
        deviceName: values?.deviceName,
        location: {
          display: locationData?.[0]?.name,
          reference: `Location/${values?.location}`,
          type: "Location",
        },
        region: locationData?.[0]?.locationCode || locationRegion || "-",
      };

      editDevice(deviceData?.id, deviceData?.orgId, payload)
        .then((res) => {
          if (res?.status === true) {
            success("Device information updated");
            onHide();
          }
        })
        .catch((res) => failed(res?.message || res?.result?.message))
        .finally(() => setBtnLoading(false));
    },
  });

  const onSuccessLocations = (data) => {
    setLocations(
      data?.map((location) => (location = { ...location, value: location?.id }))
    );
  };

  useClinicLocationsQuery({
    onSuccess: onSuccessLocations,
    orgIds: admin ? false : [user?.organizations?.[0]?.id],
  });

  const handleLocationChange = (event) => {
    const value = event?.target?.value;
    formik.setFieldValue("location", value);
  };

  const handleEnable = async (id, entryName) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "Activate",
      message: "Are you sure you would like to activate ",
    });
    if (confirmed) {
      let payload = {
        active: true,
        status: "active",
      };
      editDevice(id, deviceData?.orgId, payload)
        .then((res) => {
          if (res?.status === true) {
            success("Device information updated");
            onHide();
          }
        })
        .catch((res) => failed(res?.message || res?.result?.message))
        .finally(() => setBtnLoading(false));
    }
  };

  const handleDisable = async (id, entryName) => {
    const confirmed = await getConfirmation({
      title: "Attention!",
      entryName,
      actionBtnTitle: "De-Activate",
      message: "Are you sure you would like to de-activate ",
    });
    if (confirmed) {
      let payload = {
        active: false,
        status: "inactive",
      };
      editDevice(id, deviceData?.orgId, payload)
        .then((res) => {
          if (res?.status === true) {
            success("Device information updated");
            onHide();
          }
        })
        .catch((res) => failed(res?.message || res?.result?.message))
        .finally(() => setBtnLoading(false));
    }
  };

  const handleSwitch = () => {
    deviceData?.active
      ? handleDisable(deviceData?.id, deviceData?.deviceName?.name)
      : handleEnable(deviceData?.id, deviceData?.deviceName?.name);
  };

  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      show={onShow}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">
        Device Details
        </Modal.Title>
        <div className="">
          <Switch
            checked={deviceData?.active}
            onChange={() => handleSwitch()}
            color="primary"
            disabled={!hasAccessToEdit} 
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form className="common-form border-fields userAccount">
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"deviceName"}
                label={"Device Name"}
                formik={formik}
                placeholder={"Device Name"}
                required={true}
                readOnly={!hasAccessToEdit}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                type={"email"}
                keyField={"email"}
                label={"Email Address"}
                formik={formik}
                placeholder={"Email Address"}
                required={true}
                readOnly={true}
                disabled={true}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput
                style={{
                  textTransform: "capitalize",
                }}
                type={"text"}
                keyField={"organization"}
                label={"Organization"}
                formik={formik}
                placeholder={"Organization"}
                required={true}
                readOnly={true}
                disabled={true}
              />
            </Col>
            <Col>
              <SelectFiled
                keyField={"location"}
                label={"Select Location"}
                formik={formik}
                options={locations}
                onChange={handleLocationChange}
                disabled={!admin}
              />
            </Col>
          </Row>
          {hasAccessToEdit && (
            <Row>
              <Col>
                <Button
                  style={{ marginRight: "20px" }}
                  onClick={() => {
                    onHide();
                  }}
                  variant="secondary"
                  title="Cancel"
                >
                  Cancel
                </Button>
                <Button isLoading={btnLoading} onClick={formik.handleSubmit}>
                  <img src={UpdateDetailsIcon} /> Update
                </Button>
              </Col>

              <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ alignSelf: "flex-end" }}>
                  Device Created on {ViewDate(deviceData?.createdAt)}
                </div>
              </Col>
            </Row>
          )}
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default EditDeviceModal;
