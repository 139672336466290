import React, { useEffect, useState } from "react";
import { useNavigate , Link } from "react-router-dom";
import SearchBar from "../../common/components/SearchBar";
import { Button } from "../../common/Button";
import CustomTable from "../../common/table/CustomTable";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { DataFields, DataFieldsOrgAdmin, HeadCells, HeadCellsOrgAdmin } from "./Constants";
import { commonDisableAPI } from "../../api/CommonApis";
import { failed, success } from "../../common/Toastify";
import { EditClinicLocation } from "./Edit";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery";
import { useSelector } from "react-redux";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { getLocationQuery, getOrgnizationForLocation } from "../../api/ClinicLocation";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import EditIcon from "../../../assets/images/edit.png";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import Table from "react-bootstrap/Table";
import CrossIcon from "../../../assets/images/Cross1.svg";
import RightIcon from "../../../assets/images/Tick1.svg";
import InformationIcon from "../../../assets/images/information1.svg";
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import {categoryType} from "./Constants"


function ClinicLocationList() {
    const navigate = useNavigate();
    const { getConfirmation } = useConfirmDialog();  
    const [modalShow, setModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [locations, setLocations] = useState([]);
    const [loadingLocationData, setLoadingLocationData ] = useState(false)
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const [page, setPage] = useState(0);
    const rowsPerPage = 10;
    const totalRows = filterData.length;
    
    // const onSuccessOrg = (data) => {
    //     setOrganizations(data?.map((org) => (org = { ...org, value: org.id })));
    // };

    // useOrgQuery({ onSuccess: onSuccessOrg });
      
    // const onSuccess = (data) => {
    //     setLocations(data)
    // }

    // const { isLoading, refetch, isFetching } = useClinicLocationsQuery({ onSuccess, orgnizations : organization.map((orgId)=> orgId?.id) })

    const handleShow = (data) => { setModalShow(modalShow ? false : data) };
    const handleFilterData = (data) => { 
        setFilterData(data)
    }   

    const selectedUserAction = ({ action, data }) => {
        switch (action) {
            case 'edit':
                handleShow(data)
                break;
            // case 'delete':
            //     handleDelete(data?.id, data?.name);
            //     break;
            default:
                return null;
        }
    };

    const fetchLocation = () =>{
        setLoadingLocationData(true)
        getOrgnizationForLocation().then((res)=>{
            getLocationQuery({orgnizationIds: [adminOrgs?.[0]?.id], userRoles}).then((data)=>{
                setLocations(data?.data);
                setLoadingLocationData(false)
            })
        })
    }

    useEffect(() => {
        fetchLocation()
    }, [])
    let addressData;
    let cityData;
    let faxData;
    let sftData;
    let categoryTypeCodeData;
    if (loadingLocationData) return <FullPageSpinner loadingText="Loading Locations"/>; 

    const getCategoryValuesString = (codes = []) => {
        return categoryType
            .filter(item => codes && Array.isArray(codes) && codes.includes(item.code))
            .map(item => item.name)  
            .join(", ");
    };

    return (
        <section className="common-listing">
            <EditClinicLocation
                modalShow={modalShow}
                refreshTable={fetchLocation}
                handleShow={handleShow}
            />
            <div className="heading-wrap h-change">
                <SearchBar page={page} setPage={setPage} list={locations} filterData={handleFilterData} />
                <div className="right-wrap" style={{ margin: 'left' }}>
                    <Button onClick={() => navigate("/app/add-clinic-location")} className="custom-btn" title="Add Location" style={{ width: 'auto' }}> + Create Location</Button>
                </div>
            </div>        
        
            <div className="table-wrap">
                    <Table responsive>
                        <thead>
                            <tr>                                
                                <th>Name</th>
                                <th>Type</th>
                                <th>Address</th>
                                <th>Fax</th>
                                <th>SFT</th>
                                <th style={{ textAlign: "center" }}>Description</th> 
                                <th style={{ textAlign: "center" }}>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 1 === 2 ? (
                                [1, 2, 3, 4, 5, 6, 7]?.map((val) => (
                                    <tr key={val}>
                                         <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td> 
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                        <td>
                                            <Skeleton animation="wave" />
                                        </td>
                                    </tr>
                                ))
                            ) : filterData?.length > 0 ? (
                                filterData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data) => {                                  

                                    data.address[0]?.city == undefined ? cityData = "" : cityData = data.address[0]?.city ;
                                    addressData = data.address[0]?.line[0] +','+ cityData  +' '+ data.address[0]?.state  +' '+ data.address[0]?.postalCode ;                                
                                    faxData = data.telecom[2]?.value === undefined ? "-" : data.telecom[2]?.value;
                                    sftData = data.sftEnabled === true ? <img src={RightIcon} alt="" />  : <img src={CrossIcon} alt="" /> ;
                                    categoryTypeCodeData =  getCategoryValuesString(data?.categoryTypeCode); 
                                    return (
                                        <tr key={data?.id}>
                                            <td>{data?.name?.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</td>
                                            <td>{categoryTypeCodeData || data.type[0]?.coding[0]?.display || "-"}</td>
                                            <td>{addressData}</td>
                                            <td>{faxData}</td>
                                             <td>{sftData }</td>
                                             <td style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <Tooltip title={data?.description}>                                                            
                                                    <img src={InformationIcon} alt="" />                                                          
                                                </Tooltip>
                                            </td>
                                           
                                            <td>
                                                <div className="action-wrap">
                                                    <Tooltip title={"Edit"}>
                                                            <Link to="" variant="primary" onClick={() => handleShow(data, false)} className="success-btn">
                                                                <img src={EditIcon} alt="Edit" />
                                                            </Link>
                                                        </Tooltip>                                                   
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                            ) : (
                                <tr>
                                    <TableCell colSpan="5" style={{ textAlign: "center" }}>
                                        No Data Available
                                    </TableCell>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>

                {filterData?.length > 0 ? <CustomPagination tableData={filterData} totalRows={totalRows} rowsPerPage={rowsPerPage} page={page} handlePage={(pageNo) => setPage(pageNo)} /> : null}

            {/* <CustomTable
                tableData={filterData}
                headCells={userRoles?.includes("Super-Admin") ? HeadCells : HeadCellsOrgAdmin}
                dataFields={userRoles?.includes("Super-Admin") ? DataFields : DataFieldsOrgAdmin}
                selectedUserAction={selectedUserAction}
                rowOptions={false}
                isSortDisable={true}
                // loading={isLoading}
                // fetching={isFetching}
               // deletingItem={deleting}
                page={page}
                setPage={setPage}
            /> */}
        </section >
    );
}

export default ClinicLocationList;