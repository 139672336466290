import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getPractitionerList } from "../../api/Individual";
import { failed } from "../../common/Toastify";
import {ResourceFormikObj, resourceTableTitle, resourceTableTitleSuperAdmin, noDataAvailText, truncateText} from "./constant";
import TextInput from "../../common/textfield/TextInput";
import moment from "moment";
import { useFormik } from 'formik'
import './ResourceHub.css';
import { Col, Row, Table } from "react-bootstrap";
import { InputLabel, Skeleton, TableCell, Tooltip } from "@mui/material";
import { Button } from "../../common/Button";
import EditIcon from "../../../assets/images/editIcon.svg"
import CustomPagination from "../../common/components/Pagination/CustomPagination";
import { useNavigate, Link } from "react-router-dom";
import { deleteDocument, getDocumentReference } from "../../api/FillableDocument.js";
import { EditResource } from "./editResource.js";
import { CreateResource } from "./createResource.js";
import { ViewDate } from "../../../utils/DateSupport.js";
import AddDocuments from "../../../assets/images/addDocuments.svg"
import { ViewLocation } from "./ViewLocation.js";
import { useClinicLocationsQuery } from "../../../hooks/ReactQueryHooks/useClinicLocationsQuery.js";


const ResourcesHub = () => {
  const permissions = useSelector((state) => state?.auth?.user?.permissions); 
  const navigate = useNavigate();
  const [documentList, setDocumentList] = useState([]);
  const [filteredDocumentList, setFilteredDocumentList] = useState([]);
  const totalRows = filteredDocumentList.length;
  const [page, setPage] = useState(0);
  const rowsPerPage = 6;
  const [modalShow, setModalShow] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);
  const [viewlocationData, setViewlocationData] = useState(false);
  const handleCreateShow = () => {setCreateModalShow(!createModalShow)} 
  const handleShow = (data) => { setModalShow(modalShow ? false : data) };
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [showAttachmentModal, setShowAttachmentModal] = useState(false);
  const [isViewDoc, setIsViewDoc] = useState(false);
  const [docData, setDocData] = useState(null)
  const [isLoading, setIsLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
  const organization = useSelector((state) => state?.auth?.user?.organizations)
  const orgId = organization?.[0]?.id
  const isAdmin =userRoles?.includes("Super-Admin");
  const hasResourcesWritePermissions = isAdmin || permissions.some(permission => permission === 'Write Document Reference')
  
  
  const onSuccessLocations = (data) => {
    setLocations(data);
  };

  useClinicLocationsQuery({ onSuccess: onSuccessLocations, orgnizations: [orgId] });

  const userOrganizationId = useSelector(
      (state) => state?.auth?.user?.["organizations"][0].id
  );
  
  let tableTitle = [...(isAdmin ? resourceTableTitleSuperAdmin : resourceTableTitle)];

  if (!hasResourcesWritePermissions) {
    tableTitle.pop();
  }

  const formik = useFormik({
      ...ResourceFormikObj,
      onSubmit: (values) => {
          // Other form submission logic
      }
  });

  const sortByNameAscending = (data) => {
      return data.sort((a, b) => {
          let titleA = a?.content?.[0]?.attachment?.title;
          let titleB = b?.content?.[0]?.attachment?.title

          if (titleA < titleB) return -1;
          if (titleA > titleB) return 1;
          return 0;
      });
  };

  const getListOfDocument = async () => {
      setIsLoading(true);
      const documentList = await getDocumentReference(userOrganizationId, 'ref-doc', userRoles);
      const sortedList = sortByNameAscending(documentList?.data);
      setDocumentList(sortedList);
      setFilteredDocumentList(sortedList);
      setIsLoading(false);
  };

  const handleSearchChange = (e) => {
      const searchTerm = e.target.value.toLowerCase();
      formik.setFieldValue("name", searchTerm);
      const filteredList = documentList.filter((doc) =>
            doc?.content?.[0]?.attachment?.title?.toLowerCase().includes(searchTerm)
      );
      setFilteredDocumentList(filteredList);
      setPage(0);
  };

  const handleLinkOpen = async(url) => {
    const fullUrl = url.startsWith("http") ? url : `https://${url}`;
    window.open(fullUrl, "_blank", "noopener,noreferrer");
  }

  const handleViewLocation = async (id) => {
    if(viewlocationData){
        setViewlocationData(false)
    }else{
        const clickedLocation = locations.filter(loc => loc.id === id)[0]
        setViewlocationData(clickedLocation)
    }
  }

  useEffect(() => {
      getListOfDocument();
  }, []);

  return (
      <>
        <ViewLocation modalShow={viewlocationData} handleShow={handleViewLocation}/>    
        <EditResource modalShow={modalShow} refreshTable={getListOfDocument} handleShow={handleShow} locations = {locations}/>
        <CreateResource modalShow={createModalShow} refreshTable={getListOfDocument} handleShow={handleCreateShow} locations = {locations}/>
          <form
              className="common-form border-fields resourceHub"
              onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
              }}
              style={{ margin: "20px" }}
          >
              <Row>
                  <Col md={8}>
                      <TextInput
                          keyField={"name"}
                          formik={formik}
                          placeholder={"Search Resources"}
                          hideRequired={true}
                          onChange={handleSearchChange} 
                          value={formik.values.name} 
                      />
                  </Col>
                  {hasResourcesWritePermissions && <Col md={4}>
                    <div className="right-wrap" style={{justifyContent:"end"}}>
                      <Button
                        onClick={() => handleCreateShow()}
                        className="custom-btn"
                        title="Create"
                        >
                        <img src={AddDocuments} alt="Add Docs" style={{width : "28px"}}/>
                        Create
                      </Button>
                    </div>
                  </Col>}
              </Row>
          </form>

          <div className="table-wrap" style={{ margin: "20px" }}>
              <Table responsive>
                  <thead>
                      <tr>
                          {tableTitle?.map((title) => (
                              <th
                                  key={title}
                                  style={title === "Action" ? { textAlign: "center" } : undefined}
                              >
                                  {title}
                              </th>
                          ))}
                      </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                        [1, 2, 3, 4, 5, 6].map((val) => (
                            <tr key={val}>
                                {tableTitle?.map((index) =>
                                (<td key={index}>
                                    <Skeleton animation="wave" />
                                </td>)
                                )}
                            </tr>
                            ))
                    )
                    :filteredDocumentList?.length > 0 ? (
                          filteredDocumentList
                              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                              ?.map((data) => {
                                  const { id, documentName, location, description, status, date, content } = data;
                                  let url = content?.[0]?.attachment?.url
                                  let title = content?.[0]?.attachment?.title
                                  return (
                                      <tr key={id}>
                                          <td>
                                              <div
                                                  className="click-url"
                                                  title={url}
                                                  onClick={() =>  handleLinkOpen(url)}
                                              >
                                                  {title || documentName}
                                              </div>
                                          </td>
                                          <td
                                              style={{
                                                wordBreak: "break-word",
                                                maxWidth: "200px",
                                                whiteSpace: "pre-wrap"
                                            }}
                                          >
                                              <Tooltip title={description.length > 100 ? description : ""} placement="top">
                                                    <span>{truncateText(description, 100)}</span>
                                                </Tooltip>
                                          </td>
                                          {userRoles?.includes("Super-Admin") && (
                                              <td>{data?.custodian?.display}</td>
                                          )}
                                          <td style={{ wordBreak: "break-word", maxWidth: "200px", whiteSpace: "pre-wrap" }}>
                                            {location?.length > 0 ? (
                                                <div style={{ paddingLeft: "5px", margin: 0 }}>
                                                    {location.map((item, index) => (
                                                        <>
                                                            <span
                                                                key={index}
                                                                onClick={() => handleViewLocation(item.reference.split("/")[1])}
                                                                title={"View Location"}
                                                                className="click-url"
                                                            >
                                                                {item.display}
                                                            </span>
                                                            {index < location.length - 1 && ", "}  {/* Add a comma separator */}
                                                        </>
                                                    ))}
                                                </div>
                                            ) : (
                                                "-"
                                            )}
                                          </td>


                                          <td>{ViewDate(date)}</td>
                                          <td>
                                              {status === "current" ? "Active" : "Inactive"}
                                          </td>
                                          {hasResourcesWritePermissions && <td>
                                              <div className="action-wrap user">
                                                  <Tooltip title={"Update Details"}>
                                                      <Link to="" onClick={() => {handleShow(data)}}>
                                                          <img src={EditIcon} alt="Update" />
                                                      </Link>
                                                  </Tooltip>
                                              </div>
                                          </td>}
                                      </tr>
                                  );
                              })
                      ) : (
                          <tr>
                              <TableCell
                                  colSpan={tableTitle.length}
                                  style={{ textAlign: "center" }}
                              >
                                  {noDataAvailText}
                              </TableCell>
                          </tr>
                      )}
                  </tbody>
              </Table>
              {filteredDocumentList?.length > 0 && (
                  <div>
                      <CustomPagination
                          tableData={filteredDocumentList}
                          totalRows={totalRows}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          handlePage={(pageNo) => setPage(pageNo)}
                      />
                  </div>
              )}
          </div>
      </>
  );
};

export default ResourcesHub;
