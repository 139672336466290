import React, { useEffect, useState } from "react";
import { Button } from "../../common/Button";
import { useNavigate } from "react-router-dom";
import { commonDisableAPI } from "../../api/CommonApis";
import { useConfirmDialog } from "../../../hooks/useConfirmDialog";
import { activeOptions,  nameToVal } from "./Constants";
import { failed, success } from "../../common/Toastify";
import { EditIndividual } from "./edit-individual";
import SelectFiled from "../../common/textfield/SelectFiled";
import { useFormik } from "formik";
import Table from "react-bootstrap/Table";
import { Skeleton, TableCell, Tooltip } from "@mui/material";
import { decryptData } from "../../EncryptDecrypt";
import EditIcon from "../../../assets/images/editIcon.svg";
import ViewIcon from "../../../assets/images/view.png";
import { Link } from "react-router-dom";
import {getAllUsers, getDevicesByOrg, getPatientsByOrgId} from "../../api/Individual";
import "./list.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import addUserIcon from '../../../assets/images/ant-design--user-add-outlined.svg';
import { ArrowDropDown, ArrowDropUp, } from "@mui/icons-material";
import { useOrgQuery } from "../../../hooks/ReactQueryHooks/useOrgQuery";
import { safeAgeString, dateifier, ViewDate } from "../../../utils/DateSupport";
import { useSelector } from "react-redux"; 
import { Pagination } from "@mui/material"
import IndividualSearchBar from "../../common/components/individualSearch"; 

function Employees() { 
    const { getConfirmation } = useConfirmDialog();
    const navigate = useNavigate();
    const [usersList, setUsersList] = useState([]);
    const [organizations, setOrganizations] = useState([]);
    const [organizationsData, setOrganizationsData] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState("AscName");
    const [devicemodalShow, setDeviceModalShow] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [deleting, setDeleting] = useState(false);
    const [readOnly, setReadOnly] = useState(false); 
    const [btnLoading, setBtnLoading] = useState(false); 
    const [inactive, setInactive] = useState(false); 
    const userRoles = useSelector((state) => state?.auth?.user?.["cognito:groups"]);
    const admin = userRoles?.includes('Super-Admin');
    const [selectedTab, setSelectedTab] = useState("employees")
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations)
    const loggedInUserId = useSelector((state) => state?.auth?.user?.["custom:unique_id"])
    const permissions = useSelector((state) => state?.auth?.user?.permissions);
    const practitioner = userRoles?.includes('Practitioner');
    const hasAccess = ((permissions?.includes('practitioner-create') && practitioner) || userRoles?.includes("Super-Admin"));
    const [searchVal, setSearchVal] = useState("");
    const [sortingBy, setSortingBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);
    const [page, setPage] = useState(0);
    const [totalRows, setTotalRows] = useState();
    const rowsPerPage = 10; 
    const [displayActiveFilterDropdown, setDisplayActiveFilterDropdown] = useState(true); 

    const formik = useFormik({
        initialValues: {
            org: admin ? "All" : adminOrgs?.[0]?.id,
            active: true,
        },
    });

    useOrgQuery({
        onSuccess: (data) => {
            setOrganizationsData(data);
            setOrganizations(data?.map((org) => ({ name: org?.name, value: org?.id })))
            // getUsersList({ orgId: adminOrgs?.[0]?.id, type: nameToVal[selectedTab], active: formik?.values?.active, limit: 10 , pageNumber: page + 1, searchQuery: searchVal });
            getUsersList({ orgId: adminOrgs?.[0]?.id, type: nameToVal[selectedTab], active: formik?.values?.active, limit: 10 , pageNumber: page + 1, searchQuery: searchVal });
           
        },
        refetchOnWindowFocus: false,
    })

    const handleShow = (data, isReadOnly = false, isDevice = false) => {
        setModalShow(modalShow ? false : data);
        if(hasAccess){
            setReadOnly(isReadOnly);
        } else{
            setReadOnly(true);
        }
        
    };
    
    const handleView = (data) => { 
        // const patientId = data.link?.find(link => link.target?.type === "Patient")?.target?.reference?.split("/")[1];  
        const patientId = data?.id || data.link?.find(link => link.target?.type === "Patient")?.target?.reference?.split("/")[1];
        navigate("/app/patients-details", { state: { id: patientId, name: data?.name[0]?.text, isDoctor: false } })
    }; 

    const getUsersList = (apiArgs, active) => {
        setIsLoading(true);
        getAllUsers(apiArgs, active)
            .then((res) => {
                setIsLoading(false);
                setUsersList(res?.data);
                setTotalRows(res?.totalCount);
            })
            .catch((res) => {
                failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
            }).finally(() => { setIsLoading(false) });
    };

    //function for delete individual
    const handleDelete = async (id, entryName) => {
        const body = {
            key: "person",
            id,
            type: formik?.values?.type === "All" ? false : formik?.values?.type,
            userOrgId: adminOrgs?.[0]?.id
        };

        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Deactivate",
            message: "Are you sure you would like to deactivate "
        });

        if (confirmed) {
            handleShow()
            setIsLoading(true)
            setDeleting(id);
            commonDisableAPI(body)
                .then(() => {
                    setFilterData(filterData.filter((data) => data.id !== id));
                    success("Individual Deactivated!");
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => {setDeleting(false)
                                setIsLoading(false)});
        }
    };

    //To enable disabled person
    const handleEnableClick = async (id, entryName) => {
        const status = "enable";
        const confirmed = await getConfirmation({
            title: "Attention!",
            entryName,
            actionBtnTitle: "Activate",
            message: "Are you sure you would like to activate "
        });
        if (confirmed) {
            setBtnLoading(id);
            commonDisableAPI({
                key: "person",
                id,
                status,
            }).then(() => {
                setFilterData(filterData.filter((data) => data.id !== id));
                success("Activated Individual!");

            }).catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
        }
    };

     
    const handleOrgChange = (event) => {
        const typeSelected = nameToVal[selectedTab]
        let isActive = formik?.values?.active;
        if (selectedTab === "employees") { 
            setInactive(false); 
        }
        let value = event?.target?.value;
        formik.setFieldValue("org", value); 
        setPage(0);
        getUsersList({ orgId: value, type: "Practitioner", active: isActive, limit: 10, pageNumber: 1, searchQuery: searchVal });
    };

    useEffect(() => {
        if (selectedTab !== null) {
            const value = nameToVal[selectedTab];
            if (admin) {
                formik.setFieldValue("org", selectedTab === "employees" ? "All" : organizations?.[0]?.value)
            }
            let orgId = admin ? (selectedTab === "employees" ? "All" : organizations?.[0]?.value) : formik?.values?.org;
            let isActive = formik?.values?.active;
            setDisplayActiveFilterDropdown(true);
            getUsersList({ orgId, type: value, active: isActive, limit: 10, pageNumber: 1, searchQuery: searchVal });
    
            setPage(0);
        }
    }, [selectedTab]);
 

    const handleActiveChange = (event) => {
        const typeSelected = nameToVal[selectedTab]
        let orgId = formik?.values?.org;
        orgId = orgId === "All" ? "" : orgId;
        const value = event?.target?.value;
        formik.setFieldValue("active", value);
        if(value === "true"){
            setInactive(false);
        }else{
            setInactive(true)
        }
        getUsersList({ orgId, type: typeSelected, active: value, limit: 10 , pageNumber: 1, searchQuery: searchVal })
        setPage(0);
    }; 

    const handleSort = (sort_by, sort_order) => {
        const sortOrder = sort_order === 'asc' ? 1 : -1;
        setSortingBy(sort_by);
        setSortOrder(sortOrder);
        getUsersList({
            orgId: formik?.values?.org, type: nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchVal, sortBy: sort_by, sortOrder: sortOrder
        });
    }
 
    const sortFilterData = (sort_by) => {
        setSortBy(sort_by)
        switch (sort_by) {
            case "AscName":
                handleSort("name","asc")
                // setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) > decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) > decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "DscName":
                handleSort("name","desc")
                // setFilterData(filterData?.sort((a, b) => (decryptData(a.name?.[0]?.text) < decryptData(b.name?.[0]?.text)) ? 1 : ((decryptData(b.name?.[0]?.text) < decryptData(a.name?.[0]?.text)) ? -1 : 0)))
                break;
            case "AscOrg":
                handleSort("managingOrganization","asc")
                // setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display > b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display > a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break;
            case "DscOrg":
                handleSort("managingOrganization","desc")
                // setFilterData(filterData?.sort((a, b) => (a.managingOrganization?.[0]?.display < b.managingOrganization?.[0]?.display) ? 1 : ((b.managingOrganization?.[0]?.display < a.managingOrganization?.[0]?.display) ? -1 : 0)))
                break; 
            default:
                handleSort("name","asc")
                // setFilterData(filterData)
                break;
        }
    }
    const computeOptions = (org) => {
        let res =  org.filter(org => org.value !== adminOrgs?.[0]?.id)
        let firstOrg;
        if(admin){
            firstOrg = {name: "All", value: "All"};
            return [firstOrg, ...org];
        }
        return [{name: adminOrgs?.[0]?.name, value:adminOrgs?.[0]?.id }, ...res]
    }
 
    const handlePage = async(pageNo) => {
        setPage(pageNo)
        let orgId = formik?.values?.org;
        let isActive = formik?.values?.active;
        getUsersList({ orgId, active: isActive ,type: nameToVal[selectedTab], limit: 10 , pageNumber: pageNo + 1, searchQuery: searchVal, sortBy: sortingBy, sortOrder: sortOrder})
    };

    // Search handler
    const handleSearch = (searchQuery) => {
        setSearchVal(searchQuery)
        getUsersList({ orgId : formik?.values?.org, active: formik?.values?.active ,type: nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchQuery})
    }; 
    
    return (
        <section className="common-listing">
            {modalShow ?
                <EditIndividual
                    modalShow={modalShow}
                    handleShow={handleShow}
                    handleDelete ={handleDelete}
                    handleEnableClick = {handleEnableClick}
                    refreshTable={() => { getUsersList({ orgId: formik?.values?.org, type: formik?.values?.type === "All" ? "" : nameToVal[selectedTab], limit: 10 , pageNumber: page + 1, searchQuery: searchVal })
                    }}
                    isReadOnly={readOnly}
                    inactive={inactive}
                    typeOfUser={formik?.values?.type === "All" ? "" : formik?.values?.type}
                    organizations={organizations}
                    organizationsData={organizationsData}  
                /> : null}
                    <Row className="user-Management-header"> 
                        <Col md="auto">
                            <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                                    <SelectFiled
                                        keyField={"org"}
                                        label={false}
                                        formik={formik}
                                        selectLabel={"Select Organization"}
                                        options={computeOptions(organizations)}
                                        onChange={handleOrgChange}
                                        required={false}
                                        optional={false}
                                        readOnly={isLoading}
                                    />

                                </form>
                        </Col>
                        <Col md="auto">
                            {displayActiveFilterDropdown && <form className="common-form border-fields dropdown-filter-rw d-flex align-items-center">
                                    
                                    <SelectFiled
                                        keyField={"active"}
                                        label={false}
                                        formik={formik}
                                        options={activeOptions}
                                        onChange={handleActiveChange}
                                        required={false}
                                        optional={false}
                                        readOnly={isLoading || !formik?.values?.org}
                                    />
                                </form>}
                        </Col>
                        
                        {selectedTab !== "devices" && <Col style={{display:"flex", justifyContent: "flex-end"}}>
                            <IndividualSearchBar page={page} setPage={setPage} encoded={true} list={usersList} filterData={(data) => setFilterData(data)} onSearch={handleSearch} searchVal={searchVal} setSearchVal={setSearchVal}/>
                            
                        </Col>} 
                        {hasAccess && ( <Col md="auto" style={{display:"flex", justifyContent: "flex-end"}}>
                             <Button
                                onClick={() => navigate("/app/create-employee")}
                                title="Create Employee"
                            >
                            <img style={{width:"26px", height:"26px"}}src={addUserIcon} />
                                Create Employee
                            </Button>
                        </Col>
                        )}
                    </Row>
           
            <div className="table-wrap">
                <Table responsive>
                    <thead>
                        <tr> 
                            {selectedTab !== "devices" && <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscName" ? "DscName" : "AscName")}>Name
                                {sortBy === "AscName" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>}
                            {selectedTab === "devices" && <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscDevice" ? "DscDevice" : "AscDevice")}>Name
                                {sortBy === "AscDevice" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>}
                            {(admin && selectedTab !== "devices") && <th style={{ cursor: "pointer" }} onClick={() => sortFilterData(sortBy === "AscOrg" ? "DscOrg" : "AscOrg")}>Organization
                                {sortBy === "AscOrg" ? <span>
                                    <ArrowDropUp />
                                </span> : <span>
                                    <ArrowDropDown />
                                </span>}
                            </th>}
                            {selectedTab === "employees"
                                ? <th style={{ cursor: "pointer" }} /* onClick={() => sortFilterData(sortBy === "AscRole" ? "DscRole" : "AscRole")} */>Role
                                    {/* {sortBy === "AscRole" ? <span>
                                        <ArrowDropUp />
                                    </span> : <span>
                                        <ArrowDropDown />
                                    </span>} */}
                                </th>
                                : null}
                            {selectedTab === "patients" && <th>Date Of Birth</th>}
                            {selectedTab === "employees" || selectedTab === "device" ? null : <th>Email</th>}
                            {selectedTab !== "devices" && <th>Phone Number</th>} 
                            {hasAccess && (
                             <th
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                Action
                            </th> 
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            [1, 2, 3, 4, 5, 6, 7, 8]?.map((val) => (
                                <tr key={val}>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td>
                                    {hasAccess && (
                                    <td>
                                        <Skeleton animation="wave" />
                                    </td> 
                                    )} 
                                </tr>
                            ))
                        ) : filterData?.length > 0 && selectedTab !== "devices" ? (
                            filterData?.map((data) => {
                                const { name, birthDate, telecom, managingOrganization, id, type, createdAt } = data;
                                const isDevice = type?.[0] === "Device";
                                const decryptedBirthDate = decryptData(birthDate)?.replace(/-/g,'/');
                                const parts = decryptedBirthDate?.split('/');
                                const dateFormat = parts?.join('-');
                                return (
                                    <tr key={id}> 
                                        <td className="name-text" style={{ textTransform: 'capitalize' }}><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{decryptData(name?.[0]?.text || "-")}</span></td>
                                        {admin && <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{managingOrganization?.[0]?.display || "-"}</span></td>}
                                        {selectedTab === "employees"
                                            ? <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{data?.roleName}</span></td>
                                            : null} 
                                        {selectedTab === "employees" ? null : <td><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{telecom?.find((ele) => ele?.system === "email")?.value || "-"}</span></td>
                                        }
                                        <td style={{ whiteSpace: "nowrap" }}><span style={loggedInUserId === data?.id  ? {fontWeight: "bold"} : null}>{decryptData(telecom?.find((ele) => ele?.system === "phone")?.value || "-")}</span></td>
                                        {hasAccess && (
                                            <td>
                                                <div className={"action-wrap user"}>
                                                    {inactive ? (
                                                        <Button
                                                            onClick={() => handleEnableClick(id, decryptData(name?.[0]?.text))}
                                                            isLoading={btnLoading === id}
                                                            style={{ display: "flex", alignItems: "center" }}
                                                        >
                                                            Activate
                                                        </Button>
                                                    ) : (
                                                        <Tooltip title={"Update Details"}>
                                                            <Link 
                                                                className="view-btn"
                                                                onClick={() => { handleShow(data, false)  }} 
                                                            >
                                                                <img src={EditIcon} alt={"Update"} />
                                                            </Link>
                                                        </Tooltip>
                                                    )}
                                                </div>
                                            </td>
                                        )}

                                         
                                    </tr>
                                );
                            })
                        ) :  (
                            <tr>
                                <TableCell colSpan={"5"} style={{ textAlign: "center" }}>
                                    No Data Available
                                </TableCell>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            { filterData?.length > 0 ? 
            <div className="pagination-wrap">
                <p style={{ marginBottom: Math.ceil(totalRows / rowsPerPage) > 1 ? "10px" : "0" }}>
                    Showing {totalRows > 0 ? Math.min(page * rowsPerPage + 1, totalRows) : 0}-{totalRows > 0 ? Math.min(page * rowsPerPage + rowsPerPage, totalRows) : 0} of {totalRows}
                </p>
                <Pagination
                    page={page + 1}
                    count={Math.ceil(totalRows / rowsPerPage)}
                    color="primary"
                    boundarycount={1}
                    onChange={(event, newPage) => handlePage(newPage - 1)}
                />
            </div> : null}  
        </section>
    );
}
export default Employees;
