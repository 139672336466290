import * as Yup from "yup";
export const ResourceFormikObj = {
    initialValues: {
        name: "",
    }
}

export const FormikObj = {
    initialValues: {
        id: "",
        name: "",
        organization: "",
        description: "",
        url: "",
        status: 'current',
        locations: [],
    },
    validationSchema: Yup.object({
        name: Yup.string().max(50, "Maximum length is 50 characters.").required("Required name field").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        description: Yup.string().max(250, "Maximum length is 250 characters.").matches(/^$|^\S+.*/, 'Only blankspaces is not valid.'),
        url: Yup.string().required("Required URL field"),
    }),
}

export const categoryOptions = [
    { value: "Lab Requisition", name: "Lab Requisition" },
    { value: "Diagnostic Requisition", name: "Diagnostic Requisition" },
    { value: "Referral", name: "Referral" },
    { value: "Prescription", name: "Prescription" }
];

export const truncateText = (text, limit) => {
    if (!text) return "-";
    if (text.length <= limit) return text;
  
    const lastSpaceIndex = text.lastIndexOf(" ", limit);
    return text.slice(0, lastSpaceIndex !== -1 ? lastSpaceIndex : limit) + "...";
};

export const noDataAvailText = "No Data Available";
export const resourceTableTitle = ["Name", "Description", "Location(s)", "Date", "Status", "Action"]
export const resourceTableTitleSuperAdmin = ["Name", "Description", "Location(s)","Organiztion","Date", "Status", "Action"]