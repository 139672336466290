import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import InitialAssessmentComponent from "./InitialAssessmentComponent";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";
import Select from "react-select";
import {
  getProtocolByProtocolId,
  getProtocolsBySearchWord,
  getSearhWordsByKeyword,
} from "../api/Protocol";
import { useSelector } from "react-redux";
import { failed } from "../../common/Toastify";
import { Tooltip } from "@mui/material";
import { Link } from "react-router-dom";
import { InfoOutlined } from "@mui/icons-material";
import ProtocolDefinitionModal from "./ProtocolDefinitionModal";
import DispositionOverrideModal from "./DispositionOverrideModal";
import { Button } from "../../common/Button";
import { FaChevronDown } from "react-icons/fa";

const PositiveIndicators = ({
  protocolState,
  setProtocolState,
  stateId,
  clearProtocolState,
  preSelectProtocolType,
  handleProtocolRedirect,
}) => {
  // TODO: Chagne this hard coded to organization based setting in future
  const [protocolVersion, setProtocolVersion] = useState("2024");
  const [groupedQuestions, setGroupedQuestions] = useState(null);
  const [initialAssessmentResponses, setInitialAssessmentResponses] = useState(
    {}
  );
  const [selectedYesQuestion, setSelectedYesQuestion] = useState(null);
  const [isProtocolDataLoading, setIsProtocolDataLoading] = useState(false);
  const [selectedProtocolType, setSelectedProtocolType] = useState(
    preSelectProtocolType
  );

  useEffect(() => {
    setSelectedProtocolType(preSelectProtocolType);
  }, [preSelectProtocolType]);

  const [selectedSearchWord, setSelectedSearchword] = useState(
    protocolState?.searchWord || ""
  );
  const [isProtocolOptionsLoading, setProtocolOptionsLoading] = useState(false);
  const [protocolOptions, setProtocolOptions] = useState([]);
  const userOrgId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );
  const [protocolData, setProtocolData] = useState([]);
  const [isSearchwordLoading, setSearchwordLoading] = useState(false);

  const [selectedProtocol, setSelectedProtocol] = useState(
    protocolState?.protocol || ""
  );
  const [searchWords, setSearchWords] = useState([]);

  const [searchInput, setSearchInput] = useState("");
  const [protocolInfoModalShow, setProtocolInfoModalShow] = useState(false);
  const [adviceTexts, setAdviceTexts] = useState([]);
  const [selectedStates, setSelectedStates] = useState({});

  // TODO: Need to be moved to an organization based setting
  const [allowDispositionOverride, setAllowDispositionOverride] =
    useState(true);
  const [dispositionOverrideModalShow, setDispositionOverrideModalShow] =
    useState(false);
  const defaultDisposition = selectedYesQuestion?.dispositionHeading;

  const formik = useFormik({
    initialValues: {},
    onSubmit: (values) => {
      console.log("Submitted Values:", values);
    },
  });

  useEffect(() => {
    if (protocolState?.positiveIndicatorsGrouped) {
      autoPopulate();
    } else {
      generateDefault();
    }
  }, [protocolData, protocolState?.positiveIndicatorsGrouped]);

  useEffect(() => {
    if (protocolState?.selectedYesQuestion) {
      setSelectedYesQuestion(protocolState?.selectedYesQuestion);
    }
  }, [protocolState?.selectedYesQuestion]);

  const autoPopulate = () => {
    if (protocolState?.positiveIndicatorsGrouped) {
      setGroupedQuestions(protocolState?.positiveIndicatorsGrouped);
      setSelectedSearchword(protocolState?.searchWord);
      setSelectedProtocol(protocolState?.protocol);
    }
  };

  const generateDefault = () => {
    if (protocolData?.questions) {
      const grouped = protocolData.questions.reduce((acc, question) => {
        const { dispositionHeading, dispositionLevel } = question;

        const updatedQuestion = {
          ...question,
          isEnabled: true,
          isAnswered: false,
          answer: null,
        };

        if (!acc[dispositionHeading]) {
          acc[dispositionHeading] = { questions: [], dispositionLevel };
        }

        acc[dispositionHeading].questions.push(updatedQuestion);

        return acc;
      }, {});

      for (const group in grouped) {
        grouped[group].questions.sort(
          (a, b) => a.questionOrder - b.questionOrder
        );
      }

      let sortedGrouped = Object.keys(grouped)
        .sort(
          (a, b) => grouped[b].dispositionLevel - grouped[a].dispositionLevel
        )
        .reduce((acc, group) => {
          acc[group] = grouped[group];
          return acc;
        }, {});

      setGroupedQuestions(sortedGrouped);
    }
  };

  useEffect(() => {
    if (groupedQuestions) {
      const initialValues = groupedQuestions;

      formik.setValues(initialValues);
      if (protocolState?.protocolData) {
        setProtocolData(protocolState?.protocolData);
      }
    }
  }, [groupedQuestions]);

  const handleAnswerChange = (group, index, value) => {
    const updatedValues = { ...formik.values };
    const currentGroup = updatedValues[group];
    const updatedQuestions = [...currentGroup.questions];
    let markAsSelected;

    // Clear all selections on each answer change
    for (const grp in updatedValues) {
      if (grp !== group) {
        updatedValues[grp]?.questions.forEach((q) => {
          q.answer = null;
          q.isEnabled = true;
          q.isAnswered = false;
        });
      }
    }

    // If the answer is "Yes"
    if (value === "Yes") {
      // Mark all previous questions in all previous groups as "No"
      for (const grp in updatedValues) {
        if (grp !== group) {
          if (
            updatedValues[grp]?.dispositionLevel >
            updatedValues[group]?.dispositionLevel
          ) {
            updatedValues[grp].questions.forEach((prevQuestion) => {
              prevQuestion.answer = "No";
              prevQuestion.isAnswered = true;
            });
          }
        }
        // Mark all subsequent answers as disabled
        if (
          updatedValues[grp]?.dispositionLevel <
          updatedValues[group]?.dispositionLevel
        ) {
          updatedValues[grp].questions.forEach((q) => {
            q.answer = null;
            q.isAnswered = false;
            q.isEnabled = false;
          });
        }
      }

      // Leave the current group unanswered and mark the current question as "Yes"
      for (let i = 0; i < updatedQuestions.length; i++) {
        if (i === index) {
          updatedQuestions[i].answer = "Yes";
          updatedQuestions[i].isAnswered = true;
          setSelectedYesQuestion(updatedQuestions[i]);
          markAsSelected = updatedQuestions[i];
          //setProtocolState({
          //  ...protocolState,
          //  positiveIndicatorsGrouped: updatedValues,
          //  selectedYesQuestion: updatedQuestions[i],
          //});
        } else {
          updatedQuestions[i].answer = null;
          updatedQuestions[i].isAnswered = false;
        }

        // Disable all subsequent questions in the current group
        if (i > index) {
          updatedQuestions[i].isEnabled = false;
        }
      }
    }
    // If the answer is "No"
    else if (value === "No") {
      // Mark all the previous questions in previous groups as No
      for (const grp in updatedValues) {
        if (grp !== group) {
          if (
            updatedValues[grp]?.dispositionLevel >
            updatedValues[group]?.dispositionLevel
          ) {
            updatedValues[grp].questions.forEach((prevQuestion) => {
              prevQuestion.answer = "No";
              prevQuestion.isAnswered = true;
            });
          }
          // Mark all subsequent group questions as disabled
          if (
            updatedValues[grp]?.dispositionLevel <
            updatedValues[group]?.dispositionLevel
          ) {
            updatedValues[grp].questions.forEach((q) => {
              q.isEnabled = false;
              q.answer = null;
              q.isAnswered = false;
            });
          }
        }
      }
      updatedQuestions[index].answer = "No";
      updatedQuestions[index].isAnswered = true;

      // Check if there is at least 1 yes present
      let foundYes = false;
      for (const grp in updatedValues) {
        updatedValues[grp]?.questions.forEach((question) => {
          if (question.answer === "Yes") {
            foundYes = true;
            setProtocolState({
              ...protocolState,
              selectedYesQuestion: question,
            });
          }
        });
      }

      // If no "Yes" answer is found, enable all questions
      if (!foundYes) {
        for (const grp in updatedValues) {
          updatedValues[grp]?.questions.forEach((q) => {
            q.isEnabled = true;
          });
        }
      }
    }

    // Update the formik values with the new state
    updatedValues[group].questions = updatedQuestions;
    formik.setValues(updatedValues);

    //if (protocolState?.selectedYesQuestion) {
    //  setSelectedYesQuestion(protocolState?.selectedYesQuestion);
    //}

    setProtocolState({
      ...protocolState,
      positiveIndicatorsGrouped: updatedValues,
      documentationStarted: true,
      ...(markAsSelected && { selectedYesQuestion: markAsSelected }),
    });
  };

  const handleAssessmentChange = (responses) => {
    setInitialAssessmentResponses(responses);
    setProtocolState({
      ...protocolState,
      initialAssessmentQuestions: responses,
      documentationStarted: true
    });
  };

  const fetchProtocolOptions = async (selectedWord) => {
    setProtocolOptionsLoading(true);
    if (!selectedWord?.label) return;
    // Make an API call to get the protocols based on searchwords
    getProtocolsBySearchWord(
      selectedWord?.label,
      protocolVersion,
      selectedProtocolType,
      userOrgId
    )
      .then((res) => {
        if (res?.status === true) {
          const protocols = res?.body?.protocols || [];

          const protocolOptions = protocols
            .map((protocol) => ({
              label: protocol.name,
              value: protocol.protocolId,
            }))
            .sort((a, b) => a.label.localeCompare(b.label));

          setProtocolOptions(protocolOptions);
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        );
      })
      .finally(() => setProtocolOptionsLoading(false));
  };

  const handleInputChange = (inputValue) => {
    console.log(protocolState)
    setSearchInput(inputValue);
    if (inputValue.length >= 3) {
      // Make an API call to get search words when minimum 3 characters are typed
      if (inputValue.length === 3) {
        setSearchwordLoading(true);
        getSearhWordsByKeyword(
          inputValue,
          protocolVersion,
          selectedProtocolType,
          userOrgId
        )
          .then((res) => {
            if (res?.status === true) {
              const distinctSearchWords = Array.from(
                new Map(
                  res?.body?.map((item) => [item?.searchword, item])
                ).values()
              );
              const sortedSearchWords = distinctSearchWords.sort(
                (a, b) => a.searchword.localeCompare(b.searchword) // Sort by searchword
              );

              // Map the sorted search words to the format needed by Select
              setSearchWords(
                sortedSearchWords.map((word) => ({
                  label: word?.searchword,
                  value: word?.algorithmid,
                }))
              );
            }
          })
          .catch((res) => {
            failed(
              res?.response?.data?.message ||
                res?.response?.data?.error ||
                res.message
            );
          })
          .finally(() => setSearchwordLoading(false));
      }
    } else {
      // Clear options if fewer than 3 characters
      setSearchWords([]);
    }
  };

  const fetchSelectedProtocolData = (selectedProtocol) => {
    clearProtocolState();
    if (!selectedProtocol?.value) return;
    setIsProtocolDataLoading(true);

    getProtocolByProtocolId(
      selectedProtocol?.value,
      protocolVersion,
      selectedProtocolType,
      userOrgId
    )
      .then((res) => {
        if (res?.status === true) {
          setProtocolData(res?.body);
          setProtocolState({
            id: stateId,
            searchWord: selectedSearchWord,
            protocol: selectedProtocol,
            protocolData: res?.body,
            version: res?.body?.versionYear,
            category: res?.body?.category,
          });
        }
      })
      .catch((res) => {
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res?.message
        );
      })
      .finally(() => setIsProtocolDataLoading(false));
  };

  useEffect(() => {
    populateAdviceTexts();
  }, [protocolData, selectedYesQuestion]);

  const populateAdviceTexts = () => {
    let careAdviceTexts;

    careAdviceTexts = selectedYesQuestion?.adviceIds
      ?.map((advice) =>
        protocolData?.advice?.find((item) => item.adviceId === advice.adviceId)
      )
      .filter(Boolean)
      .sort(
        (a, b) =>
          selectedYesQuestion.adviceIds.find(
            (item) => item.adviceId === a.adviceId
          )?.adviceOrder -
          selectedYesQuestion.adviceIds.find(
            (item) => item.adviceId === b.adviceId
          )?.adviceOrder
      );

    setAdviceTexts(careAdviceTexts || []);

    initializeSelectedStates(careAdviceTexts || []);
  };

  const initializeSelectedStates = (adviceTexts) => {
    const initialStates = {};
    adviceTexts.forEach((advice) => {
      const [heading, ...content] = advice.advice.split(":");
      const bulletPoints = content
        .join(":")
        .split("*")
        .filter((point) => point.trim());

      initialStates[heading] = {
        allSelected: false,
        bullets: bulletPoints.map((point, index) => ({
          index,
          text: point.trim(),
          selected: false,
        })),
      };
    });
    setSelectedStates(initialStates);
    if (protocolState?.careAdviceResponses) {
      setSelectedStates(protocolState?.careAdviceResponses);
    }
  };

  const renderAdvice = (advice) => {
    const [heading, ...content] = advice.split(":");
    const bulletPoints = content
      .join(":")
      .split("*")
      .filter((point) => point.trim());

    // Check if all bullets are selected in the card
    const isAllSelected =
      bulletPoints.length === 0
        ? selectedStates[heading]?.allSelected // Use allSelected directly if no bullets
        : bulletPoints.every(
            (_, index) =>
              selectedStates[heading]?.bullets[index]?.selected === true
          );

    const handleCheckboxChange = (heading, index) => {
      setSelectedStates((prevStates) => {
        const newStates = { ...prevStates };
        const updatedBullets = [...newStates[heading].bullets];

        updatedBullets[index] = {
          ...updatedBullets[index],
          selected: !updatedBullets[index].selected,
        };

        const allSelected =
          updatedBullets.length > 0 &&
          updatedBullets.every((bullet) => bullet.selected);

        newStates[heading] = {
          ...newStates[heading],
          bullets: updatedBullets,
          allSelected: allSelected,
        };

        setProtocolState({
          ...protocolState,
          careAdviceResponses: newStates,
        });

        return newStates;
      });
    };

    // Handle "select all" checkbox change
    const handleSelectAllChange = (heading) => {
      setSelectedStates((prevStates) => {
        const newStates = { ...prevStates };

        // Check if bullets exist under the heading
        if (newStates[heading].bullets.length > 0) {
          // Toggle all bullets when bullets are present
          const updatedBullets = newStates[heading].bullets.map((bullet) => ({
            ...bullet,
            selected: !newStates[heading].allSelected, // Toggle based on current allSelected
          }));

          newStates[heading] = {
            ...newStates[heading],
            bullets: updatedBullets,
            allSelected: !newStates[heading].allSelected,
          };
        } else {
          // Toggle only allSelected when no bullets are present
          newStates[heading] = {
            ...newStates[heading],
            allSelected: !newStates[heading].allSelected,
          };
        }

        setProtocolState({
          ...protocolState,
          careAdviceResponses: newStates,
        });

        return newStates;
      });
    };

    return (
      <div
        key={heading}
        style={{
          border: "1px solid #005da8",
          borderRadius: "8px",
          padding: "10px",
          marginBottom: "10px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#f0f8ff",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <strong style={{ marginLeft: "8px" }}>{heading.trim()}</strong>
          {/* Select All Checkbox */}
          <input
            type="checkbox"
            checked={isAllSelected} // Check if all bullets are selected
            onChange={() => handleSelectAllChange(heading)} // Toggle all bullets when clicked
            style={{ marginLeft: "8px" }}
          />
        </div>
        <ul>
          {bulletPoints.map((point, index) => {
            // Access the state for this bullet point
            const bullet = selectedStates[heading]?.bullets[index];

            return (
              <li key={index} style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  checked={bullet ? bullet.selected : false} // Check if the checkbox is selected
                  onChange={() => handleCheckboxChange(heading, index)} // Toggle the selection state for this checkbox
                  style={{ marginRight: "8px" }}
                />
                <span style={{ marginLeft: "8px" }}>{point.trim()}</span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleOverrideSubmit = (data) => {
    setProtocolState({
      ...protocolState,
      disposition: data?.selectedDisposition,
      dispositionRationale: data?.rationale,
      dispositionLevelOverride: data?.dispositionLevel,
    });
  };

  const careAdviceRef = useRef(null);

  const handleDownArrowClick = () => {
    if (careAdviceRef.current) {
      careAdviceRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (protocolState?.protocolRedirectId) {
      setSelectedProtocol(protocolState?.protocolRedirectId);
      fetchSelectedProtocolData(protocolState?.protocolRedirectId);
    }
  }, [protocolState?.protocolRedirectId]);

  useEffect(() => {
    if (protocolState?.protocolData) {
      setProtocolData(protocolState?.protocolData);
    }
  }, [protocolState?.protocolData]);

  return (
    <div>
      <div>
        {isProtocolDataLoading && (
          <FullPageSpinner loadingText="Loading Protocol..." />
        )}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ marginTop: "5px" }}>
            <span className="label">Protocol Type:</span>
            {/* Radio buttons for Protocol Type */}
            <label style={{ marginLeft: "10px" }}>
              <input
                type="radio"
                name="protocolType"
                value="Adult"
                onChange={() => {
                  setSelectedProtocolType("Adult");
                  setProtocolState({
                    ...protocolState,
                    protocolType: "Adult",
                  });
                }}
                checked={selectedProtocolType === "Adult"}
                style={{ marginRight: "5px" }}
              />
              <span className="value">Adult</span>
            </label>
            <label style={{ marginLeft: "40px" }}>
              <input
                type="radio"
                name="protocolType"
                value="Pediatric"
                onChange={() => {
                  setSelectedProtocolType("Pediatric");
                  setProtocolState({
                    ...protocolState,
                    protocolType: "Pediatric",
                  });
                }}
                checked={selectedProtocolType === "Pediatric"}
                style={{ marginRight: "5px" }}
              />
              <span className="value">Pediatric</span>
            </label>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", width: "400px" }}
          >
            <span className="label" style={{ marginRight: "10px" }}>
              Symptoms:
            </span>
            {/* Search word drop down */}
            <Select
              onChange={(selectedWord) => {
                setSelectedSearchword(selectedWord);
                setProtocolState({
                  ...protocolState,
                  searchWord: selectedWord,
                });
                fetchProtocolOptions(selectedWord);
                // Clear the protocol dropdown options when symptoms are cleared
                if (!selectedWord) {
                  setSelectedProtocol(null);
                  setProtocolOptions([]);
                  setProtocolData(null);
                  clearProtocolState();
                  setGroupedQuestions(null);
                  setSelectedYesQuestion(null);
                }
              }}
              isMulti={false}
              name="searchwords-dropdown"
              options={searchWords}
              classNamePrefix="select"
              value={selectedSearchWord}
              isLoading={isSearchwordLoading}
              noOptionsMessage={() =>
                searchInput.length < 3
                  ? "Minimum 3 characters required"
                  : "No options"
              }
              onInputChange={handleInputChange}
              styles={{ container: (provided) => ({ ...provided, flex: 1 }) }}
              placeholder="Type searchword here..."
              isDisabled={protocolState?.documentationStarted}
            />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", width: "400px" }}
          >
            <span className="label">Protocol:</span>
            {/* Select Protocol Drop Down */}
            <Select
              onChange={(protocol) => {
                setSelectedProtocol(protocol);
                setProtocolState({
                  ...protocolState,
                  protocol: protocol,
                });
                fetchSelectedProtocolData(protocol);

                if (!selectedProtocol) {
                  //setProtocolOptions([]);
                  setProtocolData(null);
                }
              }}
              isMulti={false}
              name="protocol-dropdown"
              options={protocolOptions}
              classNamePrefix="select"
              value={selectedProtocol}
              isClearable={false} // Need to discuss if this is needed or not
              isLoading={selectedSearchWord ? isProtocolOptionsLoading : false}
              styles={{ container: (provided) => ({ ...provided, flex: 1 }) }}
              placeholder="Select Protocol..."
              isDisabled={protocolState?.documentationStarted}
            />
            {selectedProtocol && (
              <Tooltip title={"View Protocol Information"}>
                <button
                  className="view-btn"
                  onClick={() => setProtocolInfoModalShow(true)}
                  style={{
                    background: "none",
                    border: "none",
                    color: "#005ba8",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                >
                  <InfoOutlined
                    style={{
                      color: "#005ba8",
                      cursor: "pointer",
                      marginLeft: "10px",
                    }}
                  />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
        <hr style={{ margin: "10px 0" }} />
        {protocolInfoModalShow && (
          <ProtocolDefinitionModal
            protocol={selectedProtocol}
            selectedProtocolType={selectedProtocolType}
            onShow={protocolInfoModalShow}
            onHide={() => setProtocolInfoModalShow(false)}
          />
        )}
      </div>

      <div className="scrollable-content">
        <div className="side-by-side-container">
          <div className="left-div">
            <InitialAssessmentComponent
              protocolData={protocolState?.protocolData || protocolData}
              onAssessmentChange={handleAssessmentChange}
              protocolState={protocolState}
            />
          </div>
          <div className="right-div">
            <form onSubmit={formik.handleSubmit}>
              {groupedQuestions &&
                Object.entries(groupedQuestions).map(([group, data]) => (
                  <div
                    key={group}
                    style={{
                      marginBottom: "1rem",
                      border: "1px solid #005da8",
                      borderRadius: "8px",
                      padding: "15px",
                      backgroundColor: "#f0f8ff",
                    }}
                  >
                    <h5>{group}</h5>
                    <ul>
                      {data.questions.map((question, index) => (
                        <li
                          key={index}
                          style={{
                            marginBottom: "10px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                flex: 1,
                                marginRight: "20px",
                              }}
                            >
                              {question.question}
                            </span>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                                gap: "20px",
                                position: "relative",
                                paddingRight: "30px",
                              }}
                            >
                              <label>
                                <input
                                  type="radio"
                                  name={`${group}-${index}`}
                                  value="No"
                                  disabled={!question.isEnabled}
                                  checked={question.answer === "No"}
                                  onChange={() =>
                                    handleAnswerChange(group, index, "No")
                                  }
                                />
                                No
                              </label>
                              <label>
                                <input
                                  type="radio"
                                  name={`${group}-${index}`}
                                  value="Yes"
                                  disabled={!question.isEnabled}
                                  checked={question.answer === "Yes"}
                                  onChange={() =>
                                    handleAnswerChange(group, index, "Yes")
                                  }
                                />
                                Yes
                              </label>
                              {question.answer === "Yes" && (
                                <FaChevronDown
                                  style={{
                                    position: "absolute",
                                    right: "0",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    cursor: "pointer",
                                    fontSize: "20px",
                                  }}
                                  onClick={handleDownArrowClick}
                                />
                              )}
                            </div>
                          </div>
                          {selectedYesQuestion?.question ===
                            question.question &&
                            selectedYesQuestion.information && (
                              <div
                                style={{
                                  marginTop: "10px",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <InfoOutlined
                                  style={{
                                    color: "#005ba8",
                                    marginLeft: "10px",
                                    marginRight: "10px",
                                  }}
                                />
                                {selectedYesQuestion.information.startsWith(
                                  "Go to"
                                ) ? (
                                  <Button
                                    className="custom-btn"
                                    title="View Guideline"
                                    variant="primary"
                                    onClick={() =>
                                      handleProtocolRedirect({
                                        ...selectedYesQuestion,
                                        searchWord: selectedSearchWord,
                                      })
                                    }
                                    style={{ marginRight: "10px" }}
                                  >
                                    {selectedYesQuestion.information}
                                  </Button>
                                ) : (
                                  <span>{selectedYesQuestion.information}</span>
                                )}
                              </div>
                            )}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
            </form>
          </div>
        </div>
      </div>

      <div style={{ marginBottom: "80px" }}>
        <div>
          <hr style={{ margin: "10px 0" }} />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "10px 0",
          }}
        >
          {selectedYesQuestion && (
            <div ref={careAdviceRef}>
              <span className="value">Care Advice</span>
            </div>
          )}
          {selectedYesQuestion && (
            <div>
              <span className="value">Disposition: </span>
              <span className="label">
                {protocolState?.disposition ||
                  selectedYesQuestion?.dispositionHeading}
                &nbsp;&nbsp;&nbsp;
              </span>
              {protocolState?.dispositionRationale && (
                <Tooltip title={protocolState?.dispositionRationale}>
                  <InfoOutlined
                    style={{
                      color: "#005ba8",
                      cursor: "pointer",
                      marginRight: "15px",
                    }}
                  />
                </Tooltip>
              )}
              {allowDispositionOverride && selectedYesQuestion && (
                <Button
                  className="custom-btn"
                  title="View Guideline"
                  variant="primary"
                  onClick={() => setDispositionOverrideModalShow(true)}
                  style={{ marginRight: "10px" }}
                >
                  Override
                </Button>
              )}
            </div>
          )}
        </div>

        {protocolState?.careAdviceTexts
          ? protocolState?.careAdviceTexts.map((item) =>
              renderAdvice(item?.advice)
            )
          : adviceTexts.map((item) => renderAdvice(item?.advice))}
      </div>
      {dispositionOverrideModalShow && (
        <DispositionOverrideModal
          protocolData={protocolState?.protocolData || protocolData}
          currentSelectedDisposition={
            protocolState?.disposition ||
            selectedYesQuestion?.dispositionHeading
          }
          onHide={() => setDispositionOverrideModalShow(false)}
          onShow={dispositionOverrideModalShow}
          handleOverrideSubmit={handleOverrideSubmit}
        />
      )}
    </div>
  );
};

export default PositiveIndicators;
