import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import { getSchedules, getScheduleSlots } from "../../api/Schedule";
import { useSelector } from "react-redux";

const ScheduleOverview = () => {
  const [events, setEvents] = useState([]);
  const [schedules, setSchedules] = useState([]); 
  const [serviceCategories, setServiceCategories] = useState([]);
  const [serviceCategoryFilter, setServiceCategoryFilter] = useState("");
  const [practitioners, setPractitioners] = useState([]);
  const [practitionerFilter, setPractitionerFilter] = useState("");
  const loggedInUserData = useSelector((state) => state?.auth?.user);
  const calendarRef = useRef(null);

  // Treat these statuses all as "busy-unavailable"
  const busyStatuses = [
    "busy-meeting",
    "busy-unavailable",
    "busy-training",
    "busy-documentation",
    "busy-meal",
    "busy-administration",
    "busy-unscheduled",
    "busy-break",
    "busy-other",
  ];

  // Helper function to subtract slot times from availability periods
  const subtractTimePeriod = (availabilityPeriods, slotStart, slotEnd) => {
    let updatedAvailability = [];

    for (let period of availabilityPeriods) {
      if (slotEnd <= period.start || slotStart >= period.end) {
        // No overlap, keep the period
        updatedAvailability.push(period);
      } else {
        // Overlapping period, need to split
        if (slotStart > period.start) {
          // Add the period before the slot
          updatedAvailability.push({ start: period.start, end: slotStart });
        }
        if (slotEnd < period.end) {
          // Add the period after the slot
          updatedAvailability.push({ start: slotEnd, end: period.end });
        }
      }
    }

    return updatedAvailability;
  };

  // this function is used to add category types to availability. This is
  // important for reserved slots as reserved slot service categories may not
  // match what is assigned for the day.
  const insertTimePeriod = (availabilityPeriods, slotStart, slotEnd) => {
    let updatedAvailability = [...availabilityPeriods, ...[{ start: slotStart, end: slotEnd}]];
    return updatedAvailability;
  };

  // Helper function to merge overlapping availability periods
  const mergeTimePeriods = (periods) => {
    if (!periods.length) return [];

    // Sort periods by start time
    periods.sort((a, b) => a.start - b.start);

    let merged = [periods[0]];

    for (let i = 1; i < periods.length; i++) {
      let last = merged[merged.length - 1];
      let current = periods[i];

      if (current.start <= last.end) {
        // Overlapping or adjacent periods, merge them
        last.end = new Date(Math.max(last.end, current.end));
      } else {
        // No overlap, add to merged
        merged.push(current);
      }
    }

    return merged;
  };

  // Fetch schedules and store them
  const fetchSchedules = async (startDateParam, endDateParam) => {
    // Convert to ISO for API
    const startISO = startDateParam.toISOString();
    const endISO = endDateParam.toISOString();

    const schedulesResponse = await getSchedules(
      loggedInUserData["custom:unique_id"],
      loggedInUserData["custom:orgId"],
      startISO,
      endISO
    );
    const fetchedSchedules = schedulesResponse.data || [];

    // For each schedule, fetch its slots
    const schedulesWithSlots = await Promise.all(
      fetchedSchedules.map(async (schedule) => {
        const slotsResponse = await getScheduleSlots(schedule.id);
        const slots = slotsResponse.data || [];
        return { ...schedule, slots };
      })
    );

    setSchedules(schedulesWithSlots);
  };

  // processSchedules: Build availability for each serviceCategory only on its date
  const processSchedules = () => {
    if (!schedules.length) {
      setEvents([]);
      return;
    }

    // Temporary objects to help build final availability
    const allServiceCategoriesTemp = {};
    const serviceCategoryAvailabilities = {};
    const practitionersList = [];

    // Collect all unique practitioners
    for (const schedule of schedules) {
      schedule.actor?.forEach((actor) => {
        if (!practitionersList.find((p) => p.reference === actor.reference)) {
          practitionersList.push(actor);
        }
      });
    }
    setPractitioners(practitionersList);

    // Filter schedules by practitioner if needed
    const filteredSchedules = practitionerFilter
      ? schedules.filter((schedule) =>
        schedule.actor?.some(
          (actor) => actor.reference === practitionerFilter
        )
      )
      : schedules;

    for (const schedule of filteredSchedules) {
      const planningStart = new Date(schedule.planningHorizon.start);
      const planningEnd = new Date(schedule.planningHorizon.end);
      const scheduleServiceCategories = schedule.serviceCategory || [];

      // For each serviceCategory, build a single-day availability
      for (const sc of scheduleServiceCategories) {
        const scId = sc.id;
        const scName = sc.name;
        const scDate = new Date(sc.date); // "Service date"

        // Keep track of the category for the dropdown filter
        if (!allServiceCategoriesTemp[scId]) {
          allServiceCategoriesTemp[scId] = { id: scId, name: scName };
        }

        // We only show availability on that exact date (scDate).
        // 1) Construct dayStart, dayEnd for that date using planningHorizon's hours.
        const y = scDate.getFullYear();
        const m = scDate.getMonth();
        const d = scDate.getDate();

        const dayStart = new Date(
          y,
          m,
          d,
          planningStart.getHours(),
          planningStart.getMinutes()
        );
        const dayEnd = new Date(
          y,
          m,
          d,
          planningEnd.getHours(),
          planningEnd.getMinutes()
        );

        // 2) Clamp this day's availability if it goes outside the overall planningHorizon
        if (dayStart < planningStart) {
          // If the scDate's dayStart is earlier than schedule's overall start, clamp it
          if (planningStart.toDateString() === dayStart.toDateString()) {
            // Same day, but maybe earlier time
            dayStart.setHours(planningStart.getHours(), planningStart.getMinutes());
          } else if (dayStart < planningStart) {
            // Entire day is out of the horizon, skip
            continue;
          }
        }
        if (dayEnd > planningEnd) {
          // If the scDate's dayEnd is later than schedule's overall end, clamp it
          if (planningEnd.toDateString() === dayEnd.toDateString()) {
            dayEnd.setHours(planningEnd.getHours(), planningEnd.getMinutes());
          } else if (dayEnd > planningEnd) {
            // Entire day is out of the horizon, skip
            continue;
          }
        }

        // If after clamping we still have a valid range, store it
        if (dayStart < dayEnd) {
          if (!serviceCategoryAvailabilities[scId]) {
            serviceCategoryAvailabilities[scId] = [];
          }
          serviceCategoryAvailabilities[scId].push({
            start: dayStart,
            end: dayEnd,
          });
        }
      }

      // Subtract out busy slots
      const slots = schedule.slots || [];
      for (const slot of slots) {
        const slotStart = new Date(slot.start);
        const slotEnd = new Date(slot.end);
        const slotStatus = slot.status;
        const slotServiceCategories = slot.serviceCategory || [];

        /**
         * CHANGE IN FUNCTIONALITY.. if any busy status is selected, 
         * it needs to be subtracted UNLESS reserved.  If busy-reserved remove from
         * from any other categories for the day.
         * */
        if (slotStatus === "busy-reserved") {
          // Remove reserved slot from all OTHER categories that are not 
          // in the slot service categories.
          // get an array of all categories.
          let scTypes = slotServiceCategories.map(item => {
            return (item?.id) ? {id: item.id, name: item.name} : {id: item.coding[0].code, name: item.coding[0].display}
          });

          for (const key of Object.keys(serviceCategoryAvailabilities)) {
             if(scTypes.filter(item => item.id == key).length == 0) {
              serviceCategoryAvailabilities[key] = subtractTimePeriod(
                serviceCategoryAvailabilities[key],
                slotStart,
                slotEnd
              );
             }
          }
          // if the category availablity for the reserved types does NOT exist
          // ADD it to the availability.
          scTypes.forEach(type => {
            if(!Object.keys(allServiceCategoriesTemp).includes(type.id)) {
              allServiceCategoriesTemp[type.id] = type;
            }
          
            serviceCategoryAvailabilities[type.id] = insertTimePeriod(
              serviceCategoryAvailabilities[type.id] || [],
              slotStart,
              slotEnd
            );
          })
        } else if (busyStatuses.includes(slotStatus)) {
          // Subtract from all categories for this schedule
          for (const scId in serviceCategoryAvailabilities) {
            serviceCategoryAvailabilities[scId] = subtractTimePeriod(
              serviceCategoryAvailabilities[scId],
              slotStart,
              slotEnd
            );
          }
        }
      }
    }

    // Finally, merge the availability for each category and turn them into events
    const mergedEvents = [];
    for (const scId in serviceCategoryAvailabilities) {
      const mergedPeriods = mergeTimePeriods(serviceCategoryAvailabilities[scId]);
      const categoryName = allServiceCategoriesTemp[scId]?.name || "Unknown";

      mergedPeriods.forEach((period) => {
        mergedEvents.push({
          start: period.start,
          end: period.end,
          category: scId,
          categoryName: categoryName,
        });
      });
    }

    setServiceCategories(Object.values(allServiceCategoriesTemp).sort((a, b) => (a.name > b.name) ? 1 : -1));
    setEvents(mergedEvents);
  };

  // On mount, use FullCalendar's visible range to load schedules for the current week
  useEffect(() => {
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      const { activeStart, activeEnd } = calendarApi.view;
      // This ensures the data load corresponds exactly to the current visible range
      fetchSchedules(new Date(activeStart), new Date(activeEnd));
    }
  }, []);

  // Process schedules whenever schedules or practitionerFilter changes
  useEffect(() => {
    processSchedules();
  }, [schedules, practitionerFilter]);

  // Handler for reloading data based on calendar's visible range
  const handleReloadData = () => {
    const calendarApi = calendarRef.current.getApi();
    const view = calendarApi.view;
    const startDateParam = new Date(view.activeStart);
    const endDateParam = new Date(view.activeEnd);

    fetchSchedules(startDateParam, endDateParam);
  };

  // Apply service category filter
  const filteredEvents = events.filter((event) => {
    return serviceCategoryFilter
      ? event.category === serviceCategoryFilter
      : true;
  });

  return (
    <div style={{ margin: "20px" }}>
      {/* Add custom CSS for styling every other slot */}
      <style>
        {`
          /* Style for even slots */
          .even-slot {
            background-color: #f7f7f7; /* Your desired background color */
          }
          /* Style for the legend square */
          .legend-square {
            width: 40px;
            height: 40px;
            background-color: #3788d8;
            display: inline-block;
            margin-right: 5px;
          }
          .legend-item {
            display: flex;
            align-items: center;
            margin-right: 15px;
          }
          .legend-container {
            display: flex;
            align-items: center;
          }
        `}
      </style>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          marginBottom: "20px",
          justifyContent: "space-between",
        }}
      >
        {/* Filters on the left */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <div style={{ marginRight: "20px" }}>
            <label htmlFor="serviceCategoryFilter" style={{ marginRight: "10px" }}>
              Filter by Service Category:
            </label>
            <select
              id="serviceCategoryFilter"
              value={serviceCategoryFilter}
              onChange={(e) => setServiceCategoryFilter(e.target.value)}
            >
              <option value="">All</option>
              {serviceCategories.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
          <div style={{ marginRight: "20px" }}>
            <label htmlFor="practitionerFilter" style={{ marginRight: "10px" }}>
              Filter by Practitioner:
            </label>
            <select
              id="practitionerFilter"
              value={practitionerFilter}
              onChange={(e) => setPractitionerFilter(e.target.value)}
            >
              <option value="">All</option>
              {practitioners.map((practitioner) => (
                <option key={practitioner.reference} value={practitioner.reference}>
                  {practitioner.display}
                </option>
              ))}
            </select>
          </div>
          <button onClick={handleReloadData}>Reload Data for Visible Range</button>
        </div>

        {/* Legend aligned to the right */}
        <div className="legend-container">
          <div className="legend-item">
            <div className="legend-square" />
            <span>Available</span>
          </div>
          {/* You can add more legend items here if needed */}
        </div>
      </div>

      <div style={{ marginTop: "20px" }}>
        <FullCalendar
          ref={calendarRef}
          plugins={[timeGridPlugin]}
          initialView="timeGridWeek"
          // Ensure slot duration matches calculations
          slotDuration="00:30:00"
          events={filteredEvents}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "timeGridWeek,timeGridDay",
          }}
          slotLabelClassNames={(arg) => {
            const totalMinutes =
              arg.date.getHours() * 60 + arg.date.getMinutes();
            const slotIndex = totalMinutes / 30; // 30 from slotDuration
            if (Math.floor(slotIndex) % 2 === 0) {
              // Even slot
              return ["even-slot"];
            } else {
              return [];
            }
          }}
          slotLaneClassNames={(arg) => {
            const totalMinutes =
              arg.date.getHours() * 60 + arg.date.getMinutes();
            const slotIndex = totalMinutes / 30; // 30 from slotDuration
            if (Math.floor(slotIndex) % 2 === 0) {
              // Even slot
              return ["even-slot"];
            } else {
              return [];
            }
          }}
          eventContent={(eventInfo) => {
            const start = eventInfo.event.start;
            const end = eventInfo.event.end;
            if (!start || !end) {
              return <div>Time not available</div>;
            }

            const startTime = start.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            const endTime = end.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            });
            const categoryName = eventInfo.event.extendedProps.categoryName;

            return (
              <div>
                <div>{`${startTime} - ${endTime}`}</div>
                <div style={{ fontWeight: "bold" }}>{categoryName}</div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
};

export default ScheduleOverview;
