import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { Button } from '../common/Button'
import { Accordion, AccordionDetails, AccordionSummary, Typography, Tooltip } from '@mui/material'
import { ExpandMore, Launch } from '@mui/icons-material'
import TextEditor from '../common/textfield/TextEditor'
import { QuestionnaireFormikObj } from './constant'
import { isAnswered } from '../../utils/questionnaireSupport';
import { useFormik } from 'formik'
import SelectFiled from '../common/textfield/SelectFiled'
import Fields from '../Admin/Dashboard/Fields'; 
import { createQuestionnaireResponse, editQuestionnaireResponse } from "../api/Questionnaire";
import { failed, success } from "../common/Toastify";
import { useQuestionnaireQuery } from '../../hooks/ReactQueryHooks/useQuestionnaireQuery';
import { getOrganization } from '../api/Organization';
import { useSelector } from "react-redux";
import { getVideoVisitQuestionnaire } from '../api/Questionnaire'
import QuestionnaireRenderer from '../Admin/Questionnaire/QuestionnaireRenderer'
import { getEncounterQuestionnaireResponses } from '../apps/api/AppointmentRequests';
import { useConfirmDialog } from '../../hooks/useConfirmDialog'
import isEqual from 'lodash/isEqual';

const QuestionnaireBox = forwardRef((props, ref) =>{
    const { getConfirmation } = useConfirmDialog();
    const [btnLoading, setBtnLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const [questionnaireList, setQuestionnaireList] = useState([]);
    const adminOrgs = useSelector((state) => state?.auth?.user?.organizations);
    const orgId = adminOrgs?.[0]?.id;
    const [buttonText, setButtonText] = useState("Save")

    const [textEditorKey, setTextEditorKey] = useState(0);
    const [selectedQuestionnaire, setSelectedQuestionnaire] = useState(questionnaireList.length && questionnaireList[0]?.id || '');
    const [submitStatus, setSubmitStatus] = useState(false);
    const [savedQuestionnaireResponses, setSavedQuestionnaireResponses] = useState([]);
    const user = useSelector((state) => state?.auth?.user);
    const [canEditSelectedQuestionnaire, setCanEditSelectedQuestionnaire] = useState(true);
    const [intakeQuestionnaire, setIntakeQuestionnaire] = useState([]);
    const [isIntakeQuestionnaire, setIsIntakeQuestionnaire] = useState(false);
    const [initialQuestionnaireResponse, setInitialQuestionnaireResponse] = useState([]);

    
    //Submit  form  to  save/update questioner
    const formik = useFormik({
        ...QuestionnaireFormikObj,
        onSubmit: (values, { setErrors, setFieldValue }) => {
            if(!values.questionResponse){
                failed("Select and save questionnaire before proceeding");
                return;
            } 
            if (values?.questionResponse.length === 0 || values.questionResponse === undefined ) {
                failed("Save questionnaire before proceeding");
                return;
            }
            for (let i = 0; i < values?.questionResponse.length; i++) {
                const item = values?.questionResponse[i];
                if (item.required && !isAnswered(item)) {
                    setErrors({ isQuestionsError: `Question : ${item.question} is mandatory.` });
                    return;
                }
            }
            setBtnLoading(true);
            setIsDisabled(false);
            let QRid = values.questionResponseId;
            if(QRid){
                //Update Questionnaire Response
                editQuestionnaireResponse(QRid, { patientID: props?.patientId, encounterId: props?.encounterId, questionResponse: values?.questionResponse, appointmentId: props?.appointmentId , questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"] })
                    .then(async (res) => {
                        try {
                            setFieldValue("questionResponseId", res.data.id)
                            const responses = await getEncounterQuestionnaireResponses({ encounterId: props?.encounterId });
                            setSavedQuestionnaireResponses(responses);  
                            props.handleIsQuestionnaireComplete(true);
                            setSubmitStatus(true);
                            setInitialQuestionnaireResponse(values?.questionResponse);
                            success(res.message);
                        } catch (error) {
                            console.error("Error fetching questionnaire responses:", error);
                        }
                    })
                    .catch((res) => {
                        failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
                    })
                    .finally(() => {
                        setBtnLoading(false);
                    });
            } else{
                //Create a New Questionnaire Response
                createQuestionnaireResponse({ patientID: props?.patientId, encounterId: props?.encounterId, questionResponse: values?.questionResponse, appointmentId: props?.appointmentId, questionnaire: selectedQuestionnaire, authorName: user?.name[0]?.text, authorType: "Practitioner", authorId: user["custom:practitioner_id"] })
                .then(async (res) => {
                    setFieldValue("questionResponseId", res.data.id) 
                    const responses = await getEncounterQuestionnaireResponses({ encounterId: props?.encounterId });
                    setSavedQuestionnaireResponses(responses);
                    setButtonText("Update");  
                    props.handleIsQuestionnaireComplete(true);
                    setSubmitStatus(true);
                    setInitialQuestionnaireResponse(values?.questionResponse);
                    success(res.message);
                })
                .catch((res) => failed(res?.response?.data?.message || res?.response?.data?.error || res.message))
                .finally(() => setBtnLoading(false));
            }
        },
    });

    // To load and set the previously saved questioner during the initial render.
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await getOrganization(adminOrgs?.[0]?.id);
                const assignedQuestionnaire = res?.data?.assignedQuestionnaire || {};
                const videoVisitIds = assignedQuestionnaire["video-visit"] || [];
                const intakeQuestionnaireId = assignedQuestionnaire["telephone-intake"]
                let questionnaireIdsList = [];
                if('video-visit' in assignedQuestionnaire && videoVisitIds.length > 0) {
                    const VVRes = await getVideoVisitQuestionnaire(JSON.stringify(videoVisitIds), orgId)
                    const modifiedData = VVRes?.data?.map(item => ({
                        ...item,
                        name: item.title,
                        value: item.id
                    }));
                    if (intakeQuestionnaireId) {
                        modifiedData.push({
                            id: 'Intake Questionnaire',
                            name: 'Intake Questionnaire',
                            value: 'Intake Questionnaire'
                        });
                    }
                    questionnaireIdsList = modifiedData.map(item => item.id);
                    setQuestionnaireList(modifiedData); 
                }
                 
                const responseData = await getEncounterQuestionnaireResponses({ encounterId: props?.encounterId });
                if(intakeQuestionnaireId){
                    let filteredData = responseData.data
                    .filter((r) => r.questionnaire === intakeQuestionnaireId)
                    console.log(filteredData);
                    filteredData = filteredData.length > 0 ? filteredData[0] : null;
                    setIntakeQuestionnaire(filteredData);
                }

                setSavedQuestionnaireResponses(responseData);

                if ( responseData && Array.isArray(responseData.data) && responseData.data.every(item => typeof item.updatedAt === "number") ) {
                    // Sort data by `updatedAt` in descending order to get the latest questionnaire
                    const sortedData = [...responseData.data].sort((a, b) => b.updatedAt - a.updatedAt);
                    const latestQuestionnaireId = sortedData?.[0]?.questionnaire; 
                    if (!questionnaireIdsList.includes(latestQuestionnaireId) || !latestQuestionnaireId) return; 
                 
                    // Filter records with the latest questionnaire ID and sort by `authored`
                    const filteredData = responseData.data
                        .filter(record => record.questionnaire === latestQuestionnaireId)
                        .sort((a, b) => b.authored - a.authored);
                
                    const latest = filteredData.length > 0 ? filteredData[0] : null;
                    let questionResponse = [];
                
                    if (latest?.item?.length > 0) { 
                        const canEdit = checkCanEditAuthor(latest); 
                        questionResponse = canEdit
                            ? setPreviousQuestionnaire(latest)  // Use previous questionnaire if the author is the same
                            : setEmptyQuestionnaire(latestQuestionnaireId); // Otherwise, blank it out
                
                        if (canEdit) {
                            formik.setFieldValue("questionResponseId", latest.id);
                            setSelectedQuestionnaire(latestQuestionnaireId);
                            setButtonText("Update"); 
                        }
                    } else { 
                        // Brand new blank questionnaire
                        questionResponse = setEmptyQuestionnaire(latestQuestionnaireId);
                    }
                    setCanEditSelectedQuestionnaire(true);
                    setInitialQuestionnaireResponse(questionResponse);
                    formik.setFieldValue("questionResponse", questionResponse);
                } 
            } catch (error) {
                console.error('Error fetching organization data:', error);
            }
        };
        fetchData();
        // const intervalId = setInterval(fetchData, 1000);
        // return () => clearInterval(intervalId);
    }, [adminOrgs?.[0]?.id]);  
 
    useEffect(() => {
        const questionResponse = questionnaireList.filter(item => item?.id === formik?.values?.questionnaire)?.[0]?.item.map(item => ({
            id: item?.linkId,
            question: item?.text,
            answerType: item?.answerType,
            answer: item?.answerOption?.map(item => ({ answer: "", id: item?.id })),
            required: item?.required,
        }));
        formik.setFieldValue("questionResponse", questionResponse)
        setInitialQuestionnaireResponse(questionResponse);
    }, [formik.values?.questionnaire])

    // Check if current user can edit the existing questionnaire response
  const checkCanEditAuthor = (responseObj) => {
    if (!responseObj?.author?.reference) return true;
    const authorRef = responseObj.author.reference; // e.g. "Practitioner/12345..."
    const authorId = authorRef.split("/")[1];
    // Return true if the same as current user, false if not
    return authorId === user["custom:practitioner_id"];
  }

    const setEmptyQuestionnaire = (qId) => {
        return questionnaireList
            .filter(item => item?.id === qId)?.[0]?.item
            .map((item , index) => ({
                id: item?.linkId,
                question: item?.text,
                key:{index},
                questionType: item?.answerType,
                answerType: item?.answerType,
                answerOption: item?.answerOption?.map(x => ({value: ((x.value) ? x.value : x.text), name: (x.text) ? x.text: x.value, text: x.text, option: (x.value) ? x.value : x.text})),
                answer: item.answerOption?.map(item => ({ answer: "", id: item?.id, option: (item?.value) ? item.value : item?.text })),
                required: item?.required,
            }))
    }

    const setPreviousQuestionnaire = (questionResponse) => {
        return questionResponse?.item?.map((questionItem, index) => ({
            id: questionItem?.linkId,
            question: questionItem?.text,
            key: { index },
            questionType: questionItem?.questionType,
            answerType: questionItem?.questionType,
            answerOption: questionItem?.answer?.map(x => ({
                value:  x?.option,
                name: x?.option,
                text: x?.option,
                option: x?.option
            })),
            answer: questionItem?.answer?.map(answerItem => ({
                answer: answerItem?.valueString || answerItem?.ratingText || answerItem?.valueBoolean || "",
                id: questionItem?.linkId,
                option: answerItem?.option || answerItem?.valueString
            })),
            required: questionItem?.required
        }));
    };


    const handlePrompt = async (event) =>{
        const selectedQId = event.target.value;
        if(!isEqual(initialQuestionnaireResponse ,formik.values.questionResponse)){
            const confirmed = await getConfirmation({
                title: "Attention!",
                message: "You have unsaved changes. Are you sure you want to lose your unsaved changes?",
                actionBtnTitle: "Continue anyway"
            });
    
            if (confirmed) {
                
                handleSelectChange(selectedQId)
            }

        }else{
            handleSelectChange(selectedQId)
        }
    }

    const handleSelectChange = async (selectedQId) => {
        if(selectedQId === 'Intake Questionnaire'){
            setIsIntakeQuestionnaire(true)
            setSelectedQuestionnaire(selectedQId);
            setCanEditSelectedQuestionnaire(false)
            formik.setFieldValue("questionResponse", []);
            setInitialQuestionnaireResponse([])
            return;
        }
        setIsIntakeQuestionnaire(false)
        let questionResponse = [];
        
        let canEdit = true; // default to true, refine below if a different author is found
        setButtonText("Save");
        formik.setFieldValue("questionResponseId", null);
        try {
          
            // Attempt to find the latest DB-saved questionnaireResponse for that Q
            let latestResponse = null;
            if (savedQuestionnaireResponses && savedQuestionnaireResponses.data) {
                const filteredData = savedQuestionnaireResponses.data
                .filter((r) => r.questionnaire === selectedQId)
                .sort((a, b) => b.authored - a.authored); // newest first
                latestResponse = filteredData.length > 0 ? filteredData[0] : null;
            }

            if (latestResponse?.item?.length > 0) {
                // We have an existing response in DB for that Q
                canEdit = checkCanEditAuthor(latestResponse);
                if (!canEdit) {
                    // Different author => new blank
                    questionResponse = setEmptyQuestionnaire(selectedQId);
                    setButtonText("Save");
                } else {
                    // Same author => load the existing data
                    questionResponse = setPreviousQuestionnaire(latestResponse);
                    formik.setFieldValue("questionResponseId", latestResponse.id);
                    setButtonText("Update"); 
                    }
            } else {
                questionResponse = setEmptyQuestionnaire(selectedQId);
                setButtonText("Save");
            }
          
        } catch (error) {
          console.error("Error retrieving questionnaire response:", error);
        }
    
        // Update formik + local state
        setSelectedQuestionnaire(selectedQId);
        formik.setFieldValue("questionResponse", questionResponse);
        setInitialQuestionnaireResponse(questionResponse)
        setTextEditorKey((prevKey) => prevKey + 1);  
        // Finally, set or reset "canEdit" for the newly selected Q
        setCanEditSelectedQuestionnaire(true);
      };
    const handleParkCallClickCall = () => { 
        formik.handleSubmit();
        if (submitStatus) {
            props.handleParkCallClick();
        }
       
      };
    
    const handleFollowUpClickCall = () => { 
        formik.handleSubmit();
        if (submitStatus) {
            props.handleFollowUpClick(); 
        } 
      };

      const renderIntakeQuestions = (intakeQuestions) => {
        return intakeQuestions.item?.map((data) => (
            <>
            {data?.answer?.[0]?.valueString && (
              <div className="accordion-view-block mt-2" key={data?.linkId}>
                <div className="header-accordion p-3 pt-2 pb-2">
                  {data?.text}
                </div>
                <div className="body-accordion p-3">
                  <div className="m-0" dangerouslySetInnerHTML={{__html: `${data?.answer?.[0]?.valueString}`}} />
                </div>
              </div>
            )}
            {data?.questionType === "radio" && (
              data?.answer?.some(ele => ele?.valueBoolean === true) && (
                <div className="accordion-view-block mt-3" key={data?.linkId}>
                  <div className="header-accordion p-3 pt-2 pb-2">
                    {data?.text}
                  </div>
                  <div className="body-accordion p-3">
                    <div className="m-0">
                      {data?.answer?.map(ele => (
                        ele?.valueBoolean === true && (
                          <div key={ele.id}>{ele?.option}</div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
            {data?.questionType === "checkbox" && (
              data?.answer?.some(ele => ele?.valueBoolean === true) && (
                <div className="accordion-view-block mt-3" key={data?.linkId}>
                  <div className="header-accordion p-3 pt-2 pb-2">
                    {data?.text}
                  </div>
                  <div className="body-accordion p-3">
                    <div className="m-0">
                      {data?.answer?.map(ele => (
                        ele?.valueBoolean === true && (
                          <div key={ele.id}>{ele?.option}</div>
                        )
                      ))}
                    </div>
                  </div>
                </div>
              )
            )}
            </>
        ));
    };

    useImperativeHandle(ref, () => formik.handleSubmit);

    return (

        <div className="chat-sidebar" style={props?.show ? {} : { position: "absolute", left: "-1000px" }}>
            <div className="individual-chart-wrap">
                <div className="chat-body common-form" style={{ paddingTop: "1rem" }}> 
                    <SelectFiled
                        keyField={"questionnaire"}
                        label={"Select Questionnaire"}
                        formik={formik}
                        readOnly={isDisabled}
                        options={questionnaireList}
                        onChange={handlePrompt}
                        value={selectedQuestionnaire} 
                    />
                    <hr />
                    {formik.values.questionResponse && formik.values.questionResponse.length > 0 && !isIntakeQuestionnaire && (
                        <QuestionnaireRenderer 
                            formik={formik} 
                            questionResponse={formik.values.questionResponse} 
                            parentIndex={textEditorKey}
                            readOnly={!canEditSelectedQuestionnaire} 
                        />
                    )}

                {isIntakeQuestionnaire && intakeQuestionnaire && (
                    <div>
                        {renderIntakeQuestions(intakeQuestionnaire)}
                    </div>
                )}
                </div>
                <div className="msg-footer">
                    <div className="btn-wrap" style={{ display: 'flex', fontSize: "12px", justifyContent: "end", gap: "1rem" }}>
                        <Button type="submit" style={{display: "flex", fontSize: "12px"}} isLoading={btnLoading} title="Park Call" variant="primary" onClick={handleParkCallClickCall}>Park Call</Button>
                        <Button type="submit" style={{display: "flex", fontSize: "12px"}} isLoading={btnLoading} title="Add to Follow-Up" variant="primary" onClick={handleFollowUpClickCall}>Add to Follow-Up</Button>
                        {!isIntakeQuestionnaire && <Button type="submit" isLoading={btnLoading} className="custom-btn-green" onClick={formik.handleSubmit} style={{background: 'linear-gradient(90deg, #4CAF50 0%, #388E3C 100%)', fontSize: "12px"}} >{buttonText}</Button>}
                        <Button className="custom-btn ms-2" style={{ fontSize: "12px" }} title={"Proceed"} variant="primary" onClick={props.handleProceedSessionConfirmation}>Proceed</Button>
                        
                    </div>
                </div>
            </div>
        </div>

    )
});

export default QuestionnaireBox