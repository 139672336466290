import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useFormik } from "formik";
import { Button } from "../../common/Button";
import { failed, success } from "../../common/Toastify";
import TextInput from "../../common/textfield/TextInput";
import TextArea from "../../common/textfield/TextArea";
import { FormikObj } from "./constant.js";
import { Switch } from "@mui/material";
import { addDocumentReference } from "../../api/FillableDocument.js";
import { axios } from "../../../lib/axios";
import { useSelector } from "react-redux";
import Select from "react-select";

export function CreateResource({ modalShow, handleShow, refreshTable, locations }) {
  const [btnLoading, setBtnLoading] = useState(false);
  const user = useSelector((state) => state?.auth?.user)
  const userRoles = user?.["cognito:groups"];
  const practitionerId = user?.["custom:practitioner_id"];
  const practitionerName = user?.name[0]?.text;
  const adminOrgs = user?.organizations
  const clinicLocations = locations?.map((location) => ({
    value: location?.id,
    label: location?.name || "Unnamed Location",
  }))

  const formik = useFormik({
    ...FormikObj,
    onSubmit: (values) => {
      const selectedLocations = values.locations.map((loc) => ({
        reference: `Location/${loc.value}`,
        display: loc.label,
      }));
      const author = [
          {
            display: practitionerName,
            reference: `Practitioner/${practitionerId}`,
            type: "Practitioner"
        }
      ]
      const finalValues = { ...values,
         locations: selectedLocations,
          author: author,
          orgId: userRoles?.includes("Super-Admin") ?  values?.organization : adminOrgs?.[0]?.id,
          orgName: userRoles?.includes("Super-Admin") ?  values?.orgName : adminOrgs?.[0]?.name
         };

      setBtnLoading(true);
      addDocumentReference(finalValues)
        .then((res) => {
          if (res.status === true) {
            success(res.message);
            handleShow();
            refreshTable();
          } else {
            failed(res.error);
          }
        })
        .catch((res) => {
          failed(res?.response?.data?.message || res?.response?.data?.error || res.message);
        })
        .finally(() => setBtnLoading(false));
    },
  });

  useEffect(() => {
    if (modalShow) {
      formik.resetForm();
    }
  }, [modalShow]);
  


  return (
    <Modal
      backdropClassName
      backdrop={"static"}
      size="lg"
      show={modalShow}
      onHide={handleShow}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="custom-dialog"
    >
      <Modal.Header closeButton className="border-0">
        <Modal.Title id="contained-modal-title-vcenter">Create Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="common-form border-fields" onSubmit={formik.handleSubmit}>
          <Row>
            {userRoles?.includes("Super-Admin") && (
              <Col>
                <TextInput
                  keyField={"organization"}
                  label={"Organization"}
                  formik={formik}
                  placeholder={"Organization"}
                  disabled={false}
                />
              </Col>
            )}
            <Col>
              <TextInput
                keyField={"name"}
                label={"Name"}
                formik={formik}
                placeholder={"Document Name"}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextArea
                keyField={"description"}
                label={"Description"}
                formik={formik}
                placeholder={"Description..."}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <TextInput keyField={"url"} label={"URL"} formik={formik} placeholder={"URL"} />
            </Col>
          </Row>
          <Row>
            <Col className="field-wrap multiselect">
              <label id="mutiple-select-label"> Select Clinical Locations</label>
              <Select
                options={clinicLocations}
                isMulti
                value={formik.values.locations}
                onChange={(selected) => formik.setFieldValue("locations", selected || [])}
                placeholder="Select locations"
              />
            </Col>
          </Row>
          <div className="btn-wrap" style={{ display: "flex" }}>
            <Button
              onClick={() => handleShow(false)}
              variant="secondary"
              title="Cancel"
            >
              Cancel
            </Button>
            <Button type="submit" isLoading={btnLoading}>
              Create
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}
