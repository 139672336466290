import React from "react";
import SendIcon from '../../assets/images/send-icon.png';
import moment from "moment/moment";
import TimeAgo from "timeago-react";
import { Button } from "../common/Button";

export function ZoomChatBox({ currentUser, sessionChat, chatInput, handleSendMsg, show, handleParkCallClick, handleFollowUpClick, handleProceedSessionConfirmation }) {
    return (
        <div className="chat-sidebar" style={show ? {} : { position: "absolute", left: "-1000px" }}>
            <div className="individual-chart-wrap">
                <div className="chat-body">
                    {sessionChat?.map(({
                        id, sender: {
                            userId, name
                        }, timestamp, message
                    }) => <div className={`msg-common ${userId === currentUser.userId ? "sender" : "receiver"}`} key={id}>
                            <div className="msg-text">
                                {message}
                            </div>
                            <p className="msg-time">
                                {userId === currentUser.userId ? "" : name + ", "}
                                <TimeAgo datetime={moment(timestamp)} />
                            </p>
                        </div>)}
                </div>
                <div className="msg-footer">
                    <form className="search-wrap" onSubmit={handleSendMsg}>
                        <input ref={chatInput} className="form-control me-2" type="search" placeholder="Type something" aria-label="Search" />
                        <button type="submit" title="Send"><img src={SendIcon} alt="Send" /></button>
                    </form>
                </div>
                <div className="btn-wrap" style={{ display: 'flex', justifyContent: "space-evenly", gap: "1rem", marginBottom: "0.5rem" }}>
                    <Button className="custom-btn" style={{marginBottom: "2px"}} title="Park Call" variant="primary" onClick={handleParkCallClick}>Park Call</Button>
                    <Button className="custom-btn" style={{marginBottom: "2px"}} title="Add to Follow-Up" variant="primary" onClick={handleFollowUpClick}>Add to Follow Up</Button>
                    <Button className="custom-btn" style={{marginBottom: "2px"}} title={"Proceed"} variant="primary" onClick={handleProceedSessionConfirmation}>Proceed</Button>
                </div>
            </div>
        </div>
    );
}
