
//For local setup
/* const local = {
    apiUrl: "https://mhgs4aogx3.execute-api.ca-central-1.amazonaws.com/dev/",
    PORT: ''
}; */

//For WEBSOCKET end point
let webSocketEndpoint;
if (typeof process.env.AWS_WEBSOCKET_ENDPOINT !== "undefined") webSocketEndpoint = process.env.AWS_WEBSOCKET_ENDPOINT

//PSPDFKIT license key
let PSPDFKIT_LICENSE_KEY;
if (typeof process.env.REACT_APP_PSPDFKIT_LICENSE_KEY !== "undefined") PSPDFKIT_LICENSE_KEY = process.env.REACT_APP_PSPDFKIT_LICENSE_KEY

//For dev server port KEY can not be remove its using in application
const dev = {
    env: "DEV",
    apiUrl: "https://api.dev.ca.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.dev.ca.florizelhealth.com",
    PSPDFKIT_LICENSE_KEY: PSPDFKIT_LICENSE_KEY ? PSPDFKIT_LICENSE_KEY : "Vg8TAagxaXZ7wGoJI1evMiZp3_e9La83bf03Z_t-daksxbzoDqVhj1aFtr_sosltEBjOj2m2CuK-146zg3MepMcYb76R0qYOwkOrFsr8gqMis4PpBmZPJ0mxJi3E5WXjunWDX6AbFHfmJcKJ3qiZZHMPlUCMiVKwIhYlIcIJ8Ud3cAugiMKgSbVuvXCZ3BHu4WZZtKvAzksMP9E24fXgAecQGO7q-SwVteQzwHiX9LuxiWgdb_X9ciZaPMnID6bfL9hgvFbaXR_6aGvxjPIL0ROKDHTE4vnsJVNKOL6y6nSN9-k9nOsODzwTWaytbb13gKb06Uil5k3syNCPiw_wVs02eRBe8hY7z1b_ZqDsD7yv7obYV9IkrZcd96RhpBVv3K_eYd2_LH8N1MBmvxS7HXRNMNyZrrqMfeO0RkMU4nt5n-4zq04CWbGqw61pTsSPDlEm1OLCOn2v8L7g82qxG87pcfPh3P9z6ebuO_oOUoCax2cfNZC2I2JkyZ4yoVsc6cgOH9Xi9-lT1ZRinu498N2tZH2CYjHeoREHdG121uwwbs7mB3NBMwDHQnY5CSBim-KaV6RnBl1xkSWWcc-jb0czdT_6wyw1JxYcpl3zVdhknjihcl7JAWup5W07gVew"
};

//For qa server port KEY can not be remove its using in application
const qa = {
    env: "QA",
    apiUrl: "https://api.qa.ca.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.qa.ca.florizelhealth.com",
    PSPDFKIT_LICENSE_KEY: PSPDFKIT_LICENSE_KEY ? PSPDFKIT_LICENSE_KEY : "Zfed_Whz4qhEXn9l0r-mq19PERJrLpmOyZedTz8zUmEdLdSiPrIK8Qkq3Xw49PcVjM6kdUrwJXG-WhNdwNXaI2A07E9LB3vacwisWfRS5dCsIIePbtNSWQONpDAXEuViayLu4-cqNYIdsnLMzZcpRgNOqhoXUpIKAn4bJmHi-_jFT2UqEg3hMWZ3GabU9ZqV_chE7DR-IkQW1UK5k2x-UJBMMUpzdiJV078wHrFESksevq6eOKtCrFfZc1AVmWc_rGuKfE3IgLedLuFgjzXTXcMlC8BuaPtZ6RWJI58T6GrlW9cvUtxK6m26vhqH7FoV8uCspBCo2H9--em3zg3m1l51KKXf5OKm7AiVT7Hjn0PTgx2GJ03QRBv4gS1M1KjuIsuE7oo_2Phn_h74UgA3XhR-vl1JUCd-N9G3RUSBWBhC58_bMK3Qh7ZcQiEQ2Frx3pdiaxgSNUFrfY4uz07OUkhBwqXylmUGOkmbdr2D3oS97xZQOrbWczpak6T-Q-43wzhEU8mIxM2DW0j3AOjwwRgZNCRTvLScgz0NM33MmAuoVvDZinSJSjaiPezJClvt0FTh0THy-7croz3OtkpXjEZIwlGW5NfeQHAORttHcSfekeqTPDZhfZfXRVzBdhj9"
};

//For prod server port KEY can not be remove its using in application
const prod = {
    env: "PROD",
    apiUrl: "https://api.ca.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.ca.florizelhealth.com",
    PSPDFKIT_LICENSE_KEY: PSPDFKIT_LICENSE_KEY ? PSPDFKIT_LICENSE_KEY : "e7726EeZqxESxcbcT5dXlyQmI4BgdHAi0YK7gaS2I3L9ME5T5ufcaAX-3BxwM2lIRrhOAsCWW0TSoTB37mxUuddLihkcwIO6r_QOxg_EzLsBzfQqY5yZygqbEDLCz-VOvY6n-piVZTwaa23677n000429saweNBf-_eUVHoEbXfb4Hk_5n7MLEpNq7wk1N_6TfE3n3fCcfnFMMOcIMcEb_iqRRxGrpI5cHwycQ32CJtjfR_LZq3juvAuSLba97NEf4auNWeRXDpaZAQVlyO-uwfE6m1FthGPJHukAN7sTnkIH77DcwFjzOreRsZpinJ60Rj4VbGb_hJXDDXAmcy4vuY84yDpweRKFS35wA4k5V2ttlS871tibQoYfwXRG8927lMAOQo9ljrGlmsUvWL_qpeOxCkdoW8TiIv9shFN1YJZlhuIZGr_R0YHuHqtQMW4RAaBZp0JJNYWRuVhxP5u-Uji3pILJL_K_3U-hOGalnLZ96mckOEsvNtk34EUDjNjceEf6TNfUKQh9rMW9kDuRjrkGghZIRYtPGXZqxOikotgPyvxevF-jABZWZnp3ZsO0ZhUlUbO32zyacl2tHtmQJmBontRaNLQ1WUguUya3EQ="
};

const prod_govus = {
    env: "PROD_GOVUS",
    apiUrl: "https://api.govus.florizelhealth.com/",
    PORT: '',
    webSocketEndpoint: webSocketEndpoint ? webSocketEndpoint : "api-queue.govus.florizelhealth.com",
    PSPDFKIT_LICENSE_KEY: PSPDFKIT_LICENSE_KEY ? PSPDFKIT_LICENSE_KEY : ""
};

// New key generated for local development purposes
const PSPDFKIT_LocalhostKey = "T1MLVHZOV1YtiJti1smhssX1ImxtW-0wQkS-Luqy-iOkt5Jax4JtaThkfgnUZTOxZVcvxgdkpHnEiMUtPZ5y10biHWq1QFBzbbKSl4Mi3-_gYqBYCGGq90h1P6qlSB9e5ANMyqqqQbB3mLVKUyy06JSuusn2eN8WfL4_V-y8xxlDS6Q4crkuJ1evS4odsyiX1M7s4Ot2HSEVkOJwrXnykLavKZy2qIi_Ly3Q5rkMeSJDbvcA-ZKcysQdJX7ENE-ajkzm1AjzPrnahVA7Zy9USt1HQt0_zxvt5psPbpioMbDuV0SvEJJlgPu4fzIt4jF5ZsBKSugFEz6Ba_B6dJdCN4hGE2nUz8ShTc6ak2-UUl6eKnJFcDsxJoWI98FPSfo2iqJ4RRwOO6O4rMV5DubrtY4-uBpKjk1YZDHRCik8JcpvtF1qdN_ud121adRd4WoGAH89Cg4ZAPrPVH350Dqw9rKJ7RM_bOpIg7YbE12mFs44DJxY76pbxryAd2htQ1BVM5lR4-PQAFm840X8C7teyWHO48k_Vi9GyVXE1BkVIfcOeRssdss_IqZ-Ub29WDqYxL9O2U8-_FG0NVSroIhpHjDeMR8ihCa4sWB_Hn6Hldk="



if(process.env.REACT_APP_TYPE === "qa") {
    module.exports = qa;
} else if (process.env.REACT_APP_TYPE === "prod") {
    module.exports = prod;
} else if (process.env.REACT_APP_TYPE === "prod-govus") {
    module.exports = prod_govus;
} else {
    module.exports = dev;
}

