import React, { useEffect, useState } from "react";
import { Checkbox, InputLabel, ListItemIcon, ListItemText, MenuItem, Select, Grid } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: "max-width",
        },
    },
    getcontentanchorel: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "left",
    },
    variant: "menu",
};

const renderError = (formik, keyField) => {
    let keyFieldParts = keyField?.split('.');
    
    if(keyFieldParts?.length === 3 && formik?.errors[keyFieldParts[0]]) {
        let errorString = formik?.errors[keyFieldParts[0]]?.[keyFieldParts[1]]?.[keyFieldParts[2]];
        return   <div>
                    { errorString ? <div className="error-text">{errorString}</div>: null }
                 </div>
    }
    else {
        return <div>{formik?.errors[keyField] ? <div className="error-text">{formik?.errors[keyField]}</div> : null}</div>
    }
}

function MultiSelectV2({ 
    options, 
    keyField, 
    formik, 
    isSearchable="false", 
    isSelectAll = false, 
    isSelectAllEnable = false,
    label, 
    nodata, 
    required = true, 
    hideRequired =false, 
    defaultValue = [], 
    readOnly = false,
    bindMethod = false, 
    ...props 
}) { 
    const [selected, setSelected] = useState(["Select"]);
    const [selectAll, setSelectAll] = useState(isSelectAll);
    const isSmallScreen = useMediaQuery('(max-width: 991px)');
    const isBigScreen = useMediaQuery('(max-width: 1395px)');

    const handleChange = (event) => {
        const value = event.target.value;
        const optionValues = options.map(option => option.value);

        if (value.includes("selectAll")) {
            handleSelectAll();
        } else {
            const validSelected = value.filter(val => 
                val !== "Select" && optionValues.includes(val)
            );

            setSelectAll(validSelected.length === options.length);
            setSelected(validSelected.length > 0 ? validSelected : ["Select"]);
            
            formik?.setFieldValue(keyField, validSelected);
            bindMethod && bindMethod(validSelected);
        }
    };    

    const handleItemToggle = (value) => {
        const currentIndex = selected.indexOf(value);
        const newSelected = [...selected];

        if (currentIndex === -1) {
            newSelected.push(value);
        } else {
            newSelected.splice(currentIndex, 1);
        }

        const filteredSelected = newSelected.filter(val => val !== "Select");
        
        setSelected(filteredSelected.length > 0 ? filteredSelected : ["Select"]);
        setSelectAll(filteredSelected.length === options.length);
        
        formik?.setFieldValue(keyField, filteredSelected);
        bindMethod && bindMethod(filteredSelected);
    };

    useEffect(()=>{
        defaultValue.length > 0 ? setSelected([...selected, ...defaultValue.map((n) => selected.includes(n) ? null : n).filter((n) => n)]): setSelected(["Select"]);
        defaultValue.length > 0 && (defaultValue.length  === options.length  ? setSelectAll(true) : setSelectAll(false))
    }, [defaultValue?.length])

    useEffect(()=>{
        if(isSelectAllEnable){
            if(formik?.values?.[keyField]?.length === 0) handleChange({target: {value: ["Select"]}})
            options?.length > 0 && formik?.values[keyField]?.length === options?.length && setSelectAll(true)
        }
    }, [formik?.values?.[keyField]?.length])

    useEffect(()=>{
        setSelectAll(isSelectAll)
    }, [isSelectAll])

    const handleSelected = (selected) => {
        if (selected.length === 1 && selected[0] === "Select") return "Select";

        return selected
            .filter(val => val !== "Select")
            .map(val => {
                const matchedOption = options.find(option => option.value === val);
                return matchedOption ? matchedOption.name : val;
            })
            .join(", ");
    };

    const handleSelectAll = () => {
        setSelectAll(!selectAll)
        const valuesById = selectAll ? [] : options.map((option) =>  option.value)
        setSelected(selectAll ? ["Select"] : valuesById);
        formik?.setFieldValue(keyField, valuesById);
        bindMethod && bindMethod(valuesById);
    };

    // Determine column layout based on number of options
    const shouldUseMultiColumn = options.length >= 15;
    let displayOptions = options;
    let firstColumnOptions = options;
    let secondColumnOptions = [];

    if (shouldUseMultiColumn) {
        const halfLength = Math.ceil(options.length / 2);
        firstColumnOptions = options.slice(0, halfLength);
        secondColumnOptions = options.slice(halfLength);
    }

    return (
        <div className="field-wrap multiselect">
            {label && <InputLabel id="mutiple-select-label">{label}{hideRequired ? null : required ? <span style={{ color: "red" }}> *</span> : ( props.propsOptionData === 0 ? null : " (Optional)")}</InputLabel>}
            <Select
                labelId="mutiple-select-label"
                multiple
                value={selected}
                onChange={handleChange}
                renderValue={(selected) => handleSelected(selected)}
                MenuProps={{
                    ...MenuProps,
                    PaperProps: {
                        ...MenuProps.PaperProps,
                        style: {
                            ...MenuProps.PaperProps.style,
                            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
                        }
                    }
                }}
                fullWidth
                issearchable={isSearchable}
                style={{ 
                    borderRadius: "10px", 
                    height: "42px",
                    minHeight: isBigScreen ? "54px" : "50px",
                    maxWidth: isSmallScreen ? "100%" : "clamp(23rem, 3.5vw, 30rem)", 
                    textTransform: "capitalize" 
                }}
                readOnly={readOnly}
                {...props}
            >
                {isSelectAllEnable && (
                    <MenuItem value={"selectAll"} style={{ position: 'sticky', top: 0, backgroundColor: 'white', zIndex: 1 }}>
                        <ListItemIcon>
                            <Checkbox checked={selectAll} />
                        </ListItemIcon>
                        <ListItemText primary={"Select All"} />
                    </MenuItem>
                )}
                {options.length > 0 ? (
                    shouldUseMultiColumn ? (
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                {firstColumnOptions.map(({ value, name }) => (
                                    <MenuItem 
                                        key={value} 
                                        onClick={() => handleItemToggle(value)}
                                        style={{
                                            textTransform: "capitalize",
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                            height: "auto",
                                            minHeight: "48px" 
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox 
                                                checked={selected.indexOf(value) > -1} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleItemToggle(value);
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={name}
                                            primaryTypographyProps={{
                                                style: {
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word"
                                                }
                                            }} />
                                    </MenuItem>
                                ))}
                            </Grid>
                            <Grid item xs={6}>
                                {secondColumnOptions.map(({ value, name }) => (
                                    <MenuItem 
                                        key={value} 
                                        onClick={() => handleItemToggle(value)}
                                        style={{
                                            textTransform: "capitalize",
                                            whiteSpace: "normal",
                                            wordWrap: "break-word",
                                            height: "auto",
                                            minHeight: "48px" 
                                        }}
                                    >
                                        <ListItemIcon>
                                            <Checkbox 
                                                checked={selected.indexOf(value) > -1} 
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleItemToggle(value);
                                                }}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={name} 
                                            primaryTypographyProps={{
                                                style: {
                                                    whiteSpace: "normal",
                                                    wordWrap: "break-word"
                                                }
                                            }}
                                        />
                                    </MenuItem>
                                ))}
                            </Grid>
                        </Grid>
                    ) : (
                        options.map(({ value, name }) => (
                            <MenuItem 
                                key={value} 
                                onClick={() => handleItemToggle(value)}
                                style={{
                                    textTransform: "capitalize",
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    height: "auto",
                                    minHeight: "48px" 
                                }}
                            >
                                <ListItemIcon>
                                    <Checkbox 
                                        checked={selected.indexOf(value) > -1} 
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            handleItemToggle(value);
                                        }}
                                    />
                                </ListItemIcon>
                                <ListItemText primary={name}
                                    primaryTypographyProps={{
                                        style: {
                                            whiteSpace: "normal",
                                            wordWrap: "break-word"
                                        }
                                    }} />
                            </MenuItem>
                        ))
                    )
                ) : (
                    <MenuItem>
                        <ListItemText primary={nodata} />
                    </MenuItem>
                )}
            </Select>
            {renderError(formik, keyField)}
        </div>
    );
}

export default MultiSelectV2;