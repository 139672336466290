import React, { useEffect, useState } from "react";
import "./Triage.css"; // Import the external CSS file
import { Button } from "../../common/Button";
import ProtocolResponseFill from "./ProtocolResponseFill";
import { useSelector } from "react-redux";
import { getEncounterRecord } from "../api/AppointmentRequests";
import { failed } from "../../common/Toastify";
import { getPatientData } from "../../api/Individual";
import {
  createProtocolResponse,
  getProtocolByProtocolId,
  getProtocolResponsesByEncounterId,
  patchProtocolResponse,
} from "../api/Protocol";
import moment from "moment";
import { FullPageSpinner } from "../../common/Spinner/FullPageSpinner";

const TriageParentComponent = ({ encounterIdFromVisit, fetchResponses, hideTriageBtn }) => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const encounterId = encounterIdFromVisit || urlParams.get("encounterId");
  const [patientId, setPatientId] = useState(null);
  const [patientData, setPatientData] = useState(null);

  const [encounterData, setEncounterData] = useState(null);
  const [loading, setLoading] = useState(false);

  const [protocolResponseData, setProtocolResponseData] = useState(null);
  const [activeResponseId, setActiveResponseId] = useState(1);

  useEffect(() => {
    if (encounterId) {
      setLoading(true);
      getEncounterRecord({ encounterId: encounterId })
        .then((res) => {
          if (res?.status === true) {
            setEncounterData(res?.data);

            setPatientId(res?.data?.subject?.reference?.split("/")[1]);
          }
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setLoading(false));
    }
  }, [encounterId]);

  useEffect(() => {
    if (patientId) {
      setLoading(true);
      getPatientData(patientId)
        .then((res) => {
          if (res?.status === true) {
            setPatientData(res?.result);
          }
        })
        .catch((res) =>
          failed(
            res?.response?.data?.message ||
              res?.response?.data?.error ||
              res.message
          )
        )
        .finally(() => setLoading(false));
    }
  }, [patientId]);

  const userOrgId = useSelector(
    (state) => state?.auth?.user?.["organizations"][0].id
  );
  const userPractitionerId = useSelector(
    (state) => state?.auth?.user?.["custom:practitioner_id"]
  );
  const practUserName = useSelector(
    (state) => state?.auth?.user?.name[0]?.text
  );

  useEffect(() => {
    if (encounterId) {
      getProtocolResponsesByEncounterId(encounterId, userOrgId)
        .then((res) => {
          const parsedResponse = JSON.parse(res?.body);
          if (parsedResponse?.data) {
            setProtocolResponseData(parsedResponse.data);
            let indexOfProtocol = parsedResponse.data.findIndex(item => item.authorId === userPractitionerId)
            indexOfProtocol = indexOfProtocol + 1;
            setActiveResponseId(indexOfProtocol)
            if (parsedResponse?.data?.length > 0) {
              hideTriageBtn();
            }
          }
        })
        .catch((error) => {
          console.error("Error fetching protocol responses:", error);
        });
    }
  }, [encounterId, fetchResponses]);

  const [protocolResponses, setProtocolResponses] = useState([
    {
      id: 1,
      name: "Protocol Response 1",
      state: {
        id: 1,
      },
    },
  ]);

  useEffect(() => {
    if (protocolResponseData) {
      populateProtocolResponsesStates();
    }
  }, [protocolResponseData]);

  const populateProtocolResponsesStates = async () => {
    setLoading(true);

    try {
      const newProtocolResponses = await Promise.all(
        protocolResponseData.map(async (item, index) => {
          let protocolData = null;
          let initialAssessmentQuestionsSaved;
          let searchWord;
          let protocol;
          let positiveIndicatorsGrouped;
          let selectedYesQuestion;
          let careAdviceResponses;
          let disposition;
          let dispositionRationale;
          let dispositionLevelOverride;
          let documentationStarted = false; 
          if (item?.algorithm?.algorithmId) {
            protocolData = await getProtocolByProtocolId(
              item.algorithm.algorithmId,
              item?.algorithm?.version,
              item?.algorithm?.category,
              userOrgId
            );
          }

          if (item?.initialAssessment?.length > 0) {
            initialAssessmentQuestionsSaved = item?.initialAssessment;
            documentationStarted = true;
          }

          if (item?.searchWord) {
            searchWord = item?.searchWord;
          }

          if (item?.algorithm) {
            protocol = {
              label: item?.algorithm?.display,
              value: item?.algorithm?.algorithmId,
            };
          }

          if (item?.positiveIndicators) {
            if (item?.positiveIndicators?.length > 0) {
              documentationStarted = true;
            }
            let markedAsYes;
            const grouped = protocolData.body.questions.reduce(
              (acc, question) => {
                const { dispositionHeading, dispositionLevel } = question;

                // Initialize updatedQuestion with default values
                const updatedQuestion = {
                  ...question,
                  isEnabled: true,
                  isAnswered: false,
                  answer: null,
                };

                // Check if questionId matches any in item.positiveIndicators
                const matchingIndicator = item.positiveIndicators.find(
                  (indicator) => indicator.questionId === question.questionId
                );

                // If there's a match, update isAnswered and answer
                if (matchingIndicator) {
                  updatedQuestion.isAnswered = true;
                  updatedQuestion.answer = matchingIndicator.answer;

                  if (matchingIndicator?.answer === "Yes") {
                    selectedYesQuestion = updatedQuestion;
                    markedAsYes = updatedQuestion;
                  }
                }

                // Group questions by dispositionHeading
                if (!acc[dispositionHeading]) {
                  acc[dispositionHeading] = { questions: [], dispositionLevel };
                }

                acc[dispositionHeading].questions.push(updatedQuestion);

                return acc;
              },
              {}
            );

            // Sort questions within each group by questionOrder
            for (const group in grouped) {
              grouped[group].questions.sort(
                (a, b) => a.questionOrder - b.questionOrder
              );
            }

            // Sort groups by dispositionLevel and return the result
            let sortedGrouped = Object.keys(grouped)
              .sort(
                (a, b) =>
                  grouped[b].dispositionLevel - grouped[a].dispositionLevel
              )
              .reduce((acc, group) => {
                acc[group] = grouped[group];
                return acc;
              }, {});

            // Mark all subsequent questions as disabled if a Yes answer is found
            if (markedAsYes) {
              let markDisabled = false;
              for (const grp in sortedGrouped) {
                const group = sortedGrouped[grp];
                if (grp === markedAsYes?.dispositionHeading) {
                  markDisabled = true;
                  group?.questions.forEach((question) => {
                    if (question?.questionOrder > markedAsYes?.questionOrder) {
                      question.isEnabled = false;
                    }
                  });
                }
                if (markDisabled) {
                  group?.questions.forEach((question) => {
                    if (
                      question?.isEnabled === true &&
                      grp !== markedAsYes?.dispositionHeading
                    ) {
                      question.isEnabled = false;
                    }
                  });
                }
              }
            }

            positiveIndicatorsGrouped = sortedGrouped;
          }

          if (item?.careAdvice && item?.careAdvice?.length > 0) {
            let careAdviceTexts;

            careAdviceTexts = selectedYesQuestion?.adviceIds
              ?.map((advice) =>
                protocolData?.body?.advice?.find(
                  (item) => item.adviceId === advice.adviceId
                )
              )
              .filter(Boolean)
              .sort(
                (a, b) =>
                  selectedYesQuestion.adviceIds.find(
                    (item) => item.adviceId === a.adviceId
                  )?.adviceOrder -
                  selectedYesQuestion.adviceIds.find(
                    (item) => item.adviceId === b.adviceId
                  )?.adviceOrder
              );

            let initialStates = {};
            careAdviceTexts?.forEach((advice) => {
              const [heading, ...content] = advice.advice.split(":");
              const bulletPoints = content
                .join(":")
                .split("*")
                .filter((point) => point.trim());

              initialStates[heading] = {
                allSelected: false,
                bullets: bulletPoints.map((point, index) => ({
                  index,
                  text: point.trim(),
                  selected: false,
                })),
              };
            });

            item.careAdvice.forEach((careAdviceItem) => {
              const heading = careAdviceItem?.adviceHeading;
              const subAdvices = careAdviceItem?.subAdvices;

              if (initialStates[heading]) {
                if (subAdvices.length === 0) {
                  // No bullets present for this heading
                  initialStates[heading].allSelected = true;
                  initialStates[heading].bullets = []; // Empty bullets array
                } else {
                  subAdvices.forEach((subAdvice) => {
                    const bullet = initialStates[heading].bullets.find(
                      (b) => b.index === subAdvice.index
                    );
                    if (bullet) {
                      bullet.selected = true; // Mark bullet as selected if index matches
                    }
                  });

                  // Update allSelected if all bullets are selected
                  initialStates[heading].allSelected = initialStates[
                    heading
                  ].bullets.every((b) => b.selected);
                }
              }
            });

            careAdviceResponses = initialStates;
          }

          if (item?.overrideDisposition) {
            disposition = item?.overrideDisposition;
            dispositionRationale = item?.dispositionRationale;
            dispositionLevelOverride = item?.dispositionLevel;
          }

          return {
            protocolResponseId: item?.id,
            id: index + 1,
            name: `Protocol Response ${index + 1}`,
            authorId: item?.authorId,
            state: {
              id: index + 1,
              protocolResponseId: item?.id,
              protocolData: protocolData?.body,
              initialAssessmentQuestions: initialAssessmentQuestionsSaved,
              searchWord: searchWord,
              protocol: protocol,
              positiveIndicatorsGrouped: positiveIndicatorsGrouped,
              selectedYesQuestion: selectedYesQuestion,
              careAdviceResponses: careAdviceResponses,
              disposition: disposition,
              dispositionRationale: dispositionRationale,
              dispositionLevelOverride: dispositionLevelOverride,
              documentationStarted: documentationStarted
            },
          };
        })
      );

      setProtocolResponses(newProtocolResponses);
    } catch (error) {
      console.error("Error fetching protocol data:", error);
    } finally {
      setLoading(false);
    }
  };

  const addNewProtocolResponse = (redirectData) => {
    setLoading(true);
    let redirectProtocol;
    let searchWord;

    if (redirectData) {
      const labelText =
        redirectData?.information
          ?.split("Guideline: ")[1]
          ?.replace(/\s*\(.*?\)\s*/g, "")
          ?.trim() || "";

      redirectProtocol = {
        label: labelText,
        value: redirectData?.protocolRedirectId,
      };
      searchWord = redirectData?.searchWord;
    }

    let payload = {
      resourceType: "ProtocolResponse",
      status: "in-progress",
      encounterId: encounterId,
      orgId: userOrgId,
      authored: moment().toISOString(),
      author: {
        display: practUserName,
        reference: `Practitioner/${userPractitionerId}`,
        type: "Practitioner",
      },
      authorId: userPractitionerId,
      encounter: {
        reference: `Encounter/${encounterId}`,
      },
      managingOrganization: {
        reference: `Organization/${userOrgId}`,
        type: "Organization",
      },
      subject: encounterData?.subject,
    };

    createProtocolResponse(userOrgId, payload)
      .then((res) => {
        const parsedResponse = JSON.parse(res?.body);
        if (parsedResponse?.data) {
          const newId = protocolResponses.length + 1;
          let stateData = [
            ...protocolResponses,
            {
              id: newId,
              protocolResponseId: parsedResponse?.data?.id,
              name: `Protocol Response ${newId}`,
              authorId: parsedResponse?.data?.authorId,
              state: {
                id: newId,
                protocolResponseId: parsedResponse?.data?.id,
                protocol: redirectProtocol,
                protocolRedirectId: redirectProtocol,
                searchWord: redirectData?.searchWord,
              },
            },
          ];
          setProtocolResponses(stateData);
          setActiveResponseId(newId);
        }
        // Updating new state with protocol Response id
      })
      .catch((res) =>
        failed(
          res?.response?.data?.message ||
            res?.response?.data?.error ||
            res.message
        )
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const updateProtocolResponseState = (id, protocolResponseId, newState) => {
    setProtocolResponses((prevResponses) =>
      prevResponses.map((response) => {
        if (response.id === id) {
          return {
            ...response,
            state: {
              ...response.state, // Keep previous state fields
              ...newState, // Update with new fields
            },
          };
        }
        return response;
      })
    );

    saveProtocolResponses(protocolResponseId, newState, "in-progress");
  };

  const clearProtocolResponseState = (id) => {
    setProtocolResponses((prevResponses) =>
      prevResponses.map((response) =>
        response.id === id
          ? {
              ...response,
              state: {
                id: response.state.id,
                protocolResponseId: response.state.protocolResponseId,
              }, // Clear state but retain the id and response id
            }
          : response
      )
    );
  };

  const saveProtocolResponses = (protocolResponseId, protocolState, status) => {
    let initialAssessmentResponses = [];
    let positiveIndicatorResponses = [];
    let careAdviceResponses = [];
    let searchWord;
    let protocol;
    let originalDisposition;
    let overrideDisposition;
    let overrideRationale;
    let dispositionLevel;

    if (protocolState?.initialAssessmentQuestions) {
      protocolState?.initialAssessmentQuestions?.map((item) => {
        if (item?.answer) {
          initialAssessmentResponses.push(item);
        }
      });
    }

    if (protocolState?.searchWord) {
      searchWord = protocolState?.searchWord;
    }

    if (protocolState?.protocol) {
      protocol = {
        reference: `Protocol/${protocolState?.protocol?.label}`,
        display: protocolState?.protocol?.label,
        algorithmId: protocolState?.protocol?.value,
        version:
          protocolState?.version || protocolState?.protocolData?.versionYear,
        category:
          protocolState?.category || protocolState?.protocolData?.category,
      };
    }

    if (protocolState?.selectedYesQuestion) {
      originalDisposition =
        protocolState?.selectedYesQuestion?.dispositionHeading;
      dispositionLevel = protocolState?.selectedYesQuestion?.dispositionLevel;
    }

    if (protocolState?.disposition) {
      overrideDisposition = protocolState?.disposition;
    }

    if (protocolState?.dispositionRationale) {
      overrideRationale = protocolState?.dispositionRationale;
    }

    if (protocolState?.positiveIndicatorsGrouped) {
      Object.keys(protocolState.positiveIndicatorsGrouped).forEach((key) => {
        const item = protocolState.positiveIndicatorsGrouped[key];
        item?.questions?.forEach((question) => {
          if (question?.isAnswered === true) {
            positiveIndicatorResponses.push({
              questionText: question?.question,
              answer: question?.answer,
              disposition: question?.dispositionHeading,
              questionId: question?.questionId,
            });
          }
        });
      });
    }

    if (protocolState?.careAdviceResponses) {
      Object.keys(protocolState.careAdviceResponses).forEach((key) => {
        let subAdvices = [];

        const item = protocolState.careAdviceResponses[key];

        // Handle bullets
        item?.bullets?.forEach((advice) => {
          if (advice?.selected === true) {
            subAdvices.push({ index: advice?.index, text: advice?.text });
          }
        });

        // Check for no bullets and allSelected being true
        if (item?.bullets?.length === 0 && item?.allSelected === true) {
          careAdviceResponses.push({
            adviceHeading: key,
            subAdvices: [], // No subAdvices in this case
          });
        }

        // Push responses with selected subAdvices
        if (subAdvices?.length > 0) {
          careAdviceResponses.push({
            adviceHeading: key,
            subAdvices: subAdvices,
          });
        }
      });
    }

    if (protocolState?.dispositionLevelOverride) {
      dispositionLevel = protocolState?.dispositionLevelOverride;
    }

    let payload = {
      status: status,
      protocolId: protocolState?.protocol?.value,
      algorithm: protocol,
      searchWord: searchWord,
      initialAssessment: initialAssessmentResponses,
      disposition: originalDisposition,
      overrideDisposition: overrideDisposition,
      dispositionRationale: overrideRationale,
      dispositionLevel: dispositionLevel,
      positiveIndicators: positiveIndicatorResponses,
      careAdvice: careAdviceResponses,
    };

    // TODO: This needs to be enhanced for better user experience
    patchProtocolResponse(protocolResponseId, encounterId, userOrgId, payload);
  };

  const handleProceedClick = () => {
    let payload = {
      status: "finished",
    };

    const promises = protocolResponseData?.map((item) => {
      return patchProtocolResponse(
        item?.id,
        encounterId,
        userOrgId,
        payload
      ).then((res) => {
        console.log("RES AFTER COMPLETE SET: ", res);
        return res;
      });
    });

    Promise.all(promises)
      .then(() => {
        console.log("All updates completed");
        window.close();
      })
      .catch((error) => {
        console.error("Error completing updates:", error);
        alert("An error occurred. Please try again.");
      });
  };

  return (
    <>
      <section className="common-listing">
        {loading && <FullPageSpinner loadingText={"Loading..."} />}
        <div>
          {/* Main content area */}
          {protocolResponses.map(
            (response) =>
              response.id === activeResponseId && (
                <ProtocolResponseFill
                  key={response.id}
                  protocolState={response.state}
                  setProtocolState={(newState) =>
                    updateProtocolResponseState(
                      response.id,
                      response.protocolResponseId,
                      newState
                    )
                  }
                  clearProtocolState={() =>
                    clearProtocolResponseState(response.id)
                  }
                  stateId={activeResponseId}
                  patientData={patientData}
                  encounterData={encounterData}
                  handleProtocolRedirect={addNewProtocolResponse}
                />
              )
          )}
        </div>
      </section>

      {/* Fixed Footer Buttons */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Left-aligned buttons */}
        <div className="protocol-tabs" style={{ display: "flex" }}>
          <div>
            {protocolResponses.map((response) => (
              <Button
                key={response.id}
                onClick={() => setActiveResponseId(response.id)}
                variant={
                  response.id === activeResponseId ? "primary" : "secondary"
                }
                style={{
                  marginRight: "10px",
                  marginBottom: "10px",
                  opacity: response.authorId !== userPractitionerId ? 0.5 : 1, 
                  cursor: response.authorId !== userPractitionerId ? "not-allowed" : "pointer",
                }}
                title={
                  response.authorId !== userPractitionerId
                    ? "You cannot edit a protocol created by another practitioner."
                    : ""
                }
                disabled = {response.authorId !== userPractitionerId}
              >
                {response?.state?.protocol?.label || response.name}
              </Button>
            ))}
          </div>
        </div>

        {/* Right-aligned buttons */}
        <div
          className="right-wrap"
          style={{ display: "flex", alignItems: "center" }}
        >
          <Button
            className="custom-btn"
            title="Add Protocol"
            variant="primary"
            onClick={addNewProtocolResponse}
            style={{ marginRight: "10px" }}
          >
            Add Protocol
          </Button>

          {/*<Button
            className="custom-btn"
            title="Proceed"
            variant="primary"
            onClick={handleProceedClick}
          >
            Proceed
          </Button>*/}
        </div>
      </div>
    </>
  );
};

export default TriageParentComponent;
